// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import DatalistInput, { DatalistInputProps } from "./DatalistInput";

interface ControlledDatalistInputProps extends DatalistInputProps {
  control: any;
}

const ControlledDatalistInput = (props: ControlledDatalistInputProps) => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, value = "", ...restt } }) => {
        return <DatalistInput value={value} {...restt} {...rest} />;
      }}
    />
  );
};

export default ControlledDatalistInput;
