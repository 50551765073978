import { api } from "../../../utills/api";

export const pinCodeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPinCode: build.mutation<{ data: any }, void>({
      query: (params: any) => ({
        url: `/admin/pin-code/get-pin-code-list${params?.list ? "/all" : ""}`,
        body: params,
        method: "POST",
      }),
    }),
    updatePinCode: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/pin-code/update-pin-code",
        body: params,
        method: "POST",
      }),
    }),
    addPinCode: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/pin-code/add-pin-code",
        body: params,
        method: "POST",
      }),
    }),
    deletePinCode: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/pin-code/delete-pin-code",
        body: params,
        method: "POST",
      }),
    }),
    getPinCodeById: build.mutation<{ data: any }, void>({
      query: (params: any) => ({
        url: `/admin/pin-code/get-pin-code-by-id/${params?.id}`,
        // body: params,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetPinCodeMutation,
  useUpdatePinCodeMutation,
  useAddPinCodeMutation,
  useDeletePinCodeMutation,
  useGetPinCodeByIdMutation,
} = pinCodeApi;
export const {
  endpoints: {
    getPinCode,
    addPinCode,
    updatePinCode,
    deletePinCode,
    getPinCodeById,
  },
} = pinCodeApi;
