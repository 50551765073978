import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { subscriptionsApi } from "./subscriptionsApi";

interface UserState {
  subscriptionsList: any;
  subscriptionsUserList: any;
  subscriptionsData: any;
}

const initialState: UserState = {
  subscriptionsList: {},
  subscriptionsUserList: {},
  subscriptionsData: {},
};

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    clearSubscriptions: () => initialState,
    setSubscriptions: (state, { payload }) => {
      state.subscriptionsList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      subscriptionsApi.endpoints.getSubscriptions.matchFulfilled,
      (state, { payload }) => {
        state.subscriptionsList = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default subscriptionsSlice.reducer;
export const { clearSubscriptions, setSubscriptions } =
  subscriptionsSlice.actions;

export const selectSubscriptionsList = (state: RootState) =>
  state.subscriptions.subscriptionsList;
export const useSubscriptionsList = () => {
  const subscriptionsList = useSelector(selectSubscriptionsList);
  return useMemo(() => subscriptionsList, [subscriptionsList]);
};

export const selectSubscriptionsData = (state: RootState) =>
  state.subscriptions.subscriptionsData;
export const useSubscriptionsData = () => {
  const subscriptionsData = useSelector(selectSubscriptionsData);
  return useMemo(() => subscriptionsData, [subscriptionsData]);
};
