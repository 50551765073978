import { api } from "../../utills/api";

export const policyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPolicy: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/policy/get-policy-description",
        body: params,
        method: "POST",
      }),
    }),
    updatePolicy: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/policy/update-policy-description",
        body: params,
        method: "POST",
      }),
    }),
  
  }),
});

export const {
  useGetPolicyMutation,
  useUpdatePolicyMutation,
} = policyApi;
export const {
  endpoints: { getPolicy, updatePolicy },
} = policyApi;
