import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, ControlledInput, Loader } from "../../../ui";
import {
  useAddDeliveredPersonMutation,
  useGetDeliveryPersonByIdMutation,
  useUpdateDeliveredPersonMutation,
} from "./deliveredPersonApi";
import ControlledSwitch from "../../../ui/forms/ControlledSwitch";
import { responseToaster, showToaster } from "../../../helperFunctions";
import CopyToClipboard from "react-copy-to-clipboard";

const DeliveredPersonModal = (props: any) => {
  const { onHide, isOpen, onGet, deliveredPersonData } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);

  const [addDeliveredPerson] = useAddDeliveredPersonMutation();
  const [getDeliveryPersonById, { isLoading: isgetDeliveryPersonByLoading }] =
    useGetDeliveryPersonByIdMutation();
  const [updateDeliveredPerson] = useUpdateDeliveredPersonMutation();
  const [pwdData, setPwdData] = useState<any>(null);

  const schema: any = yup.object({
    first_name: yup.string().required("Please enter a first name"),
    last_name: yup.string().required("Please enter a last name"),
    email_id: yup
      .string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
      .required("Please enter a Email"),
    mobile_number: yup.string().required("Please enter a mobile number"),
  });

  const prepareDefaultValues = () => {
    if (deliveredPersonData?.id) {
      return {
        first_name: deliveredPersonData?.first_name,
        last_name: deliveredPersonData?.last_name,
        email_id: deliveredPersonData?.email_id,
        mobile_number: deliveredPersonData?.mobile_number,
        status: deliveredPersonData?.status === "active" ? 1 : 0,
      };
    } else {
      return {
        status: 1,
      };
    }
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...prepareDefaultValues(),
    },
  });

  const onClose = () => {
    reset();
    onHide();
    setPwdData(null);
  };

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const payload = {
        ...values,
        ...(deliveredPersonData?.id ? { id: deliveredPersonData?.id } : null),
        ...(deliveredPersonData?.id
          ? { status: values?.status ? "active" : "inactive" }
          : null),
      };

      const res: any = deliveredPersonData?.id
        ? await updateDeliveredPerson(payload).unwrap()
        : await addDeliveredPerson(payload).unwrap();
      if (res?.status && deliveredPersonData?.id) {
        onClose();
        onGet?.();
      }
      if (res?.status && !deliveredPersonData?.id) {
        setPwdData(res?.data);
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  const getDeliveryPersonData = async () => {
    try {
      const payload: any = {
        id: deliveredPersonData?.id,
      };
      const res: any = await getDeliveryPersonById(payload);
      if (res?.data?.status) {
        setFormData(res?.data?.data);
      }
    } catch (error) {}
  };

  const setFormData = (deliveredPersonData: any) => {
    setValue("first_name", deliveredPersonData?.first_name);
    setValue("last_name", deliveredPersonData?.last_name);
    setValue("email_id", deliveredPersonData?.email_id);
    setValue("mobile_number", deliveredPersonData?.mobile_number);
    setValue("status", deliveredPersonData?.status === "active" ? 1 : 0);
  };

  useEffect(() => {
    if (isOpen && deliveredPersonData?.id) {
      getDeliveryPersonData();
    }
    // eslint-disable-next-line
  }, [isOpen, deliveredPersonData, deliveredPersonData?.id]);

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
          onClick={() => {
            if (!addCpisLoading) {
              onClose();

              if (!deliveredPersonData?.id && pwdData) {
                onGet?.();
              }
            }
          }}
        />

        {pwdData ? (
          <div className="modal-container bg-chatlook-white border w-4/12 2xl:w-4/12 xl:w-4/12 lg:w-6/12  md:w-7/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
            <div className="modal-content py-9 px-6 text-left  max-h-[84vh]">
              <div className="modal-header flex justify-between">
                <h4 className="modal-title mb-4 font-bold ccapitalize">
                  Delivery person's ID and password.
                </h4>
              </div>
              <div
                className="modal-body"
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>
                  <span>ID :- </span>
                  <span className="bold text-[#4d4444]">
                    {pwdData?.email_id}
                  </span>
                </span>
                <span>
                  <span>Password :- </span>
                  <span className="bold text-[#4d4444]">
                    {pwdData?.password}
                  </span>
                </span>
              </div>
              <div className="modal-footer pb-1">
                <div className="flex items-center justify-end gap-2">
                  <CopyToClipboard
                    text={`Id: ${pwdData?.email_id}\npassword: ${pwdData?.password}`}
                  >
                    <Button
                      className="submitbutton"
                      onClick={() => {
                        onClose();
                        showToaster("Copied");
                        onGet?.();
                      }}
                    >
                      Copy & Close
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-6/12 xl:w-6/12 lg:w-8/12  md:w-9/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
            <div className="modal-content p-6 text-left  max-h-[84vh]">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-header flex justify-between">
                  <h4 className="modal-title mb-4 font-bold capitalize">
                    {`${deliveredPersonData?.id ? "Update" : "Add"}`} Delivery
                    Person
                  </h4>
                  {!!deliveredPersonData?.id &&
                  !isgetDeliveryPersonByLoading ? (
                    <div className="flex items-start gap-4">
                      <ControlledSwitch
                        name="status"
                        label="Status"
                        control={control}
                        isDisabled={!deliveredPersonData?.id}
                      />
                    </div>
                  ) : null}
                </div>
                {isgetDeliveryPersonByLoading ? (
                  <div className="flex items-center justify-center">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <div className="modal-body">
                      <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 items-start gap-3 pt-2 pb-3">
                        <ControlledInput
                          name="first_name"
                          label="First Name"
                          placeholder="Enter First Name"
                          type="text"
                          autoFocus
                          errors={errors}
                          control={control}
                        />
                        <ControlledInput
                          name="last_name"
                          label="Last Name"
                          placeholder="Enter Last Name"
                          type="text"
                          errors={errors}
                          control={control}
                        />
                        <ControlledInput
                          name="email_id"
                          label="Email"
                          placeholder="Enter Email"
                          type="text"
                          errors={errors}
                          control={control}
                        />
                        <ControlledInput
                          name="mobile_number"
                          label="Mobile Number"
                          placeholder="Enter Mobile Number"
                          type="number"
                          onChange={(e) => {
                            if (
                              e?.target?.value?.length <= 10 &&
                              (e?.target?.value === "" ||
                                /^\d+$/.test(e?.target?.value))
                            ) {
                              setValue("mobile_number", e?.target?.value);
                              trigger("mobile_number");
                            }
                          }}
                          errors={errors}
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="modal-footer pb-1">
                      <div className="flex items-center justify-end gap-2">
                        <Button
                          type="submit"
                          className="mt-[10px] !h-9 !text-sm"
                          isLoading={addCpisLoading}
                          isLoader={addCpisLoading}
                        >
                          {deliveredPersonData?.id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          variant="Transaparent"
                          className="mt-[10px]  !text-sm !h-9"
                          disabled={addCpisLoading}
                          onClick={() => onClose()}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </Form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DeliveredPersonModal;
