import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, ControlledDropDown } from "../../ui";
import {
  useAddPromotionMutation,
  useUpdatePromotionMutation,
} from "./promotionApi";
import ControlledSwitch from "../../ui/forms/ControlledSwitch";
import { responseToaster } from "../../helperFunctions";
import SingleImagePicker from "../../ui/forms/SingleImagePicker";

const prepareBannerPositionOptions = [
  {
    value: 1,
    label: "Top",
  },
  {
    value: 2,
    label: "Middle",
  },
];

const PromotionModal = (props: any) => {
  const { onHide, isOpen, onGet, promotionData, currentTab } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);
  const [image, setImage] = useState<any>();
  const [image1, setImage1] = useState<any>();
  const [isAdmin, setIsAdmin] = useState(currentTab === "Admin");

  const [addPromotion] = useAddPromotionMutation();
  const [updatePromotion] = useUpdatePromotionMutation();

  const schema: any = yup.object({
    // title: yup.string().required("Please enter a title"),
    // description: yup.string().required("Please enter a description"),
    index_id: yup.string().required("Please enter a banner position"),
    photo: yup.string().required("Please add a image"),
  });

  const prepareDefaultValues = () => {
    if (promotionData?.id) {
      return {
        // title: promotionData?.title,
        // description: promotionData?.description,

        index_id: promotionData?.index_id,
        status: promotionData?.status,
      };
    } else {
      return {
        status: 1,
        index_id: prepareBannerPositionOptions?.[0]?.value,
      };
    }
  };

  const {
    control,
    clearErrors,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...prepareDefaultValues(),
    },
  });

  useEffect(() => {
    if (promotionData?.id) {
      setImage(
        `${process.env.REACT_APP_BASE_IMAGE_URL}${promotionData?.photo}`
      );

      // setValue("title", promotionData?.title);
      // setValue("description", promotionData?.description);
      setValue("photo", promotionData?.photo);
      setValue("index_id", promotionData?.index_id || 1);
      setValue("status", promotionData?.status === "active" ? 1 : 0);
    }
    setIsAdmin(currentTab === "Admin");
    // eslint-disable-next-line
  }, [promotionData, promotionData?.id]);

  useEffect(() => {
    setIsAdmin(currentTab === "Admin");
  }, [currentTab]);

  const onClose = () => {
    reset();
    onHide();
    setImage("");
    setImage1("");
    setIsAdmin(false);
  };

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const formData: any = new FormData();
      // formData.append("title", values.title);
      // formData.append("description", values.description);
      formData.append("photo", image1);
      formData.append("index_id", values?.index_id);

      if (currentTab !== "Vendor") {
        formData.append("vendor_id", 0);
      }

      const updateFormDate: any = new FormData();
      // updateFormDate.append("title", values.title);
      // updateFormDate.append("description", values.description);
      if (image1) {
        updateFormDate.append("photo", image1);
      }
      updateFormDate.append("id", promotionData.id);
      updateFormDate.append("status", values?.status ? "active" : "inactive");
      updateFormDate.append("index_id", values?.index_id);

      const res: any = promotionData?.id
        ? await updatePromotion(updateFormDate).unwrap()
        : await addPromotion(formData).unwrap();
      if (res?.status) {
        onClose();
        onGet?.();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addCpisLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-4/12 2xl:w-4/12 xl:w-4/12 lg:w-6/12  md:w-7/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-6 px-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <div>
                <h4 className="modal-title mb-4 font-bold capitalize">
                  {`${
                    promotionData?.id ? (!isAdmin ? "View" : "Update") : "Add"
                  }`}{" "}
                  Promotion
                </h4>
              </div>
              <div className="flex gap-2">
                {!!promotionData?.id ? (
                  <div className="flex items-start gap-4">
                    <ControlledSwitch
                      name="status"
                      label="Status"
                      control={control}
                      isDisabled={!promotionData?.id || !isAdmin}
                    />
                  </div>
                ) : null}
                {/*  {!isAdmin ? (
                  <div onClick={() => onClose()} className="cursor-pointer">
                    <IoIosCloseCircle color="var(--ev-primary--)" size={22} />
                  </div>
                ) : null} */}
              </div>
            </div>
            <div className="modal-body">
              <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 items-start gap-3 pt-2 pb-3">
                {/* <ControlledInput
                  name="title"
                  label="Title"
                  className="md:col-span-2 lg:col-span-2 2xl:col-span-2 xl:col-span-2 col-span-1"
                  placeholder="Enter Title"
                  type="text"
                  autoFocus
                  disabled={promotionData?.id}
                  errors={errors}
                  control={control}
                /> */}
                {/* <ControlledTextArea
                  name="description"
                  label="Description"
                  placeholder="Enter Description"
                  control={control}
                  errors={errors}
                  disabled={promotionData?.id}
                  className="textareacss"
                /> */}
                {isAdmin ? (
                  <ControlledDropDown
                    name="index_id"
                    label="Banner Position"
                    placeholder="Select Banner Position"
                    options={prepareBannerPositionOptions}
                    control={control}
                    errors={errors}
                    isDefaultOption=""
                    isDisabled={!isAdmin}
                  />
                ) : null}
                <SingleImagePicker
                  setImage={setImage}
                  setValue={setValue}
                  setImage1={setImage1}
                  clearErrors={clearErrors}
                  image={image}
                  className="col-span-2"
                  imgClassName="!h-[170px]"
                  errors={errors}
                  disabled={!isAdmin}
                  name={"photo"}
                  watch={watch}
                  label={"Image"}
                />
              </div>
            </div>

            <div className="modal-footer">
              <div className="flex items-center justify-end gap-2">
                {isAdmin ? (
                  <Button
                    type="submit"
                    className="mt-[10px] !h-9 !text-sm"
                    isLoading={addCpisLoading}
                    isLoader={addCpisLoading}
                  >
                    {promotionData?.id ? "Update" : "Submit"}
                  </Button>
                ) : null}
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={addCpisLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PromotionModal;
