import { api } from "../../utills/api";
import { LoginFormTypes } from "./types";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<any, LoginFormTypes>({
      query: (params) => ({
        url: "/admin/login",
        body: params,
        method: "POST",
      }),
    }),
    authLogout: build.mutation<{ data: any }, void>({
      query: (params?: any) => ({
        url: "/admin/logout",
        body: params,
        method: "GET",
      }),
    }),
    authOtpCheck: build.mutation<{ data: any }, void>({
      query: (params: any) => ({
        url: "/admin/2fa-verify",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useAuthLogoutMutation,
  useAuthOtpCheckMutation,
} = authApi;
export const {
  endpoints: { login, authLogout, authOtpCheck },
} = authApi;
