import { api } from "../../utills/api";

export const permissionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPermission: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/role/get-permission",
        body: params,
        method: "POST",
      }),
    }),
    updatePermission: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/permission/update-permissions",
        body: params,
        method: "POST",
      }),
    }),
    addPermission: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/role/add-permission",
        body: params,
        method: "POST",
      }),
    }),
    deletePermission: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/permission/delete-permission",
        body: params,
        method: "POST",
      }),
    }),
    getPermissionById: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/permission/get-permission-by-id",
        body: params,
        method: "POST",
      }),
    }),
    getPermissionList: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/role/get-permission",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetPermissionMutation,
  useUpdatePermissionMutation,
  useAddPermissionMutation,
  useDeletePermissionMutation,
  useGetPermissionByIdMutation,
  useGetPermissionListMutation,
} = permissionApi;
export const {
  endpoints: {
    getPermission,
    addPermission,
    updatePermission,
    deletePermission,
    getPermissionById,
    getPermissionList,
  },
} = permissionApi;
