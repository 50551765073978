import { api } from "../../utills/api";

export const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDashboard: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/dashboard-summary",
        body: params,
        method: "GET",
      }),
    }),
    getDeliveryDetails: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/setting/get-delivery-details",
        body: params,
        method: "GET",
      }),
    }),
    updateDeliveryDetails: build.mutation<{ data: any }, void>({
      query: (params: any) => ({
        url: "/admin/setting/add-delivery-details",
        body: params,
        method: "POST",
      }),
    }),
    getSettingDetails: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/developer/get-setting",
        body: params,
        method: "GET",
      }),
    }),
    updateSetting: build.mutation<{ data: any }, void>({
      query: (params: any) => ({
        url: "/admin/developer/update-setting",
        body: params,
        method: "POST",
      }),
    }),
    onEnable2fa: build.mutation<{ data: any }, void>({
      query: (params: any) => ({
        url: `/admin/2fa-enable${params?.isDisable ? "/disable" : ""}`,
        // body: params,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetDashboardMutation,
  useGetDeliveryDetailsMutation,
  useUpdateDeliveryDetailsMutation,
  useGetSettingDetailsMutation,
  useUpdateSettingMutation,
  useOnEnable2faMutation,
} = dashboardApi;
export const {
  endpoints: {
    getDashboard,
    getDeliveryDetails,
    updateDeliveryDetails,
    getSettingDetails,
    updateSetting,
    onEnable2fa,
  },
} = dashboardApi;
