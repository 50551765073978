import { api } from "../../../utills/api";

export const subCategoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSubCategory: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/category/get-sub-category",
        body: params,
        method: "POST",
      }),
    }),
    updateSubCategory: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/category/update-sub-category",
        body: params,
        method: "POST",
      }),
    }),
    addSubCategory: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/category/add-sub-category",
        body: params,
        method: "POST",
      }),
    }),
    deleteSubCategory: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/category/delete-sub-category",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetSubCategoryMutation,
  useUpdateSubCategoryMutation,
  useAddSubCategoryMutation,
  useDeleteSubCategoryMutation,
} = subCategoryApi;
export const {
  endpoints: {
    getSubCategory,
    addSubCategory,
    updateSubCategory,
    deleteSubCategory,
  },
} = subCategoryApi;
