import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";

import { Button, ControlledDropDown, ControlledInput } from "../../ui";
import ControlledTextArea from "../../ui/forms/ControlledTextArea";
import { responseToaster, showToaster } from "../../helperFunctions";
import {
  useGetFilterMutation,
  useSendNotificationMutation,
} from "./notificationApi";
import { useFilter } from "./notificationSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DROPDOWN_ALL_VALUE } from "../../constant";
import SingleImagePicker from "../../ui/forms/SingleImagePicker";
import { useRole } from "../auth/authSlice";
import { useNavigate } from "react-router-dom";

interface FormValues {
  vendor_id: [];
  user_id: [];
  type: string;
  text: string;
  title: string;
  image: File | Blob | string;
}

const NotificationForm = () => {
  const [sendNotification, { isLoading }] = useSendNotificationMutation();
  const [image, setImage] = useState<any>();
  const [image1, setImage1] = useState<any>();
  const [getFilter] = useGetFilterMutation();
  const vendorList = useFilter();

  // const [usersOptions, setUsersOptions] = useState([]);
  const [allVendorUsers, setAllVendorUsers] = useState({
    vendors: false,
    users: false,
  });

  const role = useRole("notification");
  const navigate = useNavigate();

  const typeOptions = [
    { label: "Coupon", value: "Coupon" },
    { label: "Promotion", value: "Promotion" },
  ];

  const initialValues = {
    vendor_id: [],
    user_id: [],
    text: "",
  };

  const schema = yup.object({
    type: yup.string().required("Type is required"),
    vendor_id: yup.array().min(1, "Please select vendor"),
    title: yup.string().required("Title is required"),
  });

  const getFilterData = async () => {
    try {
      await getFilter();
    } catch (error) {}
  };

  useEffect(() => {
    getFilterData();
      // eslint-disable-next-line
  }, []);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    clearErrors,
    trigger,
    watch,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver<any>(schema),
    defaultValues: initialValues,
  });
  const prepareVendorOptions = () => {
    const vendorOptions = vendorList?.vendorsList
      ?.filter?.((vendor: any) => vendor?.name !== null)
      ?.slice?.()
      ?.sort((a: { name: string }, b: { name: any }) =>
        a?.name?.localeCompare?.(b?.name)
      )
      ?.map((val: { id: any; name: any }) => ({
        value: val?.id,
        label: val?.name,
      }));
    return vendorOptions || [];
  };

  /* const prepareUserOptions = () => {
    const vendorId = getValues().vendor_id;
    if (vendorId?.length) {
      const userOptions = users?.data
        ?.filter((user: any) => vendorId?.includes(user?.vendorId))
        ?.slice?.()
        ?.sort((a: { firstName: string }, b: { firstName: string }) =>
          a?.firstName?.localeCompare?.(b?.firstName)
        )
        ?.map((val: { id: any; firstName: any }) => ({
          value: val?.id,
          label: val?.firstName,
        }));
      return userOptions || [];
    } else {
      // If vendorId is not provided or is not an array, return all users
      const userOptions = users?.data
        ?.slice?.()
        ?.sort((a: { firstName: string }, b: { firstName: string }) =>
          a?.firstName?.localeCompare?.(b?.firstName)
        )
        ?.map((val: { id: any; firstName: any }) => ({
          value: val?.id,
          label: val?.firstName,
        }));
      return userOptions || [];
    }
  }; */

  const onSubmit = async (values: FormValues) => {
    try {
      const formData: any = new FormData();
      const { vendor_id, user_id, type, text } = values;
      /* if (!vendor_id?.length && !user_id?.length) {
        showToaster("Please select atleast one user or vendor", "Error");
        return;
      } */

      if (!text?.length && !image1) {
        showToaster("Please add either text or image", "Error");
        return;
      }

      const payload: any = {
        vendor_id,
        user_id,
        type,
        text,
      };

      if (!vendor_id?.length) {
        delete payload?.vendor_id;
      }

      if (!user_id?.length) {
        delete payload?.user_id;
      }
      if (values?.vendor_id?.length) {
        formData.append(
          "vendor_id[]",
          allVendorUsers?.vendors ? [] : values?.vendor_id
        );
      }
      if (values?.user_id?.length) {
        formData.append(
          "user_id[]",
          allVendorUsers?.users ? [] : values?.user_id
        );
      }
      formData.append("type", values?.type);
      if (values?.text?.length) {
        formData.append("text", values?.text);
      }
      formData.append("title", values?.title);
      if (image1) {
        formData.append("image", image1);
      }

      const res = await sendNotification(formData).unwrap();
      responseToaster(res);
      if (res) {
        reset();
        setImage1(null);
        setImage("");
      }
    } catch (err) {}
  };

  /*   useEffect(() => {
    setUsersOptions(prepareUserOptions());
    // eslint-disable-next-line
  }, [watch("vendor_id")]); */

  if (!role.includes("create")) {
    navigate("/");
  }

  return (
    <div className="">
      {role.includes("create") ? (
        <div className="cardcss">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid sm:grid-cols-1 grid-cols-3 items-start gap-3 pt-2">
              <ControlledInput
                name="title"
                label="Title"
                placeholder="Enter Title"
                className="sm:col-span-1 col-span-3"
                type="text"
                control={control}
                errors={errors}
              />
              <ControlledDropDown
                name="vendor_id"
                label="Vendor"
                className="sm:col-span-1 col-span-2"
                placeholder="Select Vendor"
                isMulti
                isClearable={true}
                isDefaultOption={DROPDOWN_ALL_VALUE}
                options={prepareVendorOptions()}
                control={control}
                errors={errors}
                onChange={(e: any) => {
                  if (e?.includes(DROPDOWN_ALL_VALUE)) {
                    setValue(
                      "vendor_id",
                      prepareVendorOptions()?.map((item: any) => item?.value)
                    );
                    setAllVendorUsers({ ...allVendorUsers, vendors: true });
                  } else {
                    setValue("vendor_id", e);
                    setAllVendorUsers({ ...allVendorUsers, vendors: false });
                    setValue("user_id", []);
                  }
                  trigger("vendor_id");
                }}
              />
              {/*  <ControlledDropDown
                name="user_id"
                label="User"
                placeholder="Select User"
                isMulti
                isClearable={true}
                isDefaultOption={
                  usersOptions?.length ? DROPDOWN_ALL_VALUE : "No Users"
                }
                options={usersOptions}
                control={control}
                errors={errors}
                onChange={(e: any) => {
                  if (e?.includes(DROPDOWN_ALL_VALUE)) {
                    setValue(
                      "user_id",
                      prepareUserOptions()?.map((item: any) => item?.value)
                    );
                    setAllVendorUsers({ ...allVendorUsers, users: true });
                  } else {
                    setValue("user_id", e);
                    setAllVendorUsers({ ...allVendorUsers, users: false });
                  }
                }}
              /> */}
              <ControlledDropDown
                name="type"
                label="Type"
                placeholder="Select Type"
                type="text"
                options={typeOptions}
                control={control}
                errors={errors}
              />
              <ControlledTextArea
                name="text"
                label="Text"
                placeholder="Enter text..."
                control={control}
                errors={errors}
                className="textareacss col-span-2 sm:col-span-1"
              />
              <SingleImagePicker
                setImage={setImage}
                setValue={setValue}
                setImage1={setImage1}
                clearErrors={clearErrors}
                image={image}
                errors={errors}
                name={"image"}
                watch={watch}
                label={"Image"}
              />
              {/* <ControlledTextArea
                name="text"
                label="Text"
                placeholder="Enter text"
                control={control}
                errors={errors}
              /> */}
            </div>
            <div className="pt-2">
              <div className="flex justify-end button-space">
                <Button
                  type="submit"
                  className="submitbutton"
                  isLoading={isLoading}
                  isLoader={isLoading}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </div>
      ) : null}
    </div>
  );
};

export default NotificationForm;
