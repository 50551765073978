import { api } from "../../utills/api";

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/user/get-user",
        body: params,
        method: "POST",
      }),
    }),
    updateUsers: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/user/update-user",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetUsersMutation, useUpdateUsersMutation } = usersApi;
export const {
  endpoints: { getUsers, updateUsers },
} = usersApi;
