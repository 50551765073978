import React, { memo } from "react";
import { IconProps } from ".";

export interface UpArrowIconProps extends IconProps {
}
const UpArrowIcon = (props: UpArrowIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="m18 15-6-6-6 6" className={`${pathClassNames?.[0] || ""}`} />
    </svg>
  );
};

const MemoUpArrowIcon = memo(UpArrowIcon);
export default MemoUpArrowIcon;
