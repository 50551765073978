import { api } from "../../utills/api";

export const productApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProduct: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/product/get-product",
        body: params,
        method: "POST",
      }),
    }),
    addProduct: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/product/add-product",
        body: params,
        method: "POST",
      }),
    }),
    updateProduct: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/product/update-product",
        body: params,
        method: "POST",
      }),
    }),
    deleteProduct: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/product/delete-product",
        body: params,
        method: "POST",
      }),
    }),
    getVendors: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/vendor/get-vendor",
        body: params,
        method: "POST",
      }),
    }),
    getCategories: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/category/get-category",
        body: params,
        method: "POST",
      }),
    }),
    getProductById: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/product/get-product-by-id",
        body: params,
        method: "POST",
      }),
    }),
    importProduct: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/product/import-product",
        body: params,
        method: "POST",
      }),
    }),
    getPolicyOption: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/policy/get-policy",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetProductMutation,
  useAddProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useGetVendorsMutation,
  useGetCategoriesMutation,
  useGetProductByIdMutation,
  useImportProductMutation,
  useGetPolicyOptionMutation,
} = productApi;
export const {
  endpoints: {
    getProduct,
    addProduct,
    updateProduct,
    deleteProduct,
    getVendors,
    getCategories,
    getProductById,
    importProduct,
    getPolicyOption,
  },
} = productApi;
