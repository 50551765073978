import React, { Fragment, useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import {
  useGetSubscriptionsMutation,
  useUpdateSubscriptionsMutation,
} from "./subscriptionsApi";
import { setSubscriptions, useSubscriptionsList } from "./subscriptionsSlice";
import { Table, TableRow } from "../../../ui";
import Switch from "../../../ui/forms/Switch";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import SubscriptionUpdate from "./SubscriptionUpdate";
import UpdateDD from "./UpdateDD";
import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../auth/authSlice";

const breadcrumbs = [{ label: "Subscriptions" }, { label: "Plan" }];

const Plan = () => {
  const [getSubscriptions, { isLoading }] = useGetSubscriptionsMutation();
  // eslint-disable-next-line
  const [filter, setFilter] = useState(FILTER);
  const [updateSubscriptions] = useUpdateSubscriptionsMutation();

  const [tableData, setTableData] = useState<any>([]);
  const [statusLoading, setStatusLoading] = useState(false);

  const [currKey, setCurrKey] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = useRole("plan");

  if (!role.includes("list")) {
    navigate("/");
  }
  const subscriptionsList = useSubscriptionsList();

  const onUpdateStatus = async (payload: any, key: string) => {
    try {
      setStatusLoading(payload?.id);
      setCurrKey(key);
      const res = await updateSubscriptions(payload).unwrap();

      const index = tableData?.findIndex(
        (item: { id: any }) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...tableData];
        newData[index] = {
          ...newData[index],
          [key]: payload?.[key],
        };
        dispatch(setSubscriptions({ ...subscriptionsList, data: newData }));
      }
      responseToaster(res);
      setStatusLoading(false);
      setCurrKey("");
    } catch (error) {
      setCurrKey("");
      setStatusLoading(false);
    }
  };

  const columns = [
    {
      title: "Package",
      name: "package",
      sortable: true,
      sortableKey: "package",
    },
    {
      title: "Plane",
      name: "plane",
      sortable: true,
      sortableKey: "plane",
    },
    {
      title: "Package Price",
      name: "package_price",
      sortable: true,
      sortableKey: "package_price",
      Cell: (data: any) => {
        return (
          <>
            {data?.plane === "Free Trial" ? (
              data?.package_price
            ) : (
              <SubscriptionUpdate
                currKey={"package_price"}
                tableData={tableData}
                data={data}
                unlimited={false}
                rupee={true}
                isDisable={role.includes("edit")}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Online Store",
      name: "online_store",
      sortable: true,
      sortableKey: "online_store",
      Cell: (data: any) => (
        <Switch
          checked={data?.online_store === "Yes"}
          isLoading={statusLoading === data?.id && currKey === "online_store"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                online_store: value ? "Yes" : "No",
                id: data?.id,
              };
              await onUpdateStatus(payload, "online_store");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Stock Management",
      name: "stock_management",
      sortable: true,
      sortableKey: "stock_management",
      Cell: (data: any) => (
        <Switch
          checked={data?.stock_management === "Yes"}
          isLoading={
            statusLoading === data?.id && currKey === "stock_management"
          }
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                stock_management: value ? "Yes" : "No",
                id: data?.id,
              };
              await onUpdateStatus(payload, "stock_management");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Product Limits",
      name: "product_limits",
      sortable: true,
      sortableKey: "product_limits",
      Cell: (data: any) => {
        return (
          <>
            <SubscriptionUpdate
              currKey={"product_limits"}
              tableData={tableData}
              data={data}
              isDisable={role.includes("edit")}
            />
          </>
        );
      },
    },
    {
      title: "Transaction Charges",
      name: "transaction_charges",
      sortable: true,
      sortableKey: "transaction_charges",
      Cell: (data: any) => (
        <Switch
          checked={data?.transaction_charges === "Yes"}
          isLoading={
            statusLoading === data?.id && currKey === "transaction_charges"
          }
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                transaction_charges: value ? "Yes" : "No",
                id: data?.id,
              };
              await onUpdateStatus(payload, "transaction_charges");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "QR Code",
      name: "QR_code",
      sortable: true,
      sortableKey: "QR_code",
      Cell: (data: any) => (
        <Switch
          checked={data?.QR_code === "Yes"}
          isLoading={statusLoading === data?.id && currKey === "QR_code"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                QR_code: value ? "Yes" : "No",
                id: data?.id,
              };
              await onUpdateStatus(payload, "QR_code");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Customer Support",
      name: "customer_Support",
      sortable: true,
      sortableKey: "customer_Support",
      Cell: (data: any) => {
        return (
          <>
            <UpdateDD
              currKey={"customer_Support"}
              tableData={tableData}
              data={data}
              isDisable={role.includes("edit")}
            />
          </>
        );
      },
    },
    {
      title: "Category Limit",
      name: "categoery_limit",
      sortable: true,
      sortableKey: "categoery_limit",
      Cell: (data: any) => {
        return (
          <>
            <SubscriptionUpdate
              currKey={"categoery_limit"}
              tableData={tableData}
              data={data}
              isDisable={role.includes("edit")}
            />
          </>
        );
      },
    },
    {
      title: "Customer List",
      name: "customer_list",
      sortable: true,
      sortableKey: "customer_list",
      Cell: (data: any) => (
        <Switch
          checked={data?.customer_list === "Yes"}
          isLoading={statusLoading === data?.id && currKey === "customer_list"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                customer_list: value ? "Yes" : "No",
                id: data?.id,
              };
              await onUpdateStatus(payload, "customer_list");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Social Media Integration",
      name: "social_media_integration",
      sortable: true,
      sortableKey: "social_media_integration",
      Cell: (data: any) => (
        <Switch
          checked={data?.social_media_integration === "Yes"}
          isLoading={
            statusLoading === data?.id && currKey === "social_media_integration"
          }
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                social_media_integration: value ? "Yes" : "No",
                id: data?.id,
              };
              await onUpdateStatus(payload, "social_media_integration");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Coupons",
      name: "coupons",
      sortable: true,
      sortableKey: "coupons",
      Cell: (data: any) => (
        <Switch
          checked={data?.coupons === "Yes"}
          isDisabled={!role.includes("edit") || statusLoading}
          isLoading={statusLoading === data?.id && currKey === "coupons"}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                coupons: value ? "Yes" : "No",
                id: data?.id,
              };
              await onUpdateStatus(payload, "coupons");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Store Analytics",
      name: "store_analytics",
      sortable: true,
      sortableKey: "store_analytics",
      Cell: (data: any) => (
        <Switch
          checked={data?.store_analytics === "Yes"}
          isDisabled={!role.includes("edit") || statusLoading}
          isLoading={
            statusLoading === data?.id && currKey === "store_analytics"
          }
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                store_analytics: value ? "Yes" : "No",
                id: data?.id,
              };
              await onUpdateStatus(payload, "store_analytics");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Delivery App",
      name: "delivery_app",
      sortable: true,
      sortableKey: "delivery_app",
      Cell: (data: any) => (
        <Switch
          checked={data?.delivery_app === "Yes"}
          isDisabled={!role.includes("edit") || statusLoading}
          isLoading={statusLoading === data?.id && currKey === "delivery_app"}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                delivery_app: value ? "Yes" : "No",
                id: data?.id,
              };
              await onUpdateStatus(payload, "delivery_app");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "In Store Billing",
      name: "instore_billing",
      sortable: true,
      sortableKey: "instore_billing",
      Cell: (data: any) => (
        <Switch
          checked={data?.instore_billing === "Yes"}
          isDisabled={!role.includes("edit") || statusLoading}
          isLoading={
            statusLoading === data?.id && currKey === "instore_billing"
          }
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                instore_billing: value ? "Yes" : "No",
                id: data?.id,
              };
              await onUpdateStatus(payload, "instore_billing");
            } catch (err) {}
          }}
        />
      ),
    },
  ];

  const getSubscriptionsList = async () => {
    try {
      await getSubscriptions();
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    setTableData(
      subscriptionsList?.data?.length ? [...subscriptionsList?.data] : []
    );
  }, [subscriptionsList]);

  useEffect(() => {
    if (role.includes("list")) {
      getSubscriptionsList();
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
      {role.includes("list") ? (
        <div className="cardcss">
          <Table
          containerClass="planTable"
            columns={columns}
            isExpendable={false}
            isLoading={isLoading}
            data={tableData || []}
            setData={setTableData}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    isExpendable={false}
                    item={item}
                  ></TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
    </>
  );
};

export default Plan;
