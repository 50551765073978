import React, { Fragment, useEffect, useState } from "react";
import { Button, Table, TableRow } from "../../ui";
import { FILTER } from "../../constant";
import { useGetPolicyMutation } from "./policyApi";
import { usePolicyList } from "./policySlice";
import PolicyModal from "./PolicyModal";
import { MdEdit } from "react-icons/md";
import { responseToaster } from "../../helperFunctions";
import Tooltip from "../../ui/elements/Tooltip";
import { useNavigate } from "react-router-dom";
import { useRole } from "../auth/authSlice";

const Policy = () => {
  const [getPolicy, { isLoading }] = useGetPolicyMutation();
  const policyList = usePolicyList();
  const [isPolicyModal, setIsPolicyModal] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [policyData, setPolicyData] = useState({});
  const navigate = useNavigate();
  const role = useRole("policy");

  if (!role.includes("list")) {
    navigate("/");
  }
  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Attribute",
      name: "attribute",
      sortable: true,
      sortableKey: "attribute",
    },
    {
      title: "Description",
      name: "description",
      sortable: true,
      sortableKey: "description",
    },
    {
      title: "Action",
      name: "action",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            <Tooltip text="Edit">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setIsPolicyModal(true);
                  setPolicyData(item);
                }}
                disabled={!role.includes("edit")}
              >
                <MdEdit />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const getPolicyList = async () => {
    try {
      const payload: any = {
        ...filter,
        filter_data: {
          vendor_id: filter?.vendor_id,
        },
      };

      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getPolicy(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    if (role.includes("list")) {
      getPolicyList();
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setTableData(policyList?.data?.length ? [...policyList?.data] : []);
  }, [policyList]);

  return (
    <>
      <div>
        {role.includes("list") ? (
          <div className="cardcss">
            <Table
              columns={columns}
              className="policyTable"
              isExpendable={false}
              isLoading={isLoading}
              data={tableData || []}
              setData={setTableData}
              count={policyList?.total_item}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                      onClick={(item: any) => {}}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        ) : null}
      </div>
      <PolicyModal
        isOpen={isPolicyModal}
        onGet={getPolicyList}
        policyData={policyData}
        onHide={() => {
          setIsPolicyModal(false);
          setPolicyData({});
        }}
      />
    </>
  );
};

export default Policy;
