import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { pinCodeApi } from "./pinCodeApi";

interface UserState {
  pinCodeList: any;
}

const initialState: UserState = {
  pinCodeList: {},
};

export const pinCodeSlice = createSlice({
  name: "pinCode",
  initialState,
  reducers: {
    clearPinCode: () => initialState,
    setPinCode: (state, action) => {
      state.pinCodeList = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      pinCodeApi.endpoints.getPinCode.matchFulfilled,
      (state, action: any) => {
        state.pinCodeList = action?.payload;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default pinCodeSlice.reducer;
export const { clearPinCode, setPinCode } =
  pinCodeSlice.actions;

export const selectPinCodeList = (state: RootState) =>
  state.pinCode.pinCodeList;
export const usePinCodeList = () => {
  const pinCodeList = useSelector(selectPinCodeList);
  return useMemo(() => pinCodeList, [pinCodeList]);
};
