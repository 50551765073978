import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, ControlledDropDown, ControlledInput } from "../../../ui";
import {
  useAddSubCategoryMutation,
  useUpdateSubCategoryMutation,
} from "./subCategoryApi";
import {
  prepareVendorOptions,
  responseToaster,
} from "../../../helperFunctions";
import { useGetVendorMutation } from "../../vendor/vendorList/vendorApi";
import { useVendorList } from "../../vendor/vendorList/vendorSlice";
import SingleImagePicker from "../../../ui/forms/SingleImagePicker";

const SubCategoryModal = (props: any) => {
  const { onHide, isOpen, onGet, subCategoryData, currentTab } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<any>(true);
  const [addSubCategory] = useAddSubCategoryMutation();
  const [updateSubCategory] = useUpdateSubCategoryMutation();
  const [getVendorList] = useGetVendorMutation();
  const [image, setImage] = useState<any>();
  const [image1, setImage1] = useState<any>();
  const vendorList = useVendorList();

  const schema: any = yup.object({
    name: yup.string().required("Please enter a sub category name"),
    vendor_id: yup.string().required("Please select vendor"),
    photo: yup.string().required("Please add a image"),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    reset();
    onHide();
    setImage("");
    setIsAdmin(true);
  };
  useEffect(() => {
    if (isOpen) {
      if (subCategoryData?.id) {
        if (subCategoryData?.photo) {
          setImage(
            `${process.env.REACT_APP_BASE_IMAGE_URL}${subCategoryData?.photo}`
          );
        }
        setValue("name", subCategoryData?.name);
        setValue("vendor_id", subCategoryData?.vendor_id);
        setIsAdmin(subCategoryData?.is_admin === 1);
      }
    }
    // eslint-disable-next-line
  }, [isOpen, subCategoryData?.id]);

  useEffect(() => {
    if (isOpen) {
      getVendorList();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const formData: any = new FormData();
      formData.append("name", values.name);
      formData.append("vendor_id", values.vendor_id);
      if (image1) {
        formData.append("photo", image1);
      }
      if (subCategoryData?.id) {
        formData.append("id", subCategoryData?.id);
      }
      /*  const payload = { 
        ...values,
      }; */
      const res: any = subCategoryData?.id
        ? await updateSubCategory(formData).unwrap()
        : await addSubCategory(formData).unwrap();
      if (res?.status) {
        onClose();
        onGet?.();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addCpisLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-4/12 xl:w-6/12 lg:w-6/12  md:w-6/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                {`${
                  subCategoryData?.id
                    ? currentTab === "Vendor"
                      ? "View"
                      : "Update"
                    : "Add"
                }`}{" "}
                Sub Category
              </h4>
              {/* {!isAdmin ? (
                <div onClick={() => onClose()} className="cursor-pointer">
                  <IoIosCloseCircle color="var(--ev-primary--)" size={22} />
                </div>
              ) : null} */}
            </div>
            <div className="modal-body">
              <div className="grid grid-cols-1 items-start gap-3 pt-2 pb-3">
                <ControlledInput
                  name="name"
                  label="Sub Category Name"
                  placeholder="Enter Sub Category Name"
                  type="text"
                  className="sm:col-span-2"
                  errors={errors}
                  control={control}
                  disabled={!isAdmin}
                />
                <ControlledDropDown
                  name="vendor_id"
                  label="Vendor"
                  className="sm:col-span-2"
                  placeholder="Select Vendor"
                  options={prepareVendorOptions(vendorList)}
                  control={control}
                  errors={errors}
                  isDisabled={!isAdmin}
                />
                <SingleImagePicker
                  setImage={setImage}
                  setValue={setValue}
                  setImage1={setImage1}
                  clearErrors={clearErrors}
                  image={image}
                  errors={errors}
                  name={"photo"}
                  watch={watch}
                  label={"Image"}
                />
              </div>
            </div>

            <div className="modal-footer">
              <div className="flex items-center justify-end gap-2">
                {currentTab === "Admin" ? (
                  <Button
                    type="submit"
                    className="mt-[10px] !h-9 !text-sm"
                    isLoading={addCpisLoading}
                    isLoader={addCpisLoading}
                  >
                    {subCategoryData?.id ? "Update" : "Submit"}
                  </Button>
                ) : null}
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={addCpisLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SubCategoryModal;
