import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { notificationApi } from "./notificationApi";

interface UserState {
  vendors: any;
  users: any;
  filterData: any;
}

const initialState: UserState = {
  vendors: [],
  users: [],
  filterData: [],
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    clearNotification: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      notificationApi.endpoints.getVendors.matchFulfilled,
      (state, { payload }) => {
        state.vendors = payload || [];
      }
    );
    builder.addMatcher(
      notificationApi.endpoints.getUsers.matchFulfilled,
      (state, { payload }) => {
        state.users = payload || [];
      }
    );
    builder.addMatcher(
      notificationApi.endpoints.getFilter.matchFulfilled,
      (state, { payload }) => {
        state.filterData = payload || [];
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default notificationSlice.reducer;
export const { clearNotification } = notificationSlice.actions;

export const selectVendors = (state: RootState) => state.notification.vendors;
export const useVendors = () => {
  const vendors = useSelector(selectVendors);
  return useMemo(() => vendors, [vendors]);
};

export const selectUsers = (state: RootState) => state.notification.users;
export const useUsers = () => {
  const users = useSelector(selectUsers);
  return useMemo(() => users, [users]);
};

export const selectFilter = (state: RootState) => state.notification.filterData;
export const useFilter = () => {
  const users = useSelector(selectFilter);
  return useMemo(() => users, [users]);
};
