import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  ControlledDropDown,
  ControlledInput,
  Loader,
} from "../../../ui";
import {
  useUpdateCourierPartnerMutation,
  useAddCourierPartnerMutation,
  useGetCourierPartnerByIdMutation,
} from "./courierPartnerApi";
import ControlledSwitch from "../../../ui/forms/ControlledSwitch";
import { responseToaster } from "../../../helperFunctions";
import { DROPDOWN_ALL_VALUE, statusOptions1 } from "../../../constant";
import { useCourierPartnerList } from "./courierPartnerSlice";

const CourierPartnerModal = (props: any) => {
  const { onHide, isOpen, onGet, courierPartnerData } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);
  const [addCourierPartner] = useAddCourierPartnerMutation();
  const [getCourierPartnerById, { isLoading: isgetCourierPartnerByLoading }] =
    useGetCourierPartnerByIdMutation();
  const [updateCourierPartner] = useUpdateCourierPartnerMutation();
  const courierPartnerList = useCourierPartnerList();

  const schema: any = yup.object({
    name: yup.string().required("Please enter a name"),
  });

  const prepareDefaultValues = () => {
    if (courierPartnerData?.id) {
      return {
        name: courierPartnerData?.name,
        status: courierPartnerData?.status === 1 ? 1 : 0,
        is_local: 0,
      };
    } else {
      return {
        status: 1,
        is_local: 0,
      };
    }
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...prepareDefaultValues(),
    },
  });

  const onClose = () => {
    reset();
    onHide();
  };

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const payload = {
        ...values,
        ...(courierPartnerData?.id ? { id: courierPartnerData?.id } : null),
      };

      const res: any = courierPartnerData?.id
        ? await updateCourierPartner(payload).unwrap()
        : await addCourierPartner(payload).unwrap();
      if (res?.status) {
        onClose();
        onGet?.();
      }

      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  const getCourierPartnerData = async () => {
    try {
      const payload: any = {
        id: courierPartnerData?.id,
      };
      const res: any = await getCourierPartnerById(payload);
      if (res?.data?.status) {
        setFormData(res?.data?.data);
      }
    } catch (error) {}
  };

  const setFormData = (courierPartnerData: any) => {
    setValue("name", courierPartnerData?.name);
    setValue("status", courierPartnerData?.status === 1 ? 1 : 0);
    setValue(
      "pin_code",
      courierPartnerData?.courier_pin_code?.map((pincode: any) => {
        return pincode?.pin_code_id;
      })
    );
    setValue("is_local", courierPartnerData?.is_local);
  };

  const preparePincode = () => {
    const userOptions = courierPartnerList?.pinCodeList
      ?.filter?.((pincode: any) => pincode?.pin_code !== null)
      ?.slice?.()
      ?.sort((a: any, b: any) => a?.pin_code?.localeCompare?.(b?.pin_code))
      ?.map((val: any) => ({
        value: val?.id,
        label: val?.pin_code,
      }));
    return userOptions || [];
  };

  useEffect(() => {
    if (isOpen && courierPartnerData?.id) {
      getCourierPartnerData();
    }
    // eslint-disable-next-line
  }, [isOpen, courierPartnerData, courierPartnerData?.id]);

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
          onClick={() => {
            if (!addCpisLoading) {
              onClose();
            }
          }}
        />

        <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-4/12 xl:w-4/12 lg:w-5/12  md:w-6/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
          <div className="modal-content p-6 text-left  max-h-[84vh]">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-header flex justify-between">
                <h4 className="modal-title mb-4 font-bold capitalize">
                  {`${courierPartnerData?.id ? "Update" : "Add"}`} Courier
                  Partner
                </h4>
                {!!courierPartnerData?.id && !isgetCourierPartnerByLoading ? (
                  <div className="flex items-start gap-4">
                    <ControlledSwitch
                      name="status"
                      label="Status"
                      control={control}
                      isDisabled={!courierPartnerData?.id}
                    />
                  </div>
                ) : null}
              </div>
              {isgetCourierPartnerByLoading ? (
                <div className="flex items-center justify-center">
                  <Loader />
                </div>
              ) : (
                <>
                  <div className="modal-body">
                    <div className="grid grid-cols-1 items-start gap-3 pt-2 pb-3">
                      <ControlledInput
                        name="name"
                        label="Name"
                        placeholder="Enter Name"
                        type="text"
                        autoFocus
                        errors={errors}
                        control={control}
                      />
                      <ControlledDropDown
                        name="pin_code"
                        label="Pincode"
                        placeholder="Select"
                        isDefaultOption={DROPDOWN_ALL_VALUE}
                        isMulti
                        options={preparePincode()}
                        onChange={(e: any) => {
                          if (e?.includes(DROPDOWN_ALL_VALUE)) {
                            setValue(
                              "pin_code",
                              preparePincode()?.map((item: any) => item?.value)
                            );
                          } else {
                            setValue("pin_code", e);
                          }
                        }}
                        control={control}
                        errors={errors}
                      />
                      <ControlledDropDown
                        name="is_local"
                        label="Is localfirst courier?"
                        placeholder="Select"
                        isDefaultOption={false}
                        options={statusOptions1}
                        control={control}
                        errors={errors}
                      />
                    </div>
                  </div>
                  <div className="modal-footer pb-1">
                    <div className="flex items-center justify-end gap-2">
                      <Button
                        type="submit"
                        className="mt-[10px] !h-9 !text-sm"
                        isLoading={addCpisLoading}
                        isLoader={addCpisLoading}
                      >
                        {courierPartnerData?.id ? "Update" : "Submit"}
                      </Button>
                      <Button
                        variant="Transaparent"
                        className="mt-[10px]  !text-sm !h-9"
                        disabled={addCpisLoading}
                        onClick={() => onClose()}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourierPartnerModal;
