import { api } from "../../../utills/api";

export const deliveredByApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDeliveredBy: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/order/get-order",
        body: params,
        method: "POST",
      }),
    }),
    getOrder: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/order/get-order-by-id",
        body: params,
        method: "POST",
      }),
    }),
    getDeliveryPersonList: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/setting/get-delivery-person-list",
        body: params,
        method: "GET",
      }),
    }),
    assignDeliveryPerson: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/order/assign-delivery-person",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetDeliveredByMutation,
  useGetOrderMutation,
  useGetDeliveryPersonListMutation,
  useAssignDeliveryPersonMutation,
} = deliveredByApi;
export const {
  endpoints: {
    getDeliveredBy,
    getOrder,
    getDeliveryPersonList,
    assignDeliveryPerson,
  },
} = deliveredByApi;
