import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { productApi } from "./productApi";

interface UserState {
  productList: any;
  productVendor: any;
  productUserList: any;
  productUnApprovedList: any;
  vendors: any;
  categories: any;
  currentProduct: any | null;
  currTab: any;
  policyList: any;
}

const initialState: UserState = {
  productList: {},
  productVendor: {},
  productUserList: {},
  productUnApprovedList: {},
  vendors: [],
  categories: [],
  currentProduct: null,
  currTab: "Admin",
  policyList: {},
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    clearProduct: () => initialState,
    setCurrentProduct: (state, { payload }) => {
      state.currentProduct = payload || null;
    },
    setProduct: (state, action) => {
      if (action?.payload?.currentTab === "Admin") {
        state.productList = action?.payload;
      }
      if (action?.payload?.currentTab === "Vendor") {
        state.productVendor = action?.payload;
      }
      if (action?.payload?.currentTab === "UnApproved") {
        state.productUnApprovedList = action?.payload;
      }
    },
    setCurrTab: (state, { payload }) => {
      state.currTab = payload || null;
    },
    unsetPolicyList: (state) => {      
      state.policyList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      productApi.endpoints.getProduct.matchFulfilled,
      (state, action: any) => {
        if (action?.meta?.arg?.originalArgs?.filter_data?.is_admin) {
          state.productList = action?.payload;
        }
        if (!action?.meta?.arg?.originalArgs?.filter_data?.is_admin) {
          state.productVendor = action?.payload;
        }
        if (!action?.meta?.arg?.originalArgs?.filter_data?.is_approved) {
          state.productUnApprovedList = action?.payload;
        }
      }
    );
    builder.addMatcher(
      productApi.endpoints.getVendors.matchFulfilled,
      (state, { payload }) => {
        state.vendors = payload || [];
      }
    );
    builder.addMatcher(
      productApi.endpoints.getCategories.matchFulfilled,
      (state, { payload }) => {
        state.categories = payload || [];
      }
    );
    builder.addMatcher(
      productApi.endpoints.getPolicyOption.matchFulfilled,
      (state, { payload }) => {
        state.policyList = payload || [];
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default productSlice.reducer;
export const {
  clearProduct,
  setCurrentProduct,
  setProduct,
  setCurrTab,
  unsetPolicyList,
} = productSlice.actions;

export const selectProductList = (state: RootState) =>
  state.product.productList;
export const useProductList = () => {
  const productList = useSelector(selectProductList);
  return useMemo(() => productList, [productList]);
};

export const selectVendorProductList = (state: RootState) =>
  state.product.productVendor;
export const useVendorProductList = () => {
  const productVendorList = useSelector(selectVendorProductList);
  return useMemo(() => productVendorList, [productVendorList]);
};

export const selectUnApprovedProductList = (state: RootState) =>
  state.product.productUnApprovedList;
export const useUnApprovedProductList = () => {
  const productUnApprovedList = useSelector(selectUnApprovedProductList);
  return useMemo(() => productUnApprovedList, [productUnApprovedList]);
};

export const selectVendorList = (state: RootState) => state.product.vendors;
export const useVendorList = () => {
  const vendors = useSelector(selectVendorList);
  return useMemo(() => vendors, [vendors]);
};

export const selectCategories = (state: RootState) => state.product.categories;
export const useCategories = () => {
  const categories = useSelector(selectCategories);
  return useMemo(() => categories, [categories]);
};

export const selectCurrentProduct = (state: RootState) =>
  state.product.currentProduct;
export const useCurrentProduct = () => {
  const currentProduct = useSelector(selectCurrentProduct);
  return useMemo(() => currentProduct, [currentProduct]);
};

export const selectCurrentTab = (state: RootState) => state.product.currTab;
export const useCurrentTab = () => {
  const currentTab = useSelector(selectCurrentTab);
  return useMemo(() => currentTab, [currentTab]);
};

export const selectPolicyOption = (state: RootState) =>
  state.product.policyList;
export const usePolicyList = () => {
  const policyList = useSelector(selectPolicyOption);
  return useMemo(() => policyList, [policyList]);
};
