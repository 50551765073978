import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { permissionApi } from "./permissionApi";

interface PermissionState {
  permissionList: any;
  permissionData: any;
  productPermission: any;
}

const initialState: PermissionState = {
  permissionList: {},
  permissionData: {},
  productPermission: {},
};

export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    clearPermission: () => initialState,
    setPermission: (state, { payload }) => {
      state.permissionList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      permissionApi.endpoints.addPermission.matchFulfilled,
      (state, { payload }) => {
        state.permissionData = payload || {};
      }
    );

    builder.addMatcher(
      permissionApi.endpoints.getPermissionList.matchFulfilled,
      (state, { payload }) => {
        state.permissionList = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default permissionSlice.reducer;
export const { clearPermission, setPermission } = permissionSlice.actions;

export const selectPermissionList = (state: RootState) =>
  state.permission.permissionList;
export const usePermissionList = () => {
  const permissionList = useSelector(selectPermissionList);
  return useMemo(() => permissionList, [permissionList]);
};

export const selectPermissionData = (state: RootState) => state.permission.permissionData;
export const usePermissionData = () => {
  const permissionData = useSelector(selectPermissionData);
  return useMemo(() => permissionData, [permissionData]);
};
