import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, ControlledInput, Loader } from "../../../ui";
import {
  useUpdateCityMutation,
  useAddCityMutation,
  useGetCityByIdMutation,
} from "./cityApi";
import ControlledSwitch from "../../../ui/forms/ControlledSwitch";
import { responseToaster } from "../../../helperFunctions";

const CityModal = (props: any) => {
  const { onHide, isOpen, onGet, cityData } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);

  const [addCity] = useAddCityMutation();
  const [getCityById, { isLoading: isgetCityByLoading }] =
    useGetCityByIdMutation();
  const [updateCity] = useUpdateCityMutation();

  const schema: any = yup.object({
    name: yup.string().required("Please enter city name"),
  });

  const prepareDefaultValues = () => {
    if (cityData?.id) {
      return {
        name: cityData?.name,
        status: cityData?.status === 1 ? 1 : 0,
      };
    } else {
      return {
        status: 1,
      };
    }
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...prepareDefaultValues(),
    },
  });

  const onClose = () => {
    reset();
    onHide();
  };

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const payload = {
        ...values,
        ...(cityData?.id ? { id: cityData?.id } : null),
      };

      const res: any = cityData?.id
        ? await updateCity(payload).unwrap()
        : await addCity(payload).unwrap();
      if (res?.status && !res?.warning) {
        onClose();
        onGet?.();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  const getCityData = async () => {
    try {
      const payload: any = {
        id: cityData?.id,
      };
      const res: any = await getCityById(payload);
      if (res?.data?.status) {
        setFormData(res?.data?.data);
      }
    } catch (error) {}
  };

  const setFormData = (cityData: any) => {
    setValue("name", cityData?.name);
    setValue("status", cityData?.status === 1 ? 1 : 0);
  };

  useEffect(() => {
    if (isOpen && cityData?.id) {
      getCityData();
    }
    // eslint-disable-next-line
  }, [isOpen, cityData, cityData?.id]);

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
          onClick={() => {
            if (!addCpisLoading) {
              onClose();
            }
          }}
        />
        <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-4/12 xl:w-4/12 lg:w-5/12  md:w-6/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
          <div className="modal-content p-6 text-left  max-h-[84vh]">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-header flex justify-between">
                <h4 className="modal-title mb-4 font-bold capitalize">
                  {`${cityData?.id ? "Update" : "Add"}`} City
                </h4>
                {!!cityData?.id && !isgetCityByLoading ? (
                  <div className="flex items-start gap-4">
                    <ControlledSwitch
                      name="status"
                      label="Status"
                      control={control}
                      isDisabled={!cityData?.id}
                    />
                  </div>
                ) : null}
              </div>
              {isgetCityByLoading ? (
                <div className="flex items-center justify-center">
                  <Loader />
                </div>
              ) : (
                <>
                  <div className="modal-body">
                    <div className="grid grid-cols-1 items-start gap-3 pt-2 pb-3">
                      <ControlledInput
                        name="name"
                        label="City Name"
                        placeholder="Enter City Name"
                        type="text"
                        autoFocus
                        errors={errors}
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="modal-footer pb-1">
                    <div className="flex items-center justify-end gap-2">
                      <Button
                        type="submit"
                        className="mt-[10px] !h-9 !text-sm"
                        isLoading={addCpisLoading}
                        isLoader={addCpisLoading}
                      >
                        {cityData?.id ? "Update" : "Submit"}
                      </Button>
                      <Button
                        variant="Transaparent"
                        className="mt-[10px]  !text-sm !h-9"
                        disabled={addCpisLoading}
                        onClick={() => onClose()}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CityModal;
