import React, { memo } from "react";
import { IconProps } from ".";

export interface RatingReviewIconProps extends IconProps {}
const RatingReviewIcon = (props: RatingReviewIconProps) => {
  const {
    className = "",
    size = 20,
    stroke = "currentColor",
    fill = "none",
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      stroke={stroke}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
    </svg>
  );
};

const MemoRatingReviewIcon = memo(RatingReviewIcon);
export default MemoRatingReviewIcon;
