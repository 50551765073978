import React, { Fragment, useEffect, useState } from "react";
import { Button, Icon, Select, Table, TableRow } from "../../../ui";
import { FILTER } from "../../../constant";
import { useDeleteTagMutation, useGetTagMutation } from "./tagApi";
import {
  setCurrTab,
  useTagList,
  useCurrentTab,
  useVendorTagList,
} from "./tagSlice";
import TagModal from "./TagModal";
import { MdEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "../../common";
import {
  prepareVendorOptions,
  responseToaster,
} from "../../../helperFunctions";
import Tooltip from "../../../ui/elements/Tooltip";
import { useForm } from "react-hook-form";
import { IoEye } from "react-icons/io5";
import { useDispatch } from "react-redux";
import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../auth/authSlice";

interface TagTabs {
  label: string;
  Component?: any;
}

const initialValues = {
  startDate: [null, null],
};
const Tag = () => {
  const [getTag, { isLoading }] = useGetTagMutation();
  const tagList = useTagList();
  const tagVendorList = useVendorTagList();
  const [deleteTag, { isLoading: isDeleteLoading }] = useDeleteTagMutation();

  const currentTab = useCurrentTab();
  const [isTagModal, setIsTagModal] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();
  const [tagData, setTagData] = useState({});
  const [showDiv, setShowDiv] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useRole("tag");

  if (!role.includes("list")) {
    navigate("/");
  }

  const breadcrumbs = [
    { label: "Master" },
    { label: "Tag" },
    { label: currentTab },
  ];
  const handleAddTag = () => {
    setIsTagModal(true);
  };

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Name",
      name: "tag_name",
      sortable: true,
      sortableKey: "tag_name",
    },
    {
      title: "Vendor Name",
      name: "tag_name",
      sortable: true,
      sortableKey: "tag_name",
      Cell: (data: any) => {
        return <span>{data?.vendor?.name || "-"}</span>;
      },
    },
    {
      title: "Tag",
      name: "sub_tags",
      Cell: (data: any) => {
        return (
          <span>
            {data?.sub_tags
              ?.map((tag: any, index: any) => {
                return tag?.tag_name;
              })
              .join(", ")
              .toString()}
          </span>
        );
      },
    },
    {
      title: "Action",
      name: "action",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            {currentTab !== "Vendor" ? (
              <Tooltip text="Edit">
                <Button
                  className="!h-8 w-8 !p-0"
                  onClick={() => {
                    setIsTagModal(true);
                    setTagData(item);
                  }}
                  disabled={!role.includes("edit")}
                >
                  <MdEdit />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip text="Preview">
                <Button
                  className="!h-8 !w-8 !p-0"
                  onClick={() => {
                    setIsTagModal(true);
                    setTagData(item);
                  }}
                >
                  <IoEye />
                </Button>
              </Tooltip>
            )}
            <Tooltip text="Delete">
              <Button
                className="!h-8 w-8 !p-0"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeleteData(item);
                }}
                disabled={!role.includes("delete")}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const tagTab: TagTabs[] = [
    { label: "Admin", Component: Tag },
    { label: "Vendor", Component: Tag },
  ];

  const onTagDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteTag(payload).unwrap();
      if (res) {
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
      responseToaster(res);
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getTagList = async () => {
    try {
      const payload: any = {
        ...filter,
        filter_data: {
          vendor_id: filter?.vendor_id,
          is_admin: currentTab === "Admin" ? 1 : 0,
        },
      };

      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getTag(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const { register, handleSubmit, reset } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  useEffect(() => {
    if (role.includes("list")) {
      getTagList();
    }
    // eslint-disable-next-line
  }, [filter, currentTab]);

  useEffect(() => {
    if (currentTab === "Admin") {
      setTableData(tagList?.data?.length ? [...tagList?.data] : []);
    } else {
      setTableData(tagVendorList?.data?.length ? [...tagVendorList?.data] : []);
    }
  }, [tagList, tagVendorList, currentTab]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div>
        <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
          <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
          <div className="flex gap-2 justify-end">
            {role.includes("list") ? (
              <Button
                classNm={`!text-ev-white !border-0 appl-btn1`}
                onClick={() => {
                  setShowDiv(!showDiv);
                }}
                className={`${
                  !showDiv ? "!bg-chatlook-Cyan" : "!bg-[#22a99c]"
                }`}
              >
                <Icon name="FilterIcon" />
              </Button>
            ) : null}
            {currentTab !== "Vendor" && role.includes("create") ? (
              <div className="flex justify-end">
                <Button onClick={() => handleAddTag()}>Add Tag</Button>
              </div>
            ) : null}
          </div>
        </div>

        {showDiv && (
          <div className="cardcss mb-3 flex">
            <form
              className="flex gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Select
                name="vendor_id"
                label="Vendor"
                placeholder="Select Vendor"
                options={prepareVendorOptions(tagList?.vendorsList)}
                register={register}
              />

              <Button type="submit" className="!text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm"
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true, isClear: true });
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        )}

        {role.includes("list") ? (
          <div className="cardcss">
            <div className="flex mb-3 gap-4 justify-between border-b-2 p-1 ">
              <div className="flex gap-2 pb-1 sm:flex-wrap">
                {tagTab?.map((tab, index) => {
                  return (
                    <div
                      key={index}
                      className={` px-6 h-9 flex items-center justify-center cursor-pointer tab-transition ${
                        currentTab !== tab?.label ? " " : "active"
                      }`}
                      onClick={() => {
                        setFilter({
                          ...FILTER,
                        });
                        reset();
                        dispatch(setCurrTab(tab?.label));
                      }}
                    >
                      {tab?.label}
                    </div>
                  );
                })}
              </div>
            </div>

            <Table
              columns={columns}
              className="tagTable"
              isExpendable={false}
              isLoading={isLoading}
              data={tableData || []}
              setData={setTableData}
              count={
                currentTab === "Admin"
                  ? tagList?.total_item
                  : currentTab === "Vendor"
                  ? tagVendorList?.total_item
                  : 0
              }
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                      onClick={(item: any) => {}}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        ) : null}
      </div>
      <TagModal
        currentTab={currentTab}
        isOpen={isTagModal}
        onGet={getTagList}
        tagData={tagData}
        onHide={() => {
          setIsTagModal(false);
          setTagData({});
        }}
      />
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onTagDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default Tag;
