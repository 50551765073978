import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  ControlledDropDown,
  ControlledInput,
  Loader,
} from "../../../ui";
import ControlledSwitch from "../../../ui/forms/ControlledSwitch";
import ControlledTextArea from "../../../ui/forms/ControlledTextArea";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { responseToaster } from "../../../helperFunctions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetVendorByIdMutation, useUpdateVendorMutation } from "./vendorApi";
import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";

const paymentMethodOptions = [
  {
    value: "1",
    label: "Cash",
  },
  {
    value: "2",
    label: "Online",
  },
  {
    value: "0",
    label: "All",
  },
];

const deliveryTypeOptions = [
  {
    value: 0,
    label: "All",
  },
  {
    value: 1,
    label: "Delivery",
  },
  {
    value: 2,
    label: "Instore Pickup",
  },
];

const schema1: any = yup.object({
  name: yup.string().required("Please enter a name"),
  store_name: yup.string().required("Please add a store name"),
  email: yup
    .string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
    .required("Please enter a Email"),
  address: yup.string().required("Please add a Address"),
  upi_id: yup
    .string()
    .required("UPI ID is required")
    .matches(/^[\w.-]+@[\w.-]+$/, "Invalid UPI ID format"),
  delivery_radius: yup
    .string()
    .required("Please enter delivery radius")
    .matches(/^(?!0+$)\d+$/, "Delivery radius must be greater than 0"),
  pin_code: yup
    .string()
    .trim()
    .required("Please enter a pincode")
    .min(6, "Pincode must be 6 digits"),
  city: yup.string().required("Please select a city"),
  payment_method: yup.string().required("Please select a payment method"),
  order_service: yup.string().required("Please select a order service"),
});

const schema2: any = yup.object({
  name: yup.string().required("Please enter a name"),
  store_name: yup.string().required("Please add a store name"),
  email: yup
    .string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
    .required("Please enter a Email"),
  address: yup.string().required("Please add a Address"),
  pin_code: yup
    .string()
    .trim()
    .required("Please enter a pincode")
    .min(6, "Pincode must be 6 digits"),
  city: yup.string().required("Please select a city"),
  payment_method: yup.string().required("Please select a payment method"),
  order_service: yup.string().required("Please select a order service"),
});

const VendorForm = () => {
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);

  const { id } = useParams();
  const location = useLocation();
  const [updateVendor] = useUpdateVendorMutation();
  const [getVendorById, { isLoading }] = useGetVendorByIdMutation();
  const [vendorDt, setVendorDt] = useState<any>({});
  const [pMethod, setPMethod] = useState<any>(
    deliveryTypeOptions?.[0]?.value || ""
  );
  const isUpiRequired =
    pMethod?.toString() === "0" || pMethod?.toString() === "2";

  const breadcrumbs = [
    { label: "Vendor", link: "/vendor/vendor/get-vendor" },
    { label: "Update Vendor" },
  ];
  const hasTicket = new URLSearchParams(location.search).has("ticket");

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(isUpiRequired ? schema1 : schema2),
    defaultValues: {
      payment_method: "0",
    },
  });
  const onClose = () => {
    reset();
    navigate(hasTicket ? "/ticket/list-ticket" : "/vendor/vendor/get-vendor");
  };

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const payload = {
        ...values,
        status: values?.status ? "active" : "inactive",
      };
      const res: any = await updateVendor({
        id,
        ...payload,
      }).unwrap();
      if (res?.status) {
        onClose();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  const getVendorData = async () => {
    try {
      const payload: any = {
        id,
      };
      const res: any = await getVendorById(payload);
      if (res?.data?.status) {
        setFormData(res?.data?.data);
        setVendorDt(res?.data);
      }
    } catch (error) {}
  };

  const setFormData = (vendorData: any) => {
    if (vendorData?.id) {
      setValue("address", vendorData?.address);
      setValue("contact", vendorData?.contact);
      setValue("delivery_charge", vendorData?.delivery_charge);
      setValue("email", vendorData?.email);
      setValue("delivery_radius", vendorData?.delivery_radius);
      setValue("facebook_link", vendorData?.facebook_link);
      setValue("instagram_link", vendorData?.instagram_link);
      setValue("twitter_link", vendorData?.twitter_link);
      setValue("is_approved", vendorData?.is_approved);
      setValue("status", vendorData?.status === "active" ? 1 : 0);
      setValue("is_auto_approved", vendorData?.is_auto_approved);
      setValue("is_email_verify", vendorData?.is_email_verify);
      setValue("name", vendorData?.name);
      setValue("order_service", vendorData?.order_service);
      setValue("upi_id", vendorData?.upi_id);
      setValue("payment_method", vendorData?.payment_method);
      setValue("store_name", vendorData?.store_name);
      setValue("store_status", vendorData?.store_status);
      setValue("city", vendorData?.city?.id);
      setValue("shop_id", vendorData?.shop_id);
      setValue("pin_code", vendorData?.pin_code);
      setPMethod(
        vendorData?.order_service === 1
          ? "1"
          : vendorData?.order_service?.toString()
      );
    }
  };

  useEffect(() => {
    if (id) {
      getVendorData();
    }
    // eslint-disable-next-line
  }, [id]);

  const prepareShopcategoryOptions = () => {
    const categoriesOptions = vendorDt?.shopCategory
      // eslint-disable-next-line
      ?.slice()
      ?.sort((a: { title: string }, b: { title: any }) =>
        a?.title?.localeCompare?.(b?.title)
      )
      ?.map((val: { id: any; title: any }) => ({
        value: val?.id,
        label: val?.title,
      }));
    return categoriesOptions || [];
  };

  const prepareCitiesOptions = () => {
    const cities = vendorDt?.city ? [...vendorDt?.city] : [];
    const citiesOptions =
      cities
        // eslint-disable-next-line
        ?.sort?.((a: { name: string }, b: { name: any }) =>
          a?.name?.localeCompare?.(b?.name)
        )
        ?.map?.((val: { id: any; name: any }) => ({
          value: val?.id,
          label: val?.name,
        })) || [];
    return [...citiesOptions] || [];
  };

  if (isLoading) {
    return (
      <>
        <div className="flex items-center justify-center loader_height">
          <Loader />
        </div>
      </>
    );
  }
  return (
    <>
      <div className="flex items-center flex-wrap gap-3">
        <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
      </div>
      <div className="cardcss mb-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body">
            <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 xl:grid-cols-2 sm:grid-cols-1 items-start gap-3 pt-2 pb-3">
              <ControlledInput
                name="name"
                label="Name"
                placeholder="Enter Name"
                type="text"
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="contact"
                label="Contact"
                placeholder="Enter Contact"
                type="text"
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="delivery_charge"
                label="Delivery Charge"
                placeholder="Enter Delivery Charge"
                onChange={(e) => {
                  if (
                    e?.target?.value === "" ||
                    /^\d+$/.test(e?.target?.value)
                  ) {
                    setValue("delivery_charge", e?.target?.value);
                  }
                }}
                type="text"
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="email"
                label="Email"
                placeholder="Enter Email"
                type="text"
                errors={errors}
                control={control}
              />

              <ControlledInput
                name="store_name"
                label="Store Name"
                placeholder="Enter Store Name"
                type="text"
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="delivery_radius"
                label="Delivery Radius"
                placeholder="Enter Delivery Radius"
                onChange={(e) => {
                  if (
                    e?.target?.value === "" ||
                    /^\d+$/.test(e?.target?.value)
                  ) {
                    setValue("delivery_radius", e?.target?.value);
                    trigger("delivery_radius");
                  }
                }}
                type="text"
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="upi_id"
                label="UPI Id"
                placeholder="Enter UPI Id"
                type="text"
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="shop_id"
                label="Shop Category"
                placeholder="Select Shop Category"
                options={prepareShopcategoryOptions()}
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="order_service"
                label="Order Service"
                placeholder="Select Order Service"
                options={deliveryTypeOptions}
                onChange={(e: any) => {
                  if (e?.toString() === "1") {
                    setPMethod("1");
                  } else {
                    setPMethod(e?.toString());
                  }
                  setValue("order_service", e);
                }}
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="city"
                label="City"
                placeholder="Select City"
                options={prepareCitiesOptions()}
                errors={errors}
                control={control}
              />
              <ControlledInput
                type="text"
                name="pin_code"
                label="Pincode"
                placeholder="Enter pincode"
                onChange={(e) => {
                  if (
                    e?.target?.value?.length <= 6 &&
                    (e?.target?.value === "" || /^\d+$/.test(e?.target?.value))
                  ) {
                    setValue("pin_code", e?.target?.value);
                    trigger("pin_code");
                  }
                }}
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="payment_method"
                label="Payment Method"
                placeholder="Select Payment Method"
                options={paymentMethodOptions}
                control={control}
                errors={errors}
              />
              <ControlledInput
                name="facebook_link"
                label="Facebook Link"
                placeholder="Enter Facebook Link"
                type="text"
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="instagram_link"
                label="Instagram Link"
                placeholder="Enter Instagram Link"
                type="text"
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="twitter_link"
                label="Twitter Link"
                placeholder="Enter Twitter Link"
                type="text"
                errors={errors}
                control={control}
              />
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 xl:grid-cols-2 sm:grid-cols-1 items-start gap-3 pb-3">
              <div className="flex items-end justify-between flex-wrap gap-3 col-span-2  !md:mt-0 !sm:mt-0 2xl:mt-7 xl:mt-0 lg:mt-0">
                <ControlledSwitch
                  name="status"
                  label="Status"
                  control={control}
                />
                <ControlledSwitch
                  name="store_status"
                  label="Store Status"
                  control={control}
                />
                <ControlledSwitch
                  name="is_approved"
                  label="Is Approved"
                  control={control}
                />
                <ControlledSwitch
                  name="is_auto_approved"
                  label="Is Auto Approved"
                  control={control}
                />
                <ControlledSwitch
                  name="is_email_verify"
                  label="Is Email Verify"
                  control={control}
                />
              </div>
              <ControlledTextArea
                name="address"
                label="Address"
                placeholder="Enter Address"
                control={control}
                errors={errors}
                className="textareacss col-span-2"
              />
            </div>
          </div>
          <div className="modal-footer pb-5">
            <div className="flex items-center justify-end gap-2">
              <div className="flex gap-2">
                <Button
                  type="submit"
                  className="!h-9 !text-sm"
                  isLoading={addCpisLoading}
                  isLoader={addCpisLoading}
                >
                  Update
                </Button>
                <Button
                  variant="Transaparent"
                  className=" !text-sm !h-9"
                  disabled={addCpisLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default VendorForm;
