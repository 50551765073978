export const typeOptionsForOrder = [
  {
    value: "add",
    label: "Add",
  },
  {
    value: "update",
    label: "Update",
  },
  {
    value: "delete",
    label: "Delete",
  },
];
