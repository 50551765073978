// UI IMPORT
import * as iconComponents from "./icons";
import { IconKeys, IconProps } from ".";

const Icon = (props: IconProps & { name: IconKeys }) => {
  const { className = "", name, ...rest } = props;
  const IconStroy = iconComponents?.[name];
  if (!IconStroy) {
    return <></>;
  }
  return <IconStroy className={`${className} cursor-pointer`} {...rest} />;
};

export default Icon;
