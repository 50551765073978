import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { userApi } from "./userApi";

interface UserState {
  userList: any;
  userVendor: any;
  userListData: any;
  userData: any;
  productUser: any;
}

const initialState: UserState = {
  userList: {},
  userVendor: {},
  userListData: {},
  userData: {},
  productUser: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser: () => initialState,
    setUser: (state, { payload }) => {
      state.userList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getUser.matchFulfilled,
      (state, action: any) => {
        state.userList = action?.payload;
      }
    );
    builder.addMatcher(
      userApi.endpoints.addUser.matchFulfilled,
      (state, { payload }) => {
        state.userData = payload || {};
      }
    );
    builder.addMatcher(
      userApi.endpoints.getUserList.matchFulfilled,
      (state, { payload }) => {
        state.userListData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default userSlice.reducer;
export const { clearUser, setUser } = userSlice.actions;

export const selectUserList = (state: RootState) => state.user.userList;
export const useUserList = () => {
  const userList = useSelector(selectUserList);
  return useMemo(() => userList, [userList]);
};
export const selectVendorUserList = (state: RootState) =>
  state.user.userVendor;
export const useVendorUserList = () => {
  const userVendorList = useSelector(selectVendorUserList);
  return useMemo(() => userVendorList, [userVendorList]);
};

export const selectUserListData = (state: RootState) =>
  state.user.userListData;
export const useUserListData = () => {
  const userListData = useSelector(selectUserListData);
  return useMemo(() => userListData, [userListData]);
};

export const selectUserData = (state: RootState) => state.user.userData;
export const useUserData = () => {
  const userData = useSelector(selectUserData);
  return useMemo(() => userData, [userData]);
};
