// UI IMPORT
import * as imageComponents from "./images";
import { ImageProps } from ".";

const Image = (props: ImageProps) => {
  const { className = "", size, name, alt, ...rest } = props;
  const ImageStroy = imageComponents?.[name];

  if (!ImageStroy) {
    return <></>;
  }

  return (
    <img
      src={imageComponents?.[name]}
      className={`${className} cursor-pointer`}
      width={size}
      height={size}
      alt={alt ? alt : name}
      {...rest}
    />
  );
};

export default Image;
