import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { couponApi } from "./couponApi";

interface UserState {
  couponList: any;
  couponVendor: any;
  couponUserList: any;
  couponData: any;
  currTab: any;
  productCoupon: any;
}

const initialState: UserState = {
  couponList: {},
  couponVendor: {},
  couponUserList: {},
  couponData: {},
  productCoupon: {},
  currTab: "Admin",
};

export const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    clearCoupon: () => initialState,
    setCoupon: (state, { payload }) => {
      state.couponList = payload;
    },
    setCurrTab: (state, { payload }) => {
      state.currTab = payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      couponApi.endpoints.getCoupon.matchFulfilled,
      (state, action: any) => {
        if (action?.meta?.arg?.originalArgs?.vendor_id === 0) {
          state.couponList = action?.payload;
        } else {
          state.couponVendor = action?.payload;
        }
      }
    );
    builder.addMatcher(
      couponApi.endpoints.addCoupon.matchFulfilled,
      (state, { payload }) => {
        state.couponData = payload || {};
      }
    );
    builder.addMatcher(
      couponApi.endpoints.getProductsForCoupon.matchFulfilled,
      (state, { payload }) => {
        state.productCoupon = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default couponSlice.reducer;
export const { clearCoupon, setCoupon, setCurrTab } = couponSlice.actions;

export const selectCouponList = (state: RootState) => state.coupon.couponList;
export const useCouponList = () => {
  const couponList = useSelector(selectCouponList);
  return useMemo(() => couponList, [couponList]);
};
export const selectVendorCouponList = (state: RootState) =>
  state.coupon.couponVendor;
export const useVendorCouponList = () => {
  const couponVendorList = useSelector(selectVendorCouponList);
  return useMemo(() => couponVendorList, [couponVendorList]);
};

export const selectCouponUserList = (state: RootState) =>
  state.coupon.couponUserList;
export const useCouponUserList = () => {
  const couponUserList = useSelector(selectCouponUserList);
  return useMemo(() => couponUserList, [couponUserList]);
};

export const selectCouponData = (state: RootState) => state.coupon.couponData;
export const useCouponData = () => {
  const couponData = useSelector(selectCouponData);
  return useMemo(() => couponData, [couponData]);
};

export const selectCurrentTab = (state: RootState) => state.coupon.currTab;
export const useCurrentTab = () => {
  const currentTab = useSelector(selectCurrentTab || "Admin");
  return useMemo(() => currentTab, [currentTab]);
};

export const selectProductCoupon = (state: RootState) => state.coupon.productCoupon;
export const useProductCoupon = () => {
  const productCoupon = useSelector(selectProductCoupon);
  return useMemo(() => productCoupon, [productCoupon]);
};
