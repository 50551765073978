import React, { useEffect, useState } from "react";

const StatusStyle = ({ status, className, text }: any) => {
  const [statusStyle, setStatusStyle] = useState<any>();

  useEffect(() => {
    switch (status) {
      case "New":
      case "Priority":
      case "0":
        setStatusStyle({ backgroundColor: "#ffdcdc", color: "#900" });
        break;
      case "Delivered":
      case "succeeded":
      case "Solved":
        setStatusStyle({ backgroundColor: "#def2d0", color: "#245900" });
        break;
      case "Pending":
      case "Cancel":
        setStatusStyle({ backgroundColor: "#e2e3e5", color: "#444a4f" });
        break;
      case "Processing":
        setStatusStyle({ backgroundColor: "#e5fff5", color: "#17da8b" });
        break;
      case "Out of delivery":
        setStatusStyle({ backgroundColor: "#f9f1c8", color: "#5e4f00" });
        break;
      case "Return":
        setStatusStyle({ backgroundColor: "#ffe4a9", color: "#da9011" });
        break;
      case "Assign to delivery":
      case "processing":
        setStatusStyle({ backgroundColor: "#ddf1f7", color: "#59b3d0" });
        break;
      case "Running":
        setStatusStyle({ backgroundColor: "#d9ecff", color: "#004b9a" });
        break;
      case "1":
        setStatusStyle({ backgroundColor: "#def2d0", color: "#59b3d0" });
        break;
      default:
        setStatusStyle({ backgroundColor: "#e2e3e5", color: "#444a4f" });
    }
  }, [status]);

  return (
    <div
      className={`${className} inline-block px-2 py-1 text-xs font-medium text-center whitespace-nowrap align-baseline rounded-md`}
      style={statusStyle}
    >
      {text || status}
    </div>
  );
};

export default StatusStyle;
