import { Fragment, useEffect, useState } from "react";
import { FILTER, statusOptions, statusOptions1 } from "../../../constant";
import { Button, Icon, Input, Select, Table, TableRow } from "../../../ui";
import { setVendors, useVendorList } from "./vendorSlice";
import {
  useDeleteVendorMutation,
  useGetVendorMutation,
  useUpdateVendorMutation,
} from "./vendorApi";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import Switch from "../../../ui/forms/Switch";
import { useForm } from "react-hook-form";

import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import Tooltip from "../../../ui/elements/Tooltip";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import VendorModal from "./VendorModal";
import { Modal } from "../../common";
import { useRole } from "../../auth/authSlice";
import { useLocation, useNavigate } from "react-router-dom";

const breadcrumbs = [{ label: "Vendor" }, { label: "Vendor List" }];

const VendorList = () => {
  const [getVendor, { isLoading }] = useGetVendorMutation();
  const vendorList = useVendorList();
  const [updateVendor] = useUpdateVendorMutation();

  const [tableData, setTableData] = useState<any>([]);
  const [isVendorModal, setIsVendorModal] = useState<any>(false);
  const [vendorData, setVendorData] = useState<any>({});
  const [filter, setFilter] = useState<any>(FILTER);
  const [statusLoading, setStatusLoading] = useState(false);
  const [currKey, setCurrKey] = useState("");
  const [showDiv, setShowDiv] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteVendor, { isLoading: isDeleteLoading }] =
    useDeleteVendorMutation();
  const [deleteData, setDeleteData] = useState<any>();
  const navigate = useNavigate();
  const route = useLocation()?.pathname;

  const role = useRole("vendor");

  if (!role.includes("list")) {
    navigate("/");
  }

  const dispatch = useDispatch();

  const onUpdateStatus = async (payload: any, key?: any) => {
    try {
      setStatusLoading(payload?.id);
      setCurrKey(key);
      const res = await updateVendor(payload).unwrap();
      const index = tableData?.findIndex(
        (item: { id: any }) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...tableData];
        newData[index] = {
          ...newData[index],
          [key]: payload?.[key],
        };
        dispatch(setVendors({ ...vendorList, data: newData }));
      }
      responseToaster(res);
      setStatusLoading(false);
      setCurrKey("");
    } catch (err) {
      setStatusLoading(false);
      setCurrKey("");
    }
  };

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Name",
      name: "name",
      sortable: true,
      sortableKey: "name",
    },
    {
      title: "Email",
      name: "email",
      sortable: true,
      sortableKey: "email",
    },
    {
      title: "Status",
      name: "status",
      Cell: (data: any) => (
        <Switch
          checked={data?.status === "active"}
          isLoading={statusLoading === data?.id && currKey === "status"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                status: value ? "active" : "inactive",
                id: data?.id,
              };
              await onUpdateStatus(payload, "status");
            } catch (err) {}
          }}
        />
      ),
      sortable: true,
      sortableKey: "status",
    },
    {
      title: "Approved",
      name: "is_approved",
      Cell: (data: any) => (
        <Switch
          checked={data?.is_approved}
          isLoading={statusLoading === data?.id && currKey === "is_approved"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                is_approved: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "is_approved");
            } catch (err) {}
          }}
        />
      ),
      sortable: true,
      sortableKey: "is_approved",
    },
    {
      title: "Auto Approved",
      name: "is_auto_approved",
      Cell: (data: any) => (
        <Switch
          checked={data?.is_auto_approved}
          isLoading={
            statusLoading === data?.id && currKey === "is_auto_approved"
          }
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                is_auto_approved: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "is_auto_approved");
            } catch (err) {}
          }}
        />
      ),
      sortable: true,
      sortableKey: "is_auto_approved",
    },
    {
      title: "Store Name",
      name: "store_name",
      sortable: true,
      sortableKey: "store_name",
    },
    {
      title: "Pincode",
      name: "pin_code",
      sortable: true,
      sortableKey: "pin_code",
    },
    {
      title: "City",
      name: "city",
      sortable: true,
      sortableKey: "city.name",
      Cell: (data: any) => {
        return <span>{data?.city?.name || "-"}</span>;
      },
    },
    {
      title: "Action",
      name: "action",
      Cell: (data: any) => (
        <>
          <div className="flex gap-1">
            <Tooltip text="Edit">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={(e: { stopPropagation: () => void }) => {
                  e.stopPropagation();
                  navigate(`${route}/${data?.id}`);
                  // setIsVendorModal(true);
                  // setVendorData(data);
                }}
                disabled={!role.includes("edit")}
              >
                <MdEdit />
              </Button>
            </Tooltip>
            <Tooltip text="Delete" className="right-0">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeleteData(data);
                }}
                disabled={!role.includes("delete")}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const onVendorDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteVendor(payload).unwrap();
      if (res) {
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
      responseToaster(res);
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getVendorList = async () => {
    try {
      const { search, status, is_auto_approved, is_approved, ...rest } = filter;
      const payload: any = {
        ...rest,
        filter_data: {
          search,
          status,
          is_approved,
          is_auto_approved,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getVendor(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const { register, handleSubmit, reset } = useForm<any>();

  useEffect(() => {
    setTableData(vendorList?.data?.length ? [...vendorList?.data] : []);
  }, [vendorList]);

  useEffect(() => {
    if (role.includes("list")) {
      getVendorList();
    }
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
        <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
        {role.includes("list") ? (
          <Button
            classNm={`!text-ev-white !border-0 appl-btn1`}
            onClick={() => {
              setShowDiv(!showDiv);
            }}
            className={`${!showDiv ? "!bg-chatlook-Cyan" : "!bg-[#22a99c]"}`}
          >
            <Icon name="FilterIcon" />
          </Button>
        ) : null}
      </div>
      {showDiv ? (
        <div className="cardcss mb-3">
          <form
            className="flex gap-2 items-end sm:flex-wrap"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              name="search"
              label="Search"
              placeholder="Search by name or email"
              register={register}
            />

            <Select
              name="status"
              label="Status"
              placeholder="All"
              options={statusOptions}
              register={register}
            />

            <Select
              name="is_approved"
              label="Approved"
              placeholder="All"
              options={statusOptions1}
              register={register}
            />

            <Select
              name="is_auto_approved"
              label="Auto Approved"
              placeholder="All"
              options={statusOptions1}
              register={register}
            />

            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isFilter: true, isClear: true });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      {role.includes("list") ? (
        <div className="cardcss">
          <Table
            columns={columns}
            isExpendable={false}
            isLoading={isLoading}
            data={tableData || []}
            setData={setTableData}
            count={vendorList?.total_item || 0}
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isFilter: false,
              });
            }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    isExpendable={false}
                    item={item}
                    onClick={(item: any) => {}}
                  ></TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
      <VendorModal
        isOpen={isVendorModal}
        onHide={() => {
          setIsVendorModal(false);
          setVendorData({});
        }}
        vendorData={vendorData}
        onGet={getVendorList}
      />
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onVendorDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default VendorList;
