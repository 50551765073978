import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, ControlledInput } from "../../ui";
import {
  useUpdateSettlementMutation,
  useVendorSettlementMutation,
} from "./settlementApi";
import { responseToaster, seperator } from "../../helperFunctions";
import { useCurrentSettlement, useVendorSettlement } from "./settlementSlice";
import ControlledTextArea from "../../ui/forms/ControlledTextArea";

const SettlementModal = (props: any) => {
  const { onHide, isOpen, onGet, settlementData } = props;
  const currentSettlement = useCurrentSettlement();
  const [vendorSettlement] = useVendorSettlementMutation();
  const vendorSettlementData = useVendorSettlement();
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);
  const [updateSettlement] = useUpdateSettlementMutation();

  const schema: any = yup.object({
    settlement_amount: yup
      .string()
      .required("Please enter a settlement amount"),
  });

  const prepareDefaultValues = () => {
    if (settlementData?.id) {
      return {
        title: settlementData?.title,
        description: settlementData?.description,
        status: settlementData?.status,
      };
    } else {
      return {
        status: 1,
      };
    }
  };

  const getVendorSettlement = async () => {
    try {
      const payload: any = {
        vendor_id: currentSettlement?.vendorId,
      };
      await vendorSettlement(payload);
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    if (isOpen && currentSettlement?.vendorId) {
      getVendorSettlement();
    }
    // eslint-disable-next-line
  }, [currentSettlement, currentSettlement?.vendorId, isOpen]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...prepareDefaultValues(),
    },
  });

  const onClose = () => {
    reset();
    onHide();
  };

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const payload = {
        vendor_id: currentSettlement?.vendorId,
        ...values,
      };
      const res: any = await updateSettlement(payload).unwrap();
      if (res?.status) {
        onClose();
        onGet?.();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addCpisLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-6/12 xl:w-6/12 lg:w-8/12  md:w-9/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-9 px-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                Update Settlement
              </h4>
            </div>
            <div className="modal-body">
              <table className="settlementTbl">
                <thead>
                  <tr>
                    <th>Shipping Charge</th>
                    <th>Unsettle Balance</th>
                    <th>Available Balance</th>
                    <th>Total COD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {seperator(vendorSettlementData?.data?.shipping_charge)}
                    </td>
                    <td>
                      {seperator(vendorSettlementData?.data?.unsettle_balance)}
                    </td>
                    <td>
                      {seperator(vendorSettlementData?.data?.available_balance)}
                    </td>
                    <td>{seperator(vendorSettlementData?.data?.total_cod)}</td>
                  </tr>
                </tbody>
              </table>

              <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 items-start gap-3 pt-2 pb-3">
                <ControlledInput
                  name="settlement_amount"
                  label="Settlement Amount"
                  placeholder="Enter Settlement Amount"
                  type="number"
                  autoFocus
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="ref_id"
                  label="Ref Id"
                  placeholder="Enter Ref Id"
                  type="number"
                  errors={errors}
                  control={control}
                />
                <ControlledTextArea
                  name="description"
                  className="md:col-span-2 lg:col-span-2 2xl:col-span-2 xl:col-span-2 col-span-1 textareacss"
                  label="Description"
                  placeholder="Enter Description"
                  control={control}
                  errors={errors}
                />
              </div>
            </div>
            <div className="modal-footer pb-1">
              <div className="flex items-center justify-end gap-2">
                <Button
                  type="submit"
                  className="mt-[10px] !h-9 !text-sm"
                  isLoading={addCpisLoading}
                  isLoader={addCpisLoading}
                >
                  Update
                </Button>
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={addCpisLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SettlementModal;
