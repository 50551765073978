import { api } from "../../utills/api";

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/sub/get-user",
        body: params,
        method: "POST",
      }),
    }),
    updateUser: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/sub/update-user",
        body: params,
        method: "POST",
      }),
    }),
    addUser: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/sub/add-user",
        body: params,
        method: "POST",
      }),
    }),
    deleteUser: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/sub/delete-user",
        body: params,
        method: "POST",
      }),
    }),
    getUserById: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/user/get-user-by-id",
        body: params,
        method: "POST",
      }),
    }),
    getUserList: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/user/user-list",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetUserMutation,
  useUpdateUserMutation,
  useAddUserMutation,
  useDeleteUserMutation,
  useGetUserByIdMutation,
  useGetUserListMutation,
} = userApi;
export const {
  endpoints: { getUser, addUser, updateUser, deleteUser, getUserById, getUserList },
} = userApi;
