import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { deliveredByApi } from "./deliveredByApi";

interface UserState {
  deliveredByList: any;
  currTab: any;
  deliveredPersonData: any;
  deliveredByPendingList: any;
}

const initialState: UserState = {
  deliveredByList: {},
  currTab: "Pending",
  deliveredPersonData: {},
  deliveredByPendingList: {},
};

export const deliveredBySlice = createSlice({
  name: "deliveredBy",
  initialState,
  reducers: {
    clearDeliveredBy: () => initialState,
    setDeliveredBy: (state, { payload }) => {
      state.deliveredByList = payload;
    },
    setCurrTab: (state, { payload }) => {
      state.currTab = payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      deliveredByApi.endpoints.getDeliveredBy.matchFulfilled,
      (state, action: any) => {
        if (
          action?.meta?.arg?.originalArgs?.filter_data?.delivery_persons_id ===
          0
        ) {
          state.deliveredByPendingList = action?.payload;
        } else {
          state.deliveredByList = action?.payload;
        }
      }
    );
    builder.addMatcher(
      deliveredByApi.endpoints.getDeliveryPersonList.matchFulfilled,
      (state, { payload }) => {
        state.deliveredPersonData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default deliveredBySlice.reducer;
export const { clearDeliveredBy, setDeliveredBy, setCurrTab } =
  deliveredBySlice.actions;

export const selectDeliveredByList = (state: RootState) =>
  state.deliveredBy.deliveredByList;
export const useDeliveredByList = () => {
  const deliveredByList = useSelector(selectDeliveredByList);
  return useMemo(() => deliveredByList, [deliveredByList]);
};
export const selectDeliveredByPendingList = (state: RootState) =>
  state.deliveredBy.deliveredByPendingList;
export const useDeliveredByPendingList = () => {
  const deliveredByPendingList = useSelector(selectDeliveredByPendingList);
  return useMemo(() => deliveredByPendingList, [deliveredByPendingList]);
};

export const selectCurrentTab = (state: RootState) => state.deliveredBy.currTab;

export const useCurrentTab = () => {
  const currentTab = useSelector(selectCurrentTab || "Pending");
  return useMemo(() => currentTab, [currentTab]);
};

export const selectDeliveredPersonData = (state: RootState) =>
  state.deliveredBy.deliveredPersonData;
export const useDeliveredPersonData = () => {
  const deliveredPersonData = useSelector(selectDeliveredPersonData);
  return useMemo(() => deliveredPersonData, [deliveredPersonData]);
};
