import React from "react";

interface CheckboxProps {
  className?:string;
  id?: string;
  name?: string;
  value?: string;
  label?: string;
  checked?: boolean |  undefined;
  onChange?: (check?: any) => void;
  disabled?:boolean
}
const CheckBox = (props: CheckboxProps) => {
  const { className = '',id, name, value, label, checked,disabled, onChange } = props;
  return (
    <div className="flex gap-1.5 items-center">
      <input
        className={`${className} checkbox h-4 w-4 `}
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked} 
        disabled={disabled}
        onChange={(e) => onChange?.(!!e?.target?.checked)}
      />
      {label ? (
        <label className="text-ev-Tertiary text-sm">{label}</label>
      ) : null}
    </div>
  );
};

export default CheckBox;
