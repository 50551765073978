import { api } from "../../utills/api";

export const variantApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVariant: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/variant/get-variant-list",
        body: params,
        method: "POST",
      }),
    }),
    updateVariant: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/variant/update-variant",
        body: params,
        method: "POST",
      }),
    }),
    addVariant: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/variant/add-variant",
        body: params,
        method: "POST",
      }),
    }),
    deleteVariant: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/variant/delete-variant",
        body: params,
        method: "POST",
      }),
    }),
    getVariantById: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/variant/get-variant-by-id",
        body: params,
        method: "POST",
      }),
    }),
    getVariantOption: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/variant/get-variant-option",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetVariantMutation,
  useUpdateVariantMutation,
  useAddVariantMutation,
  useDeleteVariantMutation,
  useGetVariantByIdMutation,
  useGetVariantOptionMutation,
} = variantApi;
export const {
  endpoints: {
    getVariant,
    addVariant,
    updateVariant,
    deleteVariant,
    getVariantById,
    getVariantOption,
  },
} = variantApi;
