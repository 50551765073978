import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { promotionApi } from "./promotionApi";

interface UserState {
  promotionList: any;
  promotionVendor: any;
  promotionUserList: any;
  promotionData: any;
  currTab: any;
}

const initialState: UserState = {
  promotionList: {},
  promotionVendor: {},
  promotionUserList: {},
  promotionData: {},
  currTab: "Admin",
};

export const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    clearPromotion: () => initialState,
    setPromotion: (state, action) => {
      if (action?.payload?.currentTab === "Admin") {
        state.promotionList = action?.payload;
      } else {
        state.promotionVendor = action?.payload;
      }
    },
    setCurrTab: (state, { payload }) => {
      state.currTab = payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      promotionApi.endpoints.getPromotion.matchFulfilled,
      (state, action: any) => {
        if (action?.meta?.arg?.originalArgs?.filter_data?.vendor_id === 0) {
          state.promotionList = action?.payload;
        } else {
          state.promotionVendor = action?.payload;
        }
      }
    );
    builder.addMatcher(
      promotionApi.endpoints.addPromotion.matchFulfilled,
      (state, { payload }) => {
        state.promotionData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default promotionSlice.reducer;
export const { clearPromotion, setPromotion, setCurrTab } =
  promotionSlice.actions;

export const selectPromotionList = (state: RootState) =>
  state.promotion.promotionList;
export const usePromotionList = () => {
  const promotionList = useSelector(selectPromotionList);
  return useMemo(() => promotionList, [promotionList]);
};

export const selectVendorPromotionList = (state: RootState) =>
  state.promotion.promotionVendor;
export const useVendorpromotionList = () => {
  const promotionVendorList = useSelector(selectVendorPromotionList);
  return useMemo(() => promotionVendorList, [promotionVendorList]);
};

export const selectPromotionUserList = (state: RootState) =>
  state.promotion.promotionUserList;
export const usePromotionUserList = () => {
  const promotionUserList = useSelector(selectPromotionUserList);
  return useMemo(() => promotionUserList, [promotionUserList]);
};

export const selectPromotionData = (state: RootState) =>
  state.promotion.promotionData;
export const usePromotionData = () => {
  const promotionData = useSelector(selectPromotionData);
  return useMemo(() => promotionData, [promotionData]);
};

export const selectCurrentTab = (state: RootState) => state.promotion.currTab;
export const useCurrentTab = () => {
  const currentTab = useSelector(selectCurrentTab);
  return useMemo(() => currentTab, [currentTab]);
};
