// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import RichTextEditor from "./RichTextEditor";

interface ControlledRichTextBoxProps {
  control: any;
  name: string;
  [key: string]: any;
}

const ControlledRichTextBox = (props: ControlledRichTextBoxProps) => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, value, onChange, onBlur, ...field } }) => {
        return (
          <RichTextEditor
            ref={ref}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            {...field}
            {...rest}
          />
        );
      }}
    />
  );
};

export default ControlledRichTextBox;
