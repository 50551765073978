import { api } from "../../utills/api";

export const promotionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPromotion: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/promotion/list-promotion",
        body: params,
        method: "POST",
      }),
    }),
    updatePromotion: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/promotion/update-promotion",
        body: params,
        method: "POST",
      }),
    }),
    addPromotion: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/promotion/add-promotion",
        body: params,
        method: "POST",
      }),
    }),
    deletePromotion: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/promotion/delete-promotion",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetPromotionMutation,
  useUpdatePromotionMutation,
  useAddPromotionMutation,
  useDeletePromotionMutation,
} = promotionApi;
export const {
  endpoints: { getPromotion, addPromotion, updatePromotion, deletePromotion },
} = promotionApi;
