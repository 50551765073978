import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { videoApi } from "./videoApi";

interface UserState {
  videoList: any;
  videoVendor: any;
  videoListData: any;
  videoData: any;
  productVideo: any;
}

const initialState: UserState = {
  videoList: {},
  videoVendor: {},
  videoListData: {},
  videoData: {},
  productVideo: {},
};

export const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    clearVideo: () => initialState,
    setVideo: (state, { payload }) => {
      state.videoList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      videoApi.endpoints.getVideo.matchFulfilled,
      (state, action: any) => {
        state.videoList = action?.payload;
      }
    );
    builder.addMatcher(
      videoApi.endpoints.addVideo.matchFulfilled,
      (state, { payload }) => {
        state.videoData = payload || {};
      }
    );
    builder.addMatcher(
      videoApi.endpoints.getVideoList.matchFulfilled,
      (state, { payload }) => {
        state.videoListData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default videoSlice.reducer;
export const { clearVideo, setVideo } = videoSlice.actions;

export const selectVideoList = (state: RootState) => state.video.videoList;
export const useVideoList = () => {
  const videoList = useSelector(selectVideoList);
  return useMemo(() => videoList, [videoList]);
};
export const selectVendorVideoList = (state: RootState) =>
  state.video.videoVendor;
export const useVendorVideoList = () => {
  const videoVendorList = useSelector(selectVendorVideoList);
  return useMemo(() => videoVendorList, [videoVendorList]);
};

export const selectVideoListData = (state: RootState) =>
  state.video.videoListData;
export const useVideoListData = () => {
  const videoListData = useSelector(selectVideoListData);
  return useMemo(() => videoListData, [videoListData]);
};

export const selectVideoData = (state: RootState) => state.video.videoData;
export const useVideoData = () => {
  const videoData = useSelector(selectVideoData);
  return useMemo(() => videoData, [videoData]);
};
