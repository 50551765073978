import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { dashboardApi } from "./dashboardApi";

interface UserState {
  dashboardList: any;
}

const initialState: UserState = {
  dashboardList: {},
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearDashboard: () => initialState,
    setDashboards: (state, { payload }) => {
      state.dashboardList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dashboardApi.endpoints.getDashboard.matchFulfilled,
      (state, { payload }) => {
        state.dashboardList = payload || {};
      }
    );
  },
});

export default dashboardSlice.reducer;
export const { clearDashboard, setDashboards } = dashboardSlice.actions;

export const selectDashboardList = (state: RootState) =>
  state.dashboard.dashboardList;
export const useDashboardList = () => {
  const dashboardList = useSelector(selectDashboardList);
  return useMemo(() => dashboardList, [dashboardList]);
};
