import { CSSProperties, PropsWithChildren, useState, Fragment } from "react";
import { Icon } from "../../ui";

interface CategoryTblRowProps extends PropsWithChildren<{}> {
  className?: string;
  style?: CSSProperties;
  isExpandable?: boolean;
  item?: any;
  columns: any[];
  onEyeClick?: (item?: any) => void;
  onClick?: (e?: any) => void;
  level?: number;
  lastLevelId?: number;
}
interface TableCellProps extends PropsWithChildren<{}> {
  className?: string;
  style?: CSSProperties;
  colSpan?: number;
  onClick?: (e?: any) => void;
  type?: string;
}
const CategoryTblRow = (props: CategoryTblRowProps) => {
  const {
    className = "",
    item = {},
    isExpandable = true,
    columns = [],
    children,
    level,
    lastLevelId,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const TableCell = (props: TableCellProps) => {
    const { className = "", style = {}, type, children, ...rest } = props;

    const isEllipsis = type === "ellipsis";
    return (
      <td
        className={`${className} open-padding table-cell tbl-td`}
        style={
          isEllipsis
            ? {
                ...style,
                whiteSpace: "normal",
                maxWidth: 330,
                minWidth: 330,
              }
            : {
                ...style,
                whiteSpace: "nowrap",
              }
        }
        {...rest}
      >
        {children}
      </td>
    );
  };
  return (
    <>
      <tr
        className={`${className} ${
          level === 1 ||
          (level === 2 && !isOpen) ||
          (lastLevelId === item?.id && level === 3)
            ? "pre-hori-line-full"
            : level === 2
            ? "pre-hori-line"
            : level === 3
            ? "pre-hori-line"
            : ""
        } ${
          level === 1 && isOpen ? "pre-verticle-line" : ""
        }  table-row tablecell-hoverx tbl-tr`}
      >
        <TableCell className={`${!item?.children ? "before-tblRow" : ""} `}>
          {isExpandable && level === 1 ? (
            <div
              className={`cursor-pointer flex justify-end ${
                isOpen ? "pre-verticle-1-1" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                if (isExpandable) {
                  setIsOpen(!isOpen);
                }
              }}
            >
              <div
              // className={`${
              //   level === 1 && isOpen ? "pre-verticle-line" : ""
              // }`}
              >
                {isOpen ? <Icon name="UpArrow" /> : <Icon name="DownArrow" />}
              </div>
            </div>
          ) : level === 2 || level === 3 ? (
            <div className={`pre-verticle-1`}></div>
          ) : null}
        </TableCell>
        {columns?.map((column, index) => {
          const { Cell, name, type } = column;
          return (
            <Fragment key={index}>
              <Fragment key={index}>
                {name === "title" ? (
                  <TableCell type={type}>
                    <div
                      className={`flex items-center gap-2 cursor-pointer ${
                        level === 2
                          ? "hori-line-2"
                          : level === 3
                          ? "hori-line-3"
                          : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isExpandable) {
                          setIsOpen(!isOpen);
                        }
                      }}
                    >
                      {level === 2 || level === 3 ? (
                        <div
                          className={`${
                            level === 2 && isOpen
                              ? "pre-verticle-line-2 z-50"
                              : level === 3 && isOpen
                              ? "pre-verticle-line-3"
                              : ""
                          }`}
                        >
                          {!!item?.children ? (
                            isOpen ? (
                              <Icon name="UpArrow" />
                            ) : (
                              <Icon name="DownArrow" />
                            )
                          ) : null}
                        </div>
                      ) : null}
                      {level === 3 ? (
                        <div className={`pre-verticle-2-3`}></div>
                      ) : null}
                      <span
                        className={`${
                          level === 3
                            ? "ml-10"
                            : level === 2 && !item?.children
                            ? "ml-4 pre-verticle-line"
                            : ""
                        } w-full break-words`}
                      >
                        {item?.[name]}
                      </span>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell type={type}>
                    {Cell ? <Cell {...item} /> : item?.[name] || "-"}
                  </TableCell>
                )}
              </Fragment>
            </Fragment>
          );
        })}
      </tr>
      {isOpen ? <>{children}</> : null}
    </>
  );
};

export default CategoryTblRow;
