import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { deliveredPersonApi } from "./deliveredPersonApi";

interface UserState {
  deliveredPersonList: any;
}

const initialState: UserState = {
  deliveredPersonList: {},
};

export const deliveredPersonSlice = createSlice({
  name: "deliveredPerson",
  initialState,
  reducers: {
    clearDeliveredPerson: () => initialState,
    setDeliveredPerson: (state, action) => {
      state.deliveredPersonList = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      deliveredPersonApi.endpoints.getDeliveredPerson.matchFulfilled,
      (state, action: any) => {
        state.deliveredPersonList = action?.payload;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default deliveredPersonSlice.reducer;
export const { clearDeliveredPerson, setDeliveredPerson } =
  deliveredPersonSlice.actions;

export const selectDeliveredPersonList = (state: RootState) =>
  state.deliveredPerson.deliveredPersonList;
export const useDeliveredPersonList = () => {
  const deliveredPersonList = useSelector(selectDeliveredPersonList);
  return useMemo(() => deliveredPersonList, [deliveredPersonList]);
};
