import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

// UI IMPORT
import { Button, Icon } from "../../ui";

// PROJECT IMPORT
import { useAppDispatch } from "../../utills/hooks";
import { toggleDrawer } from "../common/commonSlice";
import { logout } from "../../helperFunctions";
import {
  setAuthToken,
  useAuthToken,
  useRole,
  useUser,
} from "../auth/authSlice";
import { useMedia } from "../../components";
import { Modal } from "../common";
import { useEffect, useState } from "react";
import { CiLogout, CiDeliveryTruck } from "react-icons/ci";
import { FaRegUserCircle } from "react-icons/fa";
import DeliveryChargeModal from "../dashboard/DeliveryChargeModal";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useAuthLogoutMutation } from "../auth/authApi";
import { useOnEnable2faMutation } from "../dashboard/dashboardApi";
import TwoFactorAuthModal from "../dashboard/TwoFactorAuthModal";

const Header = () => {
  const user = useUser();
  const hasAuthToken: any = useAuthToken();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { media } = useMedia();
  const [authLogout, { isLoading }] = useAuthLogoutMutation();
  const [onEnable2fa, { isLoading: isAuthLoading }] = useOnEnable2faMutation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [chargeModal, setChargeModal] = useState(false);
  const [enable2fa, setEnable2fa] = useState(false);
  const [enable2faOpen, setEnable2faOpen] = useState(false);
  const [regenerateAuth, setRegenerateAuth] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [headName, setHeadName] = useState("");
  const role = useRole("delivery-details");

  useEffect(() => {
    if (!!hasAuthToken?.length) {
      setEnable2fa(!!hasAuthToken?.length);
    }
  }, [hasAuthToken]);

  const prepareTitle = (path: any) => {
    const segments = path?.split?.("/");
    for (const group in user?.data?.sidebar) {
      const item = user?.data?.sidebar?.[group];

      if (Array.isArray(item)) {
        for (const item of user?.data?.sidebar?.[group]) {
          if (
            item?.route === `/${path?.split?.("/")?.slice?.(2)?.join?.("/")}` ||
            item?.route === `/${segments?.[2]}/${segments?.[3]}` ||
            item?.route === path
          ) {
            return item?.name;
          }
        }
      } else {
        const item = user?.data?.sidebar?.[group];
        if (
          item?.route === `/${path?.split?.("/")?.slice?.(2)?.join?.("/")}` ||
          item?.route === `${path?.split?.("/")?.slice?.(0, 3)?.join?.("/")}` ||
          item?.route === `/${segments?.[2]}/${segments?.[3]}` ||
          item?.route === path
        ) {
          return item?.name;
        }
      }
    }
    return "";
  };

  const handleAuthentication = async () => {
    try {
      const payload: any = {
        isDisable: !!hasAuthToken?.length && regenerateAuth ? false : enable2fa,
      };
      const res: any = await onEnable2fa(payload).unwrap();
      setEnable2fa(!enable2fa);
      if (enable2fa) {
        dispatch(setAuthToken(""));
      }
      if (res?.status) {
        setQrCode(res?.qr);
      }
      setEnable2faOpen(false);
      setRegenerateAuth(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setHeadName(prepareTitle(location?.pathname));
    // eslint-disable-next-line
  }, [location?.pathname]);

  return (
    <>
      <div className="mainNavbar webNav  fixed-top sticky top-0 z-10 border-b-[1px] border-ev-Quinary ">
        <div className="navBar boxBetween flex justify-between items-center">
          <div className="navToggle title-nav flex">
            {media.width < 768 ? (
              <Icon
                className="me-2 w-6"
                name="MenuBarIcon"
                onClick={() => {
                  dispatch(toggleDrawer());
                }}
              />
            ) : (
              ""
            )}
            {headName}
            {/* {prepareTitle(location?.pathname)} */}
          </div>
          <div className="navIcons  boxBetween flex items-center md:items-start gap-3">
            <div className="flex gap-3 items-center flex-wrap">
              <Button
                onClick={() => {
                  setEnable2faOpen(true);
                }}
                title={`${!enable2fa ? "Enable" : "Disable"} 2FA`}
              >
                {!enable2fa ? "Enable" : "Disable"} 2FA
              </Button>
              {enable2fa ? (
                <div
                  className="flex items-center gap-2 justify-center cursor-pointer text-sm py-1"
                  title="Regenerate 2FA"
                >
                  <Button
                    onClick={() => {
                      setEnable2faOpen(true);
                      setRegenerateAuth(true);
                    }}
                  >
                    Regenerate 2FA
                  </Button>
                </div>
              ) : null}
            </div>

            <div className="flex items-center md:my-2  md:justify-end">
              <Dropdown
                isOpen={isOpen}
                toggle={() => {
                  setIsOpen(!isOpen);
                }}
                direction="down"
                className="stting-dd"
              >
                <DropdownToggle>
                  <FaRegUserCircle size={25} />
                </DropdownToggle>
                {isOpen ? (
                  <DropdownMenu style={{ backgroundColor: "white" }}>
                    <div className={`dropdown-content ${isOpen ? "open" : ""}`}>
                      <div className="flex gap-2 items-center pb-2">
                        <div className="flex justify-center">
                          <div className="flex items-center justify-center w-6 h-6 rounded-full bg-chatlook-Cyan text-white text-md font-bold uppercase">
                            {user?.data?.admin?.name?.charAt?.(0)}
                          </div>
                        </div>
                        <span className="font-bold">
                          {user?.data?.admin?.name || "-"}
                        </span>
                      </div>
                      <ul className="vendor-navbar1">
                        {role?.includes?.("list") ? (
                          <li>
                            <div
                              className="flex items-center gap-2 justify-center cursor-pointer text-sm py-1"
                              title="Delivery Setting"
                              onClick={(e) => {
                                e.stopPropagation();
                                setChargeModal(true);
                              }}
                            >
                              <CiDeliveryTruck size={20} />
                              Delivery Setting
                            </div>
                          </li>
                        ) : null}

                        <li className="border-t border-ev-gray w-full pt-[6px] ">
                          <div
                            className="flex items-center gap-2 cursor-pointer text-sm"
                            title="Logout"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsModalOpen(true);
                            }}
                          >
                            <CiLogout size={20} /> Logout
                          </div>
                        </li>
                      </ul>
                    </div>
                  </DropdownMenu>
                ) : null}
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={!!isModalOpen}
        doneText="Logout"
        isLoading={isLoading}
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to Logout?</p>
          </div>
        }
        onDoneClick={async () => {
          try {
            await authLogout();
            await logout(dispatch);
            navigate("/");
          } catch (err) {
            console.error("Logout Error =-=>", err);
          }
        }}
        onHide={() => {
          setIsModalOpen(false);
        }}
      />
      <DeliveryChargeModal
        isOpen={chargeModal}
        onHide={() => {
          setChargeModal(false);
        }}
      />
      <Modal
        isOpen={!!enable2faOpen /*  || regenerateAuth */}
        isLoading={isAuthLoading}
        doneText="Yes"
        content={
          <div className="flex flex-col gap-2">
            <p>
              {!!hasAuthToken?.length && regenerateAuth
                ? "Are you sure you want to re-generate two-factor authentication?"
                : `Do you want to turn ${!enable2fa ? "on" : "off"} two-factor
              authentication?`}
            </p>
          </div>
        }
        onDoneClick={handleAuthentication}
        onHide={() => {
          setEnable2faOpen(false);
          setRegenerateAuth(false);
        }}
      />
      <TwoFactorAuthModal
        isOpen={qrCode}
        onHide={() => {
          setQrCode("");
        }}
        svgString={qrCode}
      />
    </>
  );
};

export default Header;
