import { api } from "../../../utills/api";

export const deliveredPersonApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDeliveredPerson: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/setting/list-delivery-person",
        body: params,
        method: "POST",
      }),
    }),
    updateDeliveredPerson: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/setting/update-delivery-person",
        body: params,
        method: "POST",
      }),
    }),
    addDeliveredPerson: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/setting/add-delivery-person",
        body: params,
        method: "POST",
      }),
    }),
    deleteDeliveredPerson: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/setting/delete-delivery-person",
        body: params,
        method: "POST",
      }),
    }),
    getDeliveryPersonById: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/setting/delivery-person-by-id",
        body: params,
        method: "POST",
      }),
    }),
    resetPassword: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/setting/resetPwd-delivery-person",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetDeliveredPersonMutation,
  useUpdateDeliveredPersonMutation,
  useAddDeliveredPersonMutation,
  useDeleteDeliveredPersonMutation,
  useGetDeliveryPersonByIdMutation,
  useResetPasswordMutation,
} = deliveredPersonApi;
export const {
  endpoints: {
    getDeliveredPerson,
    addDeliveredPerson,
    updateDeliveredPerson,
    deleteDeliveredPerson,
    getDeliveryPersonById,
    resetPassword,
  },
} = deliveredPersonApi;
