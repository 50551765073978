import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Input } from "../../ui";
import { responseToaster } from "../../helperFunctions";
import ControlledTextArea from "../../ui/forms/ControlledTextArea";
import { useReplyTicketMutation } from "./ticketApi";

const ReplyModal = (props: any) => {
  const { onHide, isOpen, replyData } = props;
  const [rmIsLoading, setRmIsLoading] = useState<boolean>(false);
  const [replyTicket] = useReplyTicketMutation();

  const schema: any = yup.object({
    reply: yup.string().required("Please enter a reply"),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (replyData?.id) {
      setValue("email", replyData?.vendor?.email);
    }
    // eslint-disable-next-line
  }, [replyData, replyData?.id]);

  const onClose = () => {
    reset();
    onHide();
  };

  const onSubmit = async (values: any) => {
    try {
      setRmIsLoading(true);
      const payload: any = {
        id: replyData?.id,
        reply: values?.reply,
      };
      const res: any = await replyTicket(payload).unwrap();
      if (res?.status) {
        onClose();
      }
      responseToaster(res);
      setRmIsLoading(false);
    } catch (error) {
      setRmIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!rmIsLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-5/12 xl:w-6/12 lg:w-8/12  md:w-9/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                Send Reply: {replyData?.vendor?.name}
              </h4>
            </div>
            <div className="modal-body">
              <div className="grid grid-cols-1 items-start gap-3 pt-2 pb-3">
                <Input
                  name="email"
                  label="Email"
                  type="text"
                  value={replyData?.vendor?.email}
                  disabled
                />

                <ControlledTextArea
                  name="reply"
                  label="Message"
                  placeholder="Enter Message"
                  control={control}
                  errors={errors}
                  className="textareacss"
                />
              </div>
            </div>
            <div className="modal-footer pb-1">
              <div className="flex items-center justify-end gap-2">
                <Button
                  type="submit"
                  className="mt-[10px] !h-9 !text-sm"
                  isLoading={rmIsLoading}
                  isLoader={rmIsLoading}
                >
                  Submit
                </Button>
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={rmIsLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ReplyModal;
