import maintenance from "../../assets/images/maintenance.jpg";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../utills/hooks";
import { logout } from "../../helperFunctions";

const UnderMaintenance = ({ isOpen }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-60" />
      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-4/12 xl:w-4/12 lg:w-5/12 md:w-6/12 sm:w-10/12 mx-auto rounded-lg shadow-lg z-50 overflow-y-auto p-4">
        <IoMdArrowBack
          className="cursor-pointer"
          onClick={() => {
            setTimeout(() => {
              logout(dispatch);
            }, 0);

            navigate("/login");
          }}
        />
        <div className="modal-header flex items-center justify-center">
          <img src={maintenance} alt="under maintenance" />
        </div>
        <div className="modal-body">
          <h4 className="modal-title mb-4 font-bold flex gap-2">
            Sorry for the inconvenience but we’re performing some maintenance at
            the moment. We’ll be back shortly!
          </h4>
        </div>
      </div>
    </div>
  );
};

export default UnderMaintenance;
