import { useEffect, useState } from "react";
import { FILTER, LIMIT, statusOptions } from "../../constant";
import { Button, Icon, Pagination, Select } from "../../ui";
import { setCategory, useCategoryList } from "./categorySlice";
import {
  useDeleteCategoryMutation,
  useGetCategoryMutation,
  useUpdateCategoryMutation,
} from "./categoryApi";
import { IoMdAdd } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { RiExpandUpDownFill } from "react-icons/ri";
import CategoryTblRow from "./CategoryTblRow";
import CategoryModal from "./CategoryModal";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "../common";
import {
  dateFormatter,
  responseToaster,
  sortByDate,
  sortByKey,
} from "../../helperFunctions";
import { useDispatch } from "react-redux";
import Switch from "../../ui/forms/Switch";
import Tooltip from "../../ui/elements/Tooltip";
import { useForm } from "react-hook-form";

import CustomeBreadcrumbs from "../common/CustomeBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useRole } from "../auth/authSlice";

const initialValues = {
  // status: "all",
};

const breadcrumbs = [{ label: "Master" }, { label: "Category" }];

const Category = () => {
  const [getCategory, { isLoading }] = useGetCategoryMutation();
  const categoryList: any = useCategoryList();
  const [deleteCategory, { isLoading: isDeleteLoading }] =
    useDeleteCategoryMutation();
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [isCategoryModal, setIsCategoryModal] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const [isDeleteMdlOpen, setIsDeleteMdlOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<any>(false);
  const [categoryData, setCategoryData] = useState({});
  const [sortType, setSortType] = useState("Asce");
  const [updateCategory] = useUpdateCategoryMutation();
  const [statusLoading, setStatusLoading] = useState(false);
  const [level, setLevel] = useState<boolean>(false);
  const [showDiv, setShowDiv] = useState(false);
  const navigate = useNavigate();
  const role = useRole("category");

  if (!role.includes("list")) {
    navigate("/");
  }

  const dispatch = useDispatch();

  // const updateChildStatus = (item: { children: any[] }, newStatus: string) => {
  //   return item.children.map((child) => {
  //     const updatedChild = {
  //       ...child,
  //       status: newStatus,
  //     };
  //     if (child.children) {
  //       updatedChild.children = updateChildStatus(child, newStatus);
  //     }
  //     return updatedChild;
  //   });
  // };

  const onUpdateStatus = async (payload: any) => {
    try {
      setStatusLoading(payload?.id);

      const formData: any = new FormData();
      formData.append("title", payload.title);
      formData.append("id", payload.id);
      formData.append("status", payload?.status);

      const res: any = await updateCategory(formData).unwrap();
      if (res?.status) {
        const updateNestedChildStatus: any = (
          data: any[],
          id: number,
          newStatus: string
        ) => {
          return data?.map((item: any) => {
            if (item.id === id) {
              const updatedItem = { ...item, status: newStatus };
              // Update status of all children recursively
              // if (newStatus === "inactive" && item.children) {
              //   updatedItem.children = updateChildStatus(item, newStatus);
              // }
              return updatedItem;
            } else if (item?.children) {
              return {
                ...item,
                children: updateNestedChildStatus(
                  item?.children,
                  id,
                  newStatus
                ),
              };
            } else {
              return item;
            }
          });
        };

        // Update status in the nested structure
        const updatedTableData = updateNestedChildStatus(
          tableData,
          payload?.id,
          payload.status
        );

        dispatch(setCategory({ ...categoryList, data: updatedTableData }));
      }
      setStatusLoading(false);
      responseToaster(res);
    } catch (err) {
      console.log(err);
      setStatusLoading(false);
    }
  };

  const toggleSortType = () => {
    const newSortType = sortType === "Asce" ? "Desc" : "Asce";
    setSortType(newSortType);
    return newSortType;
  };
  const getCategoryList = async () => {
    try {
      const payload: any = {
        ...filter,
        filter_data: {
          status: filter?.status,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getCategory(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const { register, handleSubmit, reset } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  useEffect(() => {
    setTableData(categoryList?.data?.length ? [...categoryList?.data] : []);
  }, [categoryList]);

  useEffect(() => {
    if (role.includes("list")) {
      getCategoryList();
    }
    // eslint-disable-next-line
  }, [filter]);

  const columns = [
    {
      title: "Title",
      name: "title",
      sortable: true,
      sortableKey: "title",
    },
    {
      title: "Status",
      name: "status",
      sortable: true,
      sortableKey: "status",
    },
    {
      title: "Created",
      name: "created_at_ist",
      sortable: true,
      type: "date",
      sortableKey: "created_at_ist",
      Cell: (item: any) => {
        return <span>{dateFormatter(item?.created_at_ist)}</span>;
      },
    },
    {
      title: "Action",
      name: "action",
    },
  ];

  const handleAddShopCategory = () => {
    setIsCategoryModal(true);
    setCategoryId(0);
  };

  const onCategoryDelete = async () => {
    try {
      const payload: any = {
        id: deleteId,
      };
      const res = await deleteCategory(payload).unwrap();
      if (res) {
        responseToaster(res);
        setIsDeleteMdlOpen(false);
        setDeleteId(false);
        setFilter({ ...filter, isFilter: false });
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const onSort = (key: any, type: any = "normal", sort: any) => {
    if (type === "date") {
      setTableData(sortByDate(tableData, key, sort));
    } else {
      setTableData(sortByKey(tableData, key, sort));
    }
  };

  const categoryRow = (tableData: any, level = 1) => {
    return tableData?.map((item: any, index: number) => {
      const columns1 = [
        {
          title: "Title",
          name: "title",
          type: "ellipsis",
        },
        {
          title: "Status",
          name: "status",
          Cell: (data: any) => (
            <Switch
              checked={data?.status === "active"}
              isLoading={statusLoading === data?.id}
              isDisabled={!role.includes("edit") || statusLoading}
              onChange={async (value: any, e: any) => {
                e.stopPropagation();
                try {
                  const payload = {
                    ...data,
                    status: value ? "active" : "inactive",
                    id: data?.id,
                  };
                  await onUpdateStatus(payload);
                } catch (err) {}
              }}
            />
          ),
          sortable: true,
          sortableKey: "status",
        },
        {
          title: "Created",
          name: "created_at_ist",
          Cell: (item: any) => {
            return <span>{item?.created_at_ist}</span>;
          },
        },
        {
          title: "Action",
          name: "action",
          Cell: (data: any) => (
            <>
              <div className="flex gap-2">
                {level !== 3 ? (
                  <Tooltip text="Add">
                    <Button
                      className="!h-8 !w-8 !p-0"
                      onClick={(e: { stopPropagation: () => void }) => {
                        e.stopPropagation();
                        setCategoryId(data?.id || 0);
                        setLevel(true);
                        setIsCategoryModal(true);
                      }}
                      disabled={!role.includes("edit")}
                    >
                      <IoMdAdd />
                    </Button>
                  </Tooltip>
                ) : null}
                <Tooltip text="Edit">
                  <Button
                    className="!h-8 !w-8 !p-0"
                    onClick={(e: { stopPropagation: () => void }) => {
                      e.stopPropagation();
                      setIsCategoryModal(true);
                      setCategoryData(data);
                    }}
                    disabled={!role.includes("edit")}
                  >
                    <MdEdit />
                  </Button>
                </Tooltip>
                <Tooltip text="Delete" className="right-0">
                  <Button
                    className="!h-8 !w-8 !p-0"
                    onClick={(e: { stopPropagation: () => void }) => {
                      e.stopPropagation();
                      setDeleteId(data?.id);
                      setIsDeleteMdlOpen(true);
                    }}
                    disabled={!role.includes("delete")}
                  >
                    <MdDeleteOutline />
                  </Button>
                </Tooltip>
              </div>
            </>
          ),
        },
      ];

      return (
        <CategoryTblRow
          columns={columns1}
          item={item}
          level={level}
          lastLevelId={tableData?.[tableData?.length - 1]?.id}
          isExpandable={!!item?.children}
          key={index}
        >
          {!!item?.children?.length
            ? categoryRow(item?.children, level + 1)
            : null}
        </CategoryTblRow>
      );
    });
  };

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div className="">
        {role.includes("create") ? (
          <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
            <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
            <div className="flex gap-2 sm:flex-wrap">
              <Button
                classNm={`!text-ev-white !border-0 appl-btn1`}
                onClick={() => {
                  setShowDiv(!showDiv);
                }}
                className={`${
                  !showDiv ? "!bg-chatlook-Cyan" : "!bg-[#22a99c]"
                }`}
              >
                <Icon name="FilterIcon" />
              </Button>
              <Button onClick={handleAddShopCategory}>Add Shop Category</Button>
            </div>
          </div>
        ) : null}
        {showDiv ? (
          <div className="cardcss mb-3 flex">
            <form
              className="flex gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Select
                name="status"
                label="Status"
                placeholder="All"
                options={statusOptions}
                register={register}
              />
              <Button type="submit" className="!text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm"
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true, isClear: true });
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        ) : null}
        {role.includes("list") ? (
          <div className="cardcss mb-3">
            <div
              className={`relative shadow bg-white 
    table-custome1 text-[14px]  ${filter ? "pb-[75px]" : "pb-[20px]"}`}
            >
              <div
                className={`main-table whitespace-nowrap overflow-x-scroll`}
                style={{
                  minHeight: `200px`,
                }}
              >
                <table className={`w-full table`}>
                  <thead
                    className={`table-header sticky text-sm font-bold text-ev-secondary top-0 bg-ev-gray h-[46px]`}
                  >
                    <tr>
                      <th className="no-wrap">
                      </th>
                      {columns?.map((column: any, index) => {
                        return (
                          <th key={index} className="text-start no-wrap">
                            <div className="flex flex-row items-center gap-3">
                              <p>{column?.title}</p>
                              {column?.sortable ? (
                                <div
                                  onClick={() => {
                                    const newSortType = toggleSortType();
                                    onSort(
                                      column?.sortableKey,
                                      column?.type,
                                      newSortType
                                    );
                                  }}
                                  className="cursor-pointer"
                                >
                                  <RiExpandUpDownFill fill="#9e9393" />
                                </div>
                              ) : null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody className={`text-sm font-medium tbl-body`}>
                    {isLoading ? (
                      <tr className="table-loader !border-b-0">
                        <td>
                          <svg
                            aria-hidden="true"
                            className={` w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600`}
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </td>
                      </tr>
                    ) : tableData?.length ? (
                      categoryRow(tableData)
                    ) : (
                      <tr
                        className="table-loader text-center "
                        style={{
                          minHeight: "40px",
                          maxHeight: "100px",
                          borderBottom: "none",
                        }}
                      >
                        <td>No data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {!isLoading && filter ? (
                  <Pagination
                    page_no={filter?.page_no || 1}
                    limit={filter?.limit || LIMIT}
                    count={categoryList?.total_item || 0}
                    data={tableData}
                    handlePageChange={(page_no: number) =>
                      filter
                        ? setFilter({
                            ...filter,
                            page_no,
                            isFilter: false,
                          })
                        : null
                    }
                    handleLimitChange={(limit: number) =>
                      filter
                        ? setFilter({
                            ...filter,
                            limit,
                            isFilter: false,
                          })
                        : null
                    }
                  />
                ) : null}
              </div>
            </div>
            <CategoryModal
              isOpen={isCategoryModal}
              onGet={getCategoryList}
              onHide={() => {
                setIsCategoryModal(false);
                setCategoryData({});
                setLevel(false);
              }}
              categoryId={categoryId}
              categoryData={categoryData}
              level={level}
            />
            <Modal
              isOpen={!!isDeleteMdlOpen}
              isLoading={isDeleteLoading}
              doneText="Delete"
              content={
                <div className="flex flex-col gap-2">
                  <p>Are you sure you want to delete this?</p>
                </div>
              }
              onDoneClick={onCategoryDelete}
              onHide={() => {
                setIsDeleteMdlOpen(false);
              }}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Category;
