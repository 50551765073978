import React, { memo } from "react";
import { IconProps } from ".";

export interface PermissionIconProps extends IconProps {}
const PermissionIcon = (props: PermissionIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.94531 0.251439C9.09688 0.326439 8.16406 0.673314 7.36719 1.19831C6.93594 1.48425 6.24688 2.16863 5.95156 2.60925C5.29531 3.58894 5 4.55925 5 5.74988C5 6.9405 5.27188 7.8405 5.92813 8.84363C6.66875 9.97331 7.95781 10.8452 9.3125 11.1405C10.85 11.478 12.5609 11.1124 13.7891 10.1936C15.9453 8.57175 16.6344 5.6655 15.4297 3.2655C14.4031 1.22644 12.2516 0.0405017 9.94531 0.251439ZM11.1641 1.83581C13.0625 2.16863 14.4688 3.83269 14.4688 5.74988C14.4688 6.58425 14.1969 7.40456 13.7094 8.07019C12.3453 9.91706 9.81406 10.2639 8.02813 8.84363C7.42344 8.3655 6.99688 7.74675 6.725 6.96863C6.575 6.53269 6.57031 6.50457 6.57031 5.74988C6.57031 4.99519 6.575 4.96706 6.725 4.53113C7.08125 3.50456 7.73281 2.73113 8.66094 2.24363C9.45781 1.81706 10.2875 1.68581 11.1641 1.83581Z"
        fill={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[0] || ""}`}
      />
      <path
        d="M17.0232 11.2859C16.7045 11.3469 16.2263 11.5062 15.9451 11.6469C15.167 12.0312 14.4216 12.8141 14.0701 13.6062C13.831 14.1453 13.7513 14.5391 13.742 15.2328L13.7326 15.8797L12.5091 17.1078C11.7966 17.825 11.2763 18.3828 11.2576 18.4531C11.2435 18.5187 11.2341 19.1844 11.2435 19.9391C11.2576 21.4906 11.2529 21.4719 11.656 21.6734C11.8576 21.7766 11.9091 21.7812 13.142 21.7812C14.1873 21.7812 14.4545 21.7672 14.5998 21.7109C14.7123 21.6641 15.1857 21.2281 15.9685 20.45L17.1638 19.2594L17.867 19.2406C18.5045 19.2219 18.6123 19.2031 19.0154 19.0672C20.3748 18.6078 21.3873 17.4828 21.692 16.0859C21.781 15.6781 21.767 14.7031 21.6685 14.3281C21.4716 13.5687 21.167 13.0156 20.6654 12.4906C20.0607 11.8578 19.3857 11.4828 18.5373 11.3094C18.1435 11.2297 17.4029 11.2203 17.0232 11.2859ZM18.3263 12.8516C19.3482 13.1187 20.0935 13.9812 20.2154 15.0359C20.2904 15.6734 19.9904 16.4844 19.4935 16.9859C18.8982 17.5906 18.0685 17.8297 17.1826 17.6609C16.6435 17.5578 16.6716 17.5391 15.242 18.9687L13.9763 20.2344H13.381H12.781V19.6391V19.0391L14.0185 17.7969C14.7029 17.1125 15.2841 16.4937 15.3076 16.4234C15.3732 16.25 15.3732 16.0016 15.3029 15.5984C15.1013 14.4125 15.931 13.1844 17.1498 12.8562C17.4779 12.7672 17.9935 12.7625 18.3263 12.8516Z"
        fill={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[1] || ""}`}
      />
      <path
        d="M17.3515 14.2156C16.6718 14.464 16.4328 15.3547 16.8828 15.9547C17.2859 16.4797 18.2468 16.4609 18.6406 15.9125C19.2828 15.0312 18.3781 13.8406 17.3515 14.2156Z"
        fill={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[2] || ""}`}
      />
      <path
        d="M9.54682 12.1062C5.1265 12.3641 1.6765 13.9156 0.589004 16.1328C0.289005 16.7469 0.256192 16.9437 0.232755 18.1109C0.209317 19.2031 0.237442 19.4609 0.406192 19.8312C0.570255 20.1781 0.804629 20.4031 1.19369 20.5953L1.54994 20.7734H5.66088C9.76244 20.7734 9.7765 20.7734 9.90775 20.675C10.3953 20.3141 10.3671 19.5687 9.86088 19.325C9.71088 19.2594 9.31713 19.25 5.80619 19.25C2.32807 19.25 1.90619 19.2406 1.84057 19.175C1.77963 19.1141 1.76557 18.9687 1.76557 18.3125C1.76557 17.4078 1.80775 17.1406 2.01869 16.7422C2.314 16.1797 3.19057 15.425 4.07182 14.9797C4.92494 14.5484 6.0265 14.1875 7.17963 13.9578C8.39369 13.7141 9.07338 13.6578 10.7749 13.6531C12.2421 13.6484 12.3124 13.6437 12.4343 13.55C12.6828 13.3672 12.7578 13.2172 12.7578 12.9219C12.7531 12.5234 12.5374 12.2609 12.1437 12.1719C11.9468 12.125 10.0437 12.0781 9.54682 12.1062Z"
        fill={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[3] || ""}`}
      />
    </svg>
  );
};

const MemoPermissionIcon = memo(PermissionIcon);
export default MemoPermissionIcon;
