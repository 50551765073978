import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Input, Loader } from "../../ui";
import { useEffect, useState } from "react";
import {
  useGetDeliveryDetailsMutation,
  useUpdateDeliveryDetailsMutation,
} from "./dashboardApi";
import { responseToaster } from "../../helperFunctions";
// import ControlledSwitch from "../../ui/forms/ControlledSwitch";
import { useRole } from "../auth/authSlice";

const DeliveryChargeModal = (props: any) => {
  const { isOpen, onHide } = props;
  const [dcLoading, setDcLoading] = useState(false);
  const [updateDetails, setUpdateDetails] = useState<any>("");
  const [getDeliveryDetails, { isLoading }] = useGetDeliveryDetailsMutation();
  const [updateDeliveryDetails] = useUpdateDeliveryDetailsMutation();
  const role = useRole("delivery-details");

  const schema: any = yup.object({
    delivery_charge: yup.string().required("Please enter a delivery charges"),
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
  /*   defaultValues: {
      delivery_available: 0,
    }, */
  });

  const onClose = () => {
    onHide();
    reset();
  };
  const getDeliveryCharges = async () => {
    try {
      const res: any = await getDeliveryDetails().unwrap();
      if (res) {
        setValue("delivery_charge", res?.data?.delivery_charge);
        // setValue("delivery_available", res?.data?.delivery_available);
        setUpdateDetails(res?.data?.delivery_charge);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getDeliveryCharges();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  if (!isOpen) return null;

  const onSubmit = async (values: any) => {
    try {
      setDcLoading(true);
      const payload: any = {
        delivery_charge: values?.delivery_charge,
        // delivery_available: values?.delivery_available,
      };
      const res: any = await updateDeliveryDetails(payload).unwrap();
      setDcLoading(false);
      onClose();
      responseToaster(res);
    } catch (error) {
      setDcLoading(false);
      console.log("error", error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-60"
        onClick={() => {
          onClose();
        }}
      />
      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-4/12 xl:w-4/12 lg:w-5/12  md:w-6/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-5 px-6 text-left  max-h-[84vh]">
          {isLoading ? (
            <div className="h-[25vh] z-[9999] flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-header flex justify-between">
                <h4 className="modal-title mb-4 font-bold capitalize">
                  Delivery Settings
                </h4>
              </div>
              <div className="modal-body">
                <div className="grid grid-cols-1 items-start gap-3 pt-2 pb-3">
                  <Input
                    name="delivery_charge"
                    label="Delivery Charge"
                    placeholder="Enter Delivery Charge"
                    type="text"
                    value={updateDetails}
                    disabled={!role.includes("create")}
                    autoFocus
                    errors={errors}
                    onChange={(e) => {
                      const value = e.target.value;
                      const re = /^[0-9\b]+$/;
                      if (value === "" || re.test(value)) {
                        setUpdateDetails(value);
                        setValue("delivery_charge", value);
                      }
                    }}
                  />
                  {/* <ControlledSwitch
                    control={control}
                    name="delivery_available"
                    label="Available Delivery"
                    isDisabled={!role.includes("create")}
                  /> */}
                </div>
              </div>
              <div className="modal-footer">
                <div className="flex items-center justify-end gap-2">
                  {role.includes("create") ? (
                    <Button
                      type="submit"
                      className="mt-[10px] !h-9 !text-sm"
                      isLoading={dcLoading}
                      isLoader={dcLoading}
                    >
                      Update
                    </Button>
                  ) : null}
                  <Button
                    variant="Transaparent"
                    className="mt-[10px]  !text-sm !h-9"
                    onClick={() => onClose()}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryChargeModal;
