import { Fragment, useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import { Button, Icon, Input, Table, TableRow } from "../../../ui";
import { useVendorPlanList } from "./vendorPlanSlice";
import { useGetVendorPlanMutation } from "./vendorPlanApi";
import { responseToaster, seperator } from "../../../helperFunctions";
import { useForm } from "react-hook-form";

import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import StatusStyle from "../../common/StatusStyle";
import VendorPlanUpdate from "./VendorPlanUpdate";

const breadcrumbs = [{ label: "Vendor" }, { label: "Vendor Plan" }];

const VendorPlan = () => {
  const [getVendorPlan, { isLoading }] = useGetVendorPlanMutation();
  const vendorPlanList = useVendorPlanList();

  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [showDiv, setShowDiv] = useState(false);

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Vendor Name",
      name: "vendor_name",
      sortable: true,
      sortableKey: "vendor.name",
      Cell: (data: any) => {
        return <span>{data?.vendor?.name || "-"}</span>;
      },
    },
    {
      title: "Customer Support",
      name: "customer_Support",
      sortable: true,
      sortableKey: "customer_Support",
      Cell: (data: any) => {
        return <StatusStyle status={data?.customer_Support} />;
      },
    },
    {
      title: "Package Price",
      name: "package_price",
      sortable: true,
      sortableKey: "package_price",
      Cell: (data: any) => {
        return <span>{seperator(data?.package_price)}</span>;
      },
    },

    {
      title: "Category Limit",
      name: "categoery_limit",
      sortable: true,
      sortableKey: "categoery_limit",
      Cell: (data: any) => {
        return (
          <>
            <VendorPlanUpdate
              currKey={"categoery_limit"}
              tableData={tableData}
              data={data}
              unlimited={true}
            />
          </>
        );
      },
    },
    {
      title: "Product Limits",
      name: "product_limits",
      sortable: true,
      sortableKey: "product_limits",
      Cell: (data: any) => {
        return (
          <>
            <VendorPlanUpdate
              currKey={"product_limits"}
              tableData={tableData}
              data={data}
              unlimited={true}
            />
          </>
        );
      },
    },
    {
      title: "Subscription Start Date",
      name: "subscription_start_ist",
      sortable: true,
      type: "date",
      sortableKey: "subscription_start_ist",
    },
    {
      title: "Subscription End Date",
      name: "subscription_expired_date",
      sortable: true,
      type: "date",
      sortableKey: "subscription_expired_date",
    },
    {
      title: "Social Media Integration",
      name: "social_media_integration",
      sortable: true,
      sortableKey: "social_media_integration",
    },
    {
      title: "In Store Billing",
      name: "instore_billing",
      sortable: true,
      sortableKey: "instore_billing",
    },
    {
      title: "QR Code",
      name: "QR_code",
      sortable: true,
      sortableKey: "QR_code",
    },
    {
      title: "Coupons",
      name: "coupons",
      sortable: true,
      sortableKey: "coupons",
    },
    {
      title: "Customer List",
      name: "customer_list",
      sortable: true,
      sortableKey: "customer_list",
    },
    {
      title: "Delivery App",
      name: "delivery_app",
      sortable: true,
      sortableKey: "delivery_app",
    },
    {
      title: "Online Store",
      name: "online_store",
      sortable: true,
      sortableKey: "online_store",
    },
    {
      title: "Stock Management",
      name: "stock_management",
      sortable: true,
      sortableKey: "stock_management",
    },
    {
      title: "Store Analytics",
      name: "store_analytics",
      sortable: true,
      sortableKey: "store_analytics",
    },
    {
      title: "Transaction Charges",
      name: "transaction_charges",
      sortable: true,
      sortableKey: "transaction_charges",
    },
  ];

  const getVendorPlanList = async () => {
    try {
      const { search, ...rest } = filter;
      const payload: any = {
        ...rest,
        filter_data: {
          search,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getVendorPlan(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const { register, handleSubmit, reset } = useForm<any>();

  useEffect(() => {
    setTableData(vendorPlanList?.data?.length ? [...vendorPlanList?.data] : []);
  }, [vendorPlanList]);
  useEffect(() => {
    getVendorPlanList();
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
        <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />

        <Button
          classNm={`!text-ev-white !border-0 appl-btn1`}
          onClick={() => {
            setShowDiv(!showDiv);
          }}
          className={`${!showDiv ? "!bg-chatlook-Cyan" : "!bg-[#22a99c]"}`}
        >
          <Icon name="FilterIcon" />
        </Button>
      </div>
      {showDiv ? (
        <div className="cardcss mb-3 flex">
          <form
            className="flex gap-2 items-end sm:flex-wrap"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              name="search"
              label="Search"
              placeholder="Search by vendor name"
              register={register}
            />

            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isFilter: true, isClear: true });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      <div className="cardcss">
        <Table
          containerClass="planTable"
          columns={columns}
          isExpendable={false}
          isLoading={isLoading}
          data={tableData || []}
          setData={setTableData}
          count={vendorPlanList?.total_item || 0}
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
              isFilter: false,
            });
          }}
        >
          {tableData?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  columns={columns}
                  isExpendable={false}
                  item={item}
                  onClick={(item: any) => {}}
                ></TableRow>
              </Fragment>
            );
          })}
        </Table>
      </div>
    </>
  );
};

export default VendorPlan;
