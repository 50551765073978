import React, { Fragment, useEffect, useState } from "react";
import { Button, Loader, Table, TableRow } from "../../../ui";
import { FILTER } from "../../../constant";
import {
  useDeleteDeliveredPersonMutation,
  useGetDeliveredPersonMutation,
  useResetPasswordMutation,
  useUpdateDeliveredPersonMutation,
} from "./deliveredPersonApi";
import {
  setDeliveredPerson,
  useDeliveredPersonList,
} from "./deliveredPersonSlice";
import DeliveredPersonModal from "./DeliveredPersonModal";
import { MdEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "../../common";
import Switch from "../../../ui/forms/Switch";
import { responseToaster } from "../../../helperFunctions";
import { useDispatch } from "react-redux";
import Tooltip from "../../../ui/elements/Tooltip";
import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import { MdLockReset } from "react-icons/md";
import ResetPwdModal from "./ResetPwdModal";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../auth/authSlice";

const DeliveredPerson = () => {
  const [getDeliveredPerson, { isLoading }] = useGetDeliveredPersonMutation();
  const deliveredPersonList = useDeliveredPersonList();
  const [updateDeliveredPerson] = useUpdateDeliveredPersonMutation();
  const [resetPassword] = useResetPasswordMutation();

  const [deleteDeliveredPerson, { isLoading: isDeleteLoading }] =
    useDeleteDeliveredPersonMutation();
  const [isDeliveredPersonModal, setIsDeliveredPersonModal] = useState(false);
  const [isPwdLoading, setIsDPwdLoading] = useState(false);
  const [pwdData, setPwdData] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();
  const [deliveredPersonData, setDeliveredPersonData] = useState({});
  const [statusLoading, setStatusLoading] = useState(false);
  const navigate = useNavigate();
  const role = useRole("delivery-person");

  if (!role.includes("list")) {
    navigate("/");
  }
  const breadcrumbs = [{ label: "Delivered" }, { label: "Delivery Person" }];

  const dispatch = useDispatch();

  const handleAddDeliveredPerson = () => {
    setIsDeliveredPersonModal(true);
  };

  const onUpdateStatus = async (payload: any) => {
    try {
      setStatusLoading(payload?.id);
      const res = await updateDeliveredPerson(payload).unwrap();

      const index = tableData?.findIndex(
        (item: { id: any }) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...tableData];
        newData[index] = {
          ...newData[index],
          status: payload?.status,
        };
        dispatch(
          setDeliveredPerson({
            ...deliveredPersonList,
            data: newData,
          })
        );
      }
      responseToaster(res);
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "First Name",
      name: "first_name",
      sortable: true,
      sortableKey: "first_name",
    },
    {
      title: "Last Name",
      name: "last_name",
      sortable: true,
      sortableKey: "last_name",
    },
    {
      title: "Email",
      name: "email_id",
      sortable: true,
      sortableKey: "email_id",
    },
    {
      title: "Mobile Number",
      name: "mobile_number",
      sortable: true,
      sortableKey: "mobile_number",
    },
    {
      title: "Status",
      name: "status",
      sortable: true,
      sortableKey: "status",
      Cell: (data: any) => (
        <Switch
          checked={data?.status === "active"}
          isLoading={statusLoading === data?.id}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                status: value ? "active" : "inactive",
                id: data?.id,
              };
              await onUpdateStatus(payload);
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Action",
      name: "action",
      Cell: (data: any) => (
        <>
          <div className="flex gap-1">
            <Tooltip text="Reset Password">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  onPwdReset(data?.id);
                }}
                disabled={!role.includes("edit")}
              >
                {isPwdLoading ? (
                  <Loader loaderClassName="w-[15px] ml-2" />
                ) : (
                  <MdLockReset />
                )}
              </Button>
            </Tooltip>
            <Tooltip text="Edit">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setIsDeliveredPersonModal(true);
                  setDeliveredPersonData(data);
                }}
                disabled={!role.includes("edit")}
              >
                <MdEdit />
              </Button>
            </Tooltip>
            <Tooltip text="Delete" className="right-0">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setDeleteData(data);
                  setIsDeleteModalOpen(true);
                }}
                disabled={!role.includes("delete")}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const onDeliveredPersonDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteDeliveredPerson(payload).unwrap();
      if (res) {
        responseToaster(res);
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };
  const onPwdReset = async (id: any) => {
    try {
      const payload: any = {
        id,
      };
      const res = await resetPassword(payload).unwrap();
      if (res) {
        setPwdData(res?.data);
        responseToaster(res);
        setIsDPwdLoading(false);
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getDeliveredPersonList = async () => {
    try {
      // eslint-disable-next-line
      const { vendor_id = 0, status, ...rest } = filter;
      const payload: any = {
        ...rest,
        filter_data: {
          status,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getDeliveredPerson(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    if (role.includes("list")) {
      getDeliveredPersonList();
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setTableData(
      deliveredPersonList?.data?.length ? [...deliveredPersonList?.data] : []
    );
    // eslint-disable-next-line
  }, [deliveredPersonList]);

  // const onSubmit = async (values: any) => {
  //   setFilter({ ...FILTER, ...values, isFilter: true });
  // };

  return (
    <>
      <div className="">
        <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
          <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
          {role.includes("create") ? (
            <div className="flex gap-2 justify-between">
              <div className="flex justify-end">
                <Button onClick={() => handleAddDeliveredPerson()}>
                  Add Delivery Person
                </Button>
              </div>
            </div>
          ) : null}
        </div>

        {role.includes("list") ? (
          <div className="cardcss">
            <Table
              columns={columns}
              isExpendable={false}
              isLoading={isLoading}
              data={tableData || []}
              setData={setTableData}
              count={deliveredPersonList?.total_item}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        ) : null}
      </div>
      <DeliveredPersonModal
        isOpen={isDeliveredPersonModal}
        onGet={getDeliveredPersonList}
        deliveredPersonData={deliveredPersonData}
        onHide={() => {
          setIsDeliveredPersonModal(false);
          setDeliveredPersonData({});
        }}
      />
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onDeliveredPersonDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
      <ResetPwdModal
        isOpen={!!pwdData}
        personData={pwdData}
        onHide={() => {
          setPwdData(null);
        }}
      />
    </>
  );
};

export default DeliveredPerson;
