import { api } from "../../utills/api";

export const videoApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVideo: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/video/get-video-list",
        body: params,
        method: "POST",
      }),
    }),
    updateVideo: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/video/update-video",
        body: params,
        method: "POST",
      }),
    }),
    addVideo: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/video/add-video",
        body: params,
        method: "POST",
      }),
    }),
    deleteVideo: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/video/delete-video",
        body: params,
        method: "POST",
      }),
    }),
    getVideoById: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/video/get-video-by-id",
        body: params,
        method: "POST",
      }),
    }),
    getVideoList: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/video/video-list",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetVideoMutation,
  useUpdateVideoMutation,
  useAddVideoMutation,
  useDeleteVideoMutation,
  useGetVideoByIdMutation,
  useGetVideoListMutation,
} = videoApi;
export const {
  endpoints: { getVideo, addVideo, updateVideo, deleteVideo, getVideoById, getVideoList },
} = videoApi;
