import { api } from "../../../utills/api";

export const tagApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTag: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/tag/get-tag",
        body: params,
        method: "POST",
      }),
    }),
    updateTag: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/tag/update-tag",
        body: params,
        method: "POST",
      }),
    }),
    addTag: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/tag/add-tag",
        body: params,
        method: "POST",
      }),
    }),
    deleteTag: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/tag/delete-tag",
        body: params,
        method: "POST",
      }),
    }),
    getTagById: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/tag/get-tag-by-id",
        body: params,
        method: "POST",
      }),
    }),
    getTagList: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/tag/tag-list",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetTagMutation,
  useUpdateTagMutation,
  useAddTagMutation,
  useDeleteTagMutation,
  useGetTagByIdMutation,
  useGetTagListMutation,
} = tagApi;
export const {
  endpoints: { getTag, addTag, updateTag, deleteTag, getTagById, getTagList },
} = tagApi;
