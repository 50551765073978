import { api } from "../../utills/api";

export const ratingReviewApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRatingReview: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/rating-review/get-rating-review-list",
        body: params,
        method: "POST",
      }),
    }),
    updateRatingReview: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/rating-review/update-rating-review-status",
        body: params,
        method: "POST",
      }),
    }),
    deleteRatingReview: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/rating-review/delete-rating-review",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetRatingReviewMutation,
  useUpdateRatingReviewMutation,
  useDeleteRatingReviewMutation,
} = ratingReviewApi;
export const {
  endpoints: { getRatingReview, updateRatingReview, deleteRatingReview },
} = ratingReviewApi;
