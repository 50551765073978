import { Button, Loader } from "../../ui";

const Modal = (props: any) => {
  const {
    isOpen,
    isLoading,
    onHide,
    onDoneClick,
    content = "Are you sure ?",
    doneText = "Done",
    cancelText = "Cancel",
  } = props;

  const onClose = () => {
    onHide();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-60"
        onClick={() => {
          onClose();
        }}
      />

      <div className="modal-container bg-chatlook-white border w-6/12 2xl:w-4/12 xl:w-4/12 lg:w-5/12 md:w-7/12 sm:w-9/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-9 px-6 text-center ">
          {typeof content === "string" ? (
            <p className="text-lg text-ev-black">{content}</p>
          ) : (
            content
          )}
          <div className="flex justify-center pt-4 items-center gap-3">
            {!isLoading ? (
              <Button onClick={onDoneClick}>{doneText}</Button>
            ) : (
              <div className="flex item-center justify-center">
                <Loader />
              </div>
            )}
            <Button variant="Transaparent" onClick={onClose}>
              {cancelText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
