import { Fragment, useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import {
  Button,
  ControlledDatePicker,
  Icon,
  Input,
  Select,
  Table,
  TableRow,
} from "../../../ui";
import {
  setCurrTab,
  useCurrentTab,
  useDeliveredByList,
  useDeliveredByPendingList,
} from "./deliveredBySlice";
import { useGetDeliveredByMutation } from "./deliveredByApi";
import {
  dateFormatter,
  prepareUsersOptions,
  prepareVendorOptions,
  responseToaster,
} from "../../../helperFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import StatusStyle from "../../common/StatusStyle";
import { useForm } from "react-hook-form";
import { useVendorList } from "../../vendor/vendorList/vendorSlice";
import { useUsersList } from "../../customers/usersSlice";
import { useGetUsersMutation } from "../../customers/usersApi";

import { useGetVendorMutation } from "../../vendor/vendorList/vendorApi";
import { statusOptionsForOrder } from "./mock";
import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import { useDispatch } from "react-redux";
import Tooltip from "../../../ui/elements/Tooltip";
import { MdAssignmentInd } from "react-icons/md";
import AssignDeliveryPerson from "./AssignDeliveryPerson";
import { useRole } from "../../auth/authSlice";

interface DeliveryTabs {
  label: string;
  Component?: any;
}

const initialValues = {
  startDate: [null, null],
};

const DeliveredBy = () => {
  const [getDeliveredBy, { isLoading }] = useGetDeliveredByMutation();
  const deliveredByList = useDeliveredByList();
  const deliveredByPendingList = useDeliveredByPendingList();
  const [getUsers] = useGetUsersMutation();
  const [getVendorList] = useGetVendorMutation();
  const currentTab = useCurrentTab();
  const vendorList = useVendorList();
  const usersList = useUsersList();
  const navigate = useNavigate();
  const route = useLocation()?.pathname;
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [showDiv, setShowDiv] = useState(false);
  const [isAssignMdlOpen, setIsAssignMdlOpen] = useState(false);
  const [deliveredPersonData, setDeliveredPersonData] = useState({});

  const role = useRole("order");

  if (!role.includes("list")) {
    navigate("/");
  }
  const dispatch = useDispatch();
  const deliveryTab: DeliveryTabs[] = [
    { label: "Pending", Component: DeliveredBy },
    { label: "Assigned", Component: DeliveredBy },
  ];

  const breadcrumbs = [
    { label: "Delivered" },
    { label: "Delivered By" },
    { label: currentTab },
  ];

  const columns = [
    {
      title: "Status",
      name: "status",
      sortable: true,
      sortableKey: "status",
      Cell: (data: any) => {
        return (
          <>{data?.status ? <StatusStyle status={data?.status} /> : "-"}</>
        );
      },
    },
    {
      title: "Order Id",
      name: "ord_id",
      sortable: true,
      sortableKey: "ord_id",
    },
    {
      title: "Customer Name",
      name: "shipping_address",
      sortable: true,
      sortableKey: "shipping_address.name",
      Cell: (data: any) => {
        return (
          <>
            {data?.shipping_address?.name && data?.shipping_address?.lname
              ? `${data?.shipping_address?.name || ""} ${
                  data?.shipping_address?.lname || ""
                }`
              : "Unknown"}
          </>
        );
      },
    },
    {
      title: "Vendor Name",
      name: "vendor_name",
      sortable: true,
      sortableKey: "vendor.name",
      Cell: (data: any) => {
        return <>{data?.vendors?.name || "-"}</>;
      },
    },
    {
      title: "Delivery Person",
      name: "delivery_person",
      sortable: true,
      showColumn: currentTab === "Assigned",
      sortableKey: "delivery_persons.first_name",
      Cell: (data: any) => {
        return <>{data?.delivery_persons?.first_name || "-"}</>;
      },
    },

    {
      title: "Order Date",
      name: "created_at_ist",
      sortable: true,
      sortableKey: "created_at_ist",
      type: "date",
      Cell: (data: any) => {
        return <>{data?.created_at_ist}</>;
      },
    },

    {
      title: "Action",
      name: "action",
      showColumn: currentTab === "Pending",
      Cell: (data: any) => (
        <>
          <div className="flex gap-1">
            <Tooltip text="Assign Delivery Person">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setIsAssignMdlOpen(true);
                  setDeliveredPersonData(data);
                }}
                disabled={!role.includes("edit")}
              >
                <MdAssignmentInd />
              </Button>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  const { register, handleSubmit, reset, control } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });
  const getDeliveredByList = async () => {
    try {
      const { status, search, startDate, vendorId, user_id, ...rest } = filter;
      const payload: any = {
        ...rest,
        filter_data: {
          delivered_by: 0,
          ...(currentTab === "Pending" ? { delivery_persons_id: 0 } : null),
          ...(currentTab === "Assigned" ? { is_assigned: 1 } : null),
          status,
          vendorId,
          user_id,
          search,
          start_date: dateFormatter(startDate?.[0], "start"),
          end_date: dateFormatter(startDate?.[1], "end", startDate?.[0]),
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getDeliveredBy(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    if (currentTab === "Assigned") {
      setTableData(
        deliveredByList?.data?.length ? [...deliveredByList?.data] : []
      );
    } else {
      setTableData(
        deliveredByPendingList?.data?.length
          ? [...deliveredByPendingList?.data]
          : []
      );
    }
  }, [deliveredByList, deliveredByPendingList, currentTab]);
  useEffect(() => {
    if (role.includes("list")) {
      getVendorList();
      getUsers();
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (role.includes("list")) {
      getDeliveredByList();
    }
    // eslint-disable-next-line
  }, [filter, currentTab]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
        <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />

        {role.includes("list") ? (
          <Button
            classNm={`!text-ev-white !border-0 appl-btn1`}
            onClick={() => {
              setShowDiv(!showDiv);
            }}
            className={`${!showDiv ? "!bg-chatlook-Cyan" : "!bg-[#22a99c]"}`}
          >
            <Icon name="FilterIcon" />
          </Button>
        ) : null}
      </div>
      {showDiv ? (
        <div className="cardcss mb-3">
          <form
            className="flex gap-2 items-end sm:flex-wrap "
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              name="search"
              label="Search By Id"
              placeholder="Search Order Id"
              register={register}
            />
            <Select
              name="vendorId"
              label="Vendor"
              placeholder="Select Vendor"
              options={prepareVendorOptions(vendorList)}
              register={register}
            />

            <Select
              name="status"
              label="Status"
              placeholder="All"
              options={statusOptionsForOrder}
              register={register}
            />
            <Select
              name="user_id"
              label="Customer"
              placeholder="All"
              options={prepareUsersOptions(usersList)}
              register={register}
            />
            <ControlledDatePicker
              name="startDate"
              label="Order Date"
              placeholder="Select Order Date"
              selectsRange
              control={control}
              maxDate={new Date()}
            />
            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isFilter: true, isClear: true });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      {role.includes("list") ? (
        <div className="cardcss">
          <div className="flex mb-3 gap-4 justify-between border-b-2 p-1 ">
            <div className="flex gap-2 pb-1">
              {deliveryTab?.map((tab, index) => {
                return (
                  <div
                    className={` px-6 h-9 flex items-center justify-center cursor-pointer tab-transition ${
                      currentTab !== tab?.label ? " " : "active"
                    }`}
                    key={index}
                    onClick={() => {
                      setFilter({
                        ...FILTER,
                      });
                      reset();
                      dispatch(setCurrTab(tab?.label));
                    }}
                  >
                    {tab?.label}
                  </div>
                );
              })}
            </div>
          </div>
          <Table
            columns={columns}
            isLoading={isLoading}
            data={tableData || []}
            setData={setTableData}
            count={
              currentTab === "Assigned"
                ? deliveredByList?.total_item
                : currentTab === "Pending"
                ? deliveredByPendingList?.total_item
                : 0
            }
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isFilter: false,
              });
            }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    item={item}
                    isExpendable={false}
                    onEyeClick={(item: any) => {
                      navigate(`${route}/${item?.id}`);
                    }}
                  ></TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
      <AssignDeliveryPerson
        isOpen={isAssignMdlOpen}
        deliveredPersonData={deliveredPersonData}
        onGet={getDeliveredByList}
        onHide={() => {
          setIsAssignMdlOpen(false);
          setDeliveredPersonData({});
        }}
      />
    </>
  );
};

export default DeliveredBy;
