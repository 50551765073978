import React, { memo } from "react";
import { IconProps } from ".";

export interface DeliveryPersonIconProps extends IconProps {}
const DeliveryPersonIcon = (props: DeliveryPersonIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
    className={`${className}`}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    {...rest}
  >
    <path
      d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`${pathClassNames?.[0] || ""}`}
    />
    <path
      d="M11.9999 11.0001C13.2867 11.0001 14.3299 9.95687 14.3299 8.67004C14.3299 7.38322 13.2867 6.34009 11.9999 6.34009C10.7131 6.34009 9.66992 7.38322 9.66992 8.67004C9.66992 9.95687 10.7131 11.0001 11.9999 11.0001Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`${pathClassNames?.[1] || ""}`}
    />
    <path
      d="M16 16.6601C16 14.8601 14.21 13.4001 12 13.4001C9.79 13.4001 8 14.8601 8 16.6601"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`${pathClassNames?.[2] || ""}`}
    />
  </svg>
  );
};

const MemoDeliveryPersonIcon = memo(DeliveryPersonIcon);
export default MemoDeliveryPersonIcon;
