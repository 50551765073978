import React, { Fragment, useEffect, useState } from "react";
import { Table, TableRow } from "../../ui";
import { FILTER } from "../../constant";
import { useGetPermissionListMutation } from "./permissionApi";
import { usePermissionList } from "./permissionSlice";
import { responseToaster } from "../../helperFunctions";
import { useNavigate } from "react-router-dom";
import { useRole } from "../auth/authSlice";

const Permission = () => {
  const [getPermissionList, { isLoading }] = useGetPermissionListMutation();
  const permissionList = usePermissionList();
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const navigate = useNavigate();
  const role = useRole("permission");

  if (!role.includes("list")) {
    navigate("/");
  }

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Name",
      name: "name",
      sortable: true,
      sortableKey: "name",
    },
    {
      title: "Guard Name",
      name: "guard_name",
      sortable: true,
      sortableKey: "guard_name",
    },
  ];

  const getPermission = async () => {
    try {
      const payload: any = {
        ...filter,
        filter_data: {
          vendor_id: filter?.vendor_id,
        },
      };

      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getPermissionList(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    getPermission();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setTableData(permissionList?.data?.length ? [...permissionList?.data] : []);
  }, [permissionList]);

  return (
    <>
      {role.includes("list") ? (
        <div className="cardcss">
          <Table
            columns={columns}
            className="permissionTable"
            isExpendable={false}
            isLoading={isLoading}
            data={tableData || []}
            setData={setTableData}
            count={permissionList?.total_item}
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isFilter: false,
              });
            }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    isExpendable={false}
                    item={item}
                    onClick={(item: any) => {}}
                  ></TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
    </>
  );
};

export default Permission;
