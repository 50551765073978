export const transactionStatus = [
  {
    value: "succeeded",
    label: "Succeeded",
  },
  {
    value: "failed",
    label: "Failed",
  },
  {
    value: "Timedout",
    label: "timedout",
  },
  {
    value: "Cancelled",
    label: "Cancelled",
  },
  {
    value: "processing",
    label: "Processing",
  },
];
