import { useMemo } from "react";

// PROJECT IMPORT
import { RootState } from "../../utills/store";
import { authApi } from "./authApi";

// THIRD - PARTY IMPORT
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../utills/hooks";

interface AuthState {
  user: any;
  verifyAuth: boolean;
  underMaintenance: boolean;
  authToken: string;
}

const initialState: AuthState = {
  user: {
    sidebar: [
      {
        name: "Dashboard",
        route: "/dashboard",
        icon_class: "DashboardIcon",
      },
      {
        name: "Master",
        route: "/master",
        icon_class: "MasterIcon",
        hasSubmenu: true,
        submenu: [
          {
            name: "Product",
            route: "product",
            icon_class: "ProductsIcon",
            parentRoute: "master",
            hasSubmenu: false,
          },
          {
            name: "Category",
            route: "category",
            icon_class: "CategoryIcon",
            parentRoute: "master",
            hasSubmenu: false,
          },

          {
            name: "Promotion",
            route: "promotion",
            icon_class: "PromotionIcon",
            parentRoute: "master",
            hasSubmenu: false,
          },
          {
            name: "Coupon",
            route: "coupon",
            icon_class: "CouponIcon",
            parentRoute: "master",
            hasSubmenu: false,
          },
          {
            name: "Tag",
            route: "tag",
            icon_class: "TagIcon",
            parentRoute: "master",
            hasSubmenu: false,
          },
          {
            name: "Variant",
            route: "variant",
            icon_class: "VariantIcon",
            parentRoute: "master",
            hasSubmenu: false,
          },
          {
            name: "Sub Category",
            route: "sub-category",
            icon_class: "SubCategoryIcon",
            parentRoute: "master",
            hasSubmenu: false,
          },
        ],
      },
      {
        name: "Vendor",
        route: "/vendor",
        icon_class: "VendorIcon",
        hasSubmenu: true,
        submenu: [
          {
            name: "Vendor List",
            route: "list",
            icon_class: "VendorListIcon",
            parentRoute: "vendor",
            hasSubmenu: false,
          },
          {
            name: "Vendor Plan",
            route: "plan",
            icon_class: "VendorPlanIcon",
            parentRoute: "vendor",
            hasSubmenu: false,
          },
        ],
      },
      {
        name: "Orders",
        route: "/order",
        icon_class: "OrdersIcon",
      },
      {
        name: "Customers",
        route: "/customers",
        icon_class: "UsersIcon",
      },
      {
        name: "Notification",
        route: "/notification",
        icon_class: "NotificationIcon",
      },
      {
        name: "Subscriptions",
        route: "/subscriptions",
        icon_class: "SubscriptionIcon",
        hasSubmenu: true,
        submenu: [
          {
            name: "Plan",
            route: "plan",
            icon_class: "PlanIcon",
            parentRoute: "subscriptions",
            hasSubmenu: false,
          },
          {
            name: "Transaction",
            route: "transaction",
            icon_class: "SubTransactionIcon",
            parentRoute: "subscriptions",
            hasSubmenu: false,
          },
        ],
      },
      {
        name: "Settlement",
        route: "/settlement",
        icon_class: "SettlementIcon",
      },
      {
        name: "Delivered",
        route: "/delivered",
        icon_class: "DeliveredIcon",
        hasSubmenu: true,
        submenu: [
          {
            name: "Delivered By",
            route: "deliveredby",
            icon_class: "DeliveredByIcon",
            parentRoute: "delivered",
            hasSubmenu: false,
          },
          {
            name: "Delivery Person",
            route: "delivery-person",
            icon_class: "DeliveryPersonIcon",
            parentRoute: "delivered",
            hasSubmenu: false,
          },
        ],
      },
      {
        name: "Support Ticket",
        route: "/support-ticket",
        icon_class: "TicketsIcon",
      },
      {
        name: "Video",
        route: "/video",
        icon_class: "VideoIcon",
      },
      {
        name: "Policy",
        route: "/policy",
        icon_class: "PolicyIcon",
      },
      {
        name: "Subscribe",
        route: "/subscribe",
        icon_class: "SubscribeIcon",
      },
      {
        name: "User",
        route: "/user",
        icon_class: "UsersIcon",
      },
      {
        name: "Role",
        route: "/role",
        icon_class: "RoleIcon",
      },
      {
        name: "Permission",
        route: "/permission",
        icon_class: "PermissionIcon",
      },
      {
        name: "Courier",
        route: "/courier",
        icon_class: "CourierIcon",
        hasSubmenu: true,
        submenu: [
          {
            name: "Courier Partner",
            route: "courier-partner",
            icon_class: "CourierPartnerIcon",
            parentRoute: "courier",
            hasSubmenu: false,
          },
        ],
      },
    ],
  },
  verifyAuth: false,
  underMaintenance: false,
  authToken: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth: (state) => {
      state.user = initialState?.user;
      state.verifyAuth = initialState?.verifyAuth;
      state.underMaintenance = initialState?.underMaintenance;
      state.authToken = initialState?.authToken;
    },
    verifyAuth: (state, { payload }) => {
      state.verifyAuth = payload;
    },
    setUnderMaintenance: (state, { payload }) => {
      state.underMaintenance = payload;
    },
    setAuthToken: (state, { payload }) => {
      state.authToken = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        // state.user = { ...payload, ...initialState?.user };
        state.user = { ...payload };
        // state.authToken = payload?.data?.access_token;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default authSlice.reducer;
export const { clearAuth, verifyAuth, setUnderMaintenance, setAuthToken } =
  authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const useUser = () => {
  const user = useSelector(selectUser);
  return useMemo(() => user, [user]);
};

export const verifyUser = (state: RootState) => state.auth.verifyAuth;
export const useVerifyUser = () => {
  const user = useSelector(verifyUser);
  return useMemo(() => user, [user]);
};

export const authToken = (state: RootState) => state.auth.authToken;
export const useAuthToken = () => {
  const user = useSelector(authToken);
  return useMemo(() => user, [user]);
};

export const underMaintenance = (state: RootState) =>
  state.auth.underMaintenance;
export const useUnderMaintenance = () => {
  const user = useSelector(underMaintenance);
  return useMemo(() => user, [user]);
};

export const selectRole = (state: RootState) => state.auth.user;
export const useRole = (page = "") => {
  const user = useAppSelector(selectRole);
  const role = user?.data?.permission
    ?.filter?.((permission: any) => {
      const parts = permission?.name?.split?.("-");
      return parts?.slice?.(0, parts.length - 1)?.join?.("-") === page;
    })
    .map((permission: { name: string }) => {
      const parts = permission?.name?.split?.("-");
      return parts.length > 1 ? parts[parts?.length - 1] : "";
    });
  return useMemo(() => role, [role]);
};
