import { Outlet, useLocation } from "react-router-dom";

// PROJECT IMPORT
import { Header, SideBar } from "../../features";
import { useIsDrawerOpen } from "../../features/common/commonSlice";
import { useMedia } from "../../components";
import ScrollToTop from "./ScrollTotop/ScrollToTop";
import { useUnderMaintenance, useUser, useVerifyUser } from "../auth/authSlice";
import AuthVerifyOtp from "../auth/AuthVerifyOtp";
import { useEffect, useState } from "react";
import UnderMaintenance from "../auth/UnderMaintenace";

const Layout = () => {
  const { media } = useMedia();
  const location = useLocation();
  const isDrawerOpen = useIsDrawerOpen();
  const verifyUser = useVerifyUser();
  const user = useUser();
  const underMainten = useUnderMaintenance();
  const [isVerify, setIsVerify] = useState(verifyUser);
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(verifyUser);

  useEffect(() => {
    setIsVerify(verifyUser);
    // eslint-disable-next-line
  }, [verifyUser]);

  useEffect(() => {
    setIsUnderMaintenance(underMainten);
    // eslint-disable-next-line
  }, [underMainten]);
  return (
    <>
      {isUnderMaintenance &&
      location?.pathname !== "/login" &&
      !user?.data?.admin?.is_admin ? (
        <UnderMaintenance isOpen={!!isUnderMaintenance} />
      ) : (
        <div className=" h-screen sm:ml-0 sm:my-0 sm:h-[calc(100vh-0px)] overflow-y-scroll  main relative">
          <SideBar />
          <div
            className={`${
              isDrawerOpen
                ? media.width < 768
                  ? "pl-[0px]"
                  : "pl-[220px]"
                : media.width < 768
                ? "pl-[0px]"
                : "pl-[74px]"
            } 
        ${media.width < 768 ? "pr-[0px] pl-[0px]" : "pr-[0px]"} 
         mainAdmin`}
          >
            <Header />
            <div className="p-6">
              {verifyUser ? (
                <AuthVerifyOtp
                  isOpen={isVerify}
                  onHide={() => {
                    setIsVerify(false);
                  }}
                />
              ) : null}
              <Outlet />
            </div>
            <ScrollToTop />
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
