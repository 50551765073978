import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { roleApi } from "./roleApi";

interface RoleState {
  roleList: any;
  roleVendor: any;
  roleListData: any;
  roleData: any;
  productRole: any;
}

const initialState: RoleState = {
  roleList: {},
  roleVendor: {},
  roleListData: {},
  roleData: {},
  productRole: {},
};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    clearRole: () => initialState,
    setRole: (state, { payload }) => {
      state.roleList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      roleApi.endpoints.getRole.matchFulfilled,
      (state, action: any) => {
        state.roleList = action?.payload;
      }
    );
    builder.addMatcher(
      roleApi.endpoints.addRole.matchFulfilled,
      (state, { payload }) => {
        state.roleData = payload || {};
      }
    );
    builder.addMatcher(
      roleApi.endpoints.getRoleList.matchFulfilled,
      (state, { payload }) => {
        state.roleListData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default roleSlice.reducer;
export const { clearRole, setRole } = roleSlice.actions;

export const selectRoleList = (state: RootState) => state.role.roleList;
export const useRoleList = () => {
  const roleList = useSelector(selectRoleList);
  return useMemo(() => roleList, [roleList]);
};

export const selectVendorRoleList = (state: RootState) => state.role.roleVendor;
export const useVendorRoleList = () => {
  const roleVendorList = useSelector(selectVendorRoleList);
  return useMemo(() => roleVendorList, [roleVendorList]);
};

export const selectRoleListData = (state: RootState) => state.role.roleListData;
export const useRoleListData = () => {
  const roleListData = useSelector(selectRoleListData);
  return useMemo(() => roleListData, [roleListData]);
};

export const selectRoleData = (state: RootState) => state.role.roleData;
export const useRoleData = () => {
  const roleData = useSelector(selectRoleData);
  return useMemo(() => roleData, [roleData]);
};
