import React from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../helperFunctions";
import { useAppDispatch } from "../../utills/hooks";

const HeaderModal = (props: any) => {
  const { isOpen, onClose } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-[100] flex items-center justify-end">
        <div
          className="modal-overlay absolute w-full h-full bg-gray-900 opacity-0"
          onClick={() => {
            onClose();
          }}
        />

        <div className="modal-container bg-white min-w-[200px] md:max-w-md rounded-lg shadow-lg absolute top-20 right-10 sm:top-14  z-50 overflow-y-auto">
          <div className="modal-content py-2 text-left ">
            <div className="px-6">
              <div className="my-2">
                <h3
                  className="text-sm font-bold mb-2 text-[#6f6af8]"
                  onClick={() => {
                    navigate("/setting/detail");
                    onClose();
                  }}
                >
                  Setting
                </h3>
                <h3
                  className="text-sm font-bold mb-2 text-[#6f6af8]"
                  onClick={async () => {
                    try {
                      await logout(dispatch);
                      navigate("/");
                    } catch (err) {
                      console.error("Logout Error =-=>", err);
                    }
                  }}
                >
                  Log Out
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderModal;
