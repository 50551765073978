import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { transactionApi } from "./transactionApi";

interface UserState {
  subTransactionList: any;
}

const initialState: UserState = {
  subTransactionList: {},
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    clearTransaction: () => initialState,
    setTransaction: (state, { payload }) => {
      state.subTransactionList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      transactionApi.endpoints.getTransaction.matchFulfilled,
      (state, { payload }) => {
        state.subTransactionList = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default transactionSlice.reducer;
export const { clearTransaction, setTransaction } = transactionSlice.actions;

export const selectTransactionList = (state: RootState) =>
  state.transaction.subTransactionList;
export const useTransactionList = () => {
  const transactionList = useSelector(selectTransactionList);
  return useMemo(() => transactionList, [transactionList]);
};
