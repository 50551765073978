// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import Picker, { PickerProps } from "./Picker";

interface ControlledPickerProps extends PickerProps {
  control: any;
}

const ControlledPicker = (props: ControlledPickerProps) => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...restt } }) => {
        return <Picker {...restt} {...rest} />;
      }}
    />
  );
};

export default ControlledPicker;