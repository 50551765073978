import React, { memo } from "react";
import { IconProps } from ".";

export interface SubscriptionIconProps extends IconProps {}
const SubscriptionIcon = (props: SubscriptionIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M19.9702 10H3.97021V18C3.97021 21 4.97021 22 7.97021 22H15.9702C18.9702 22 19.9702 21 19.9702 18V10Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[0] || ""}`}
      />
      <path
        d="M21.5 7V8C21.5 9.1 20.97 10 19.5 10H4.5C2.97 10 2.5 9.1 2.5 8V7C2.5 5.9 2.97 5 4.5 5H19.5C20.97 5 21.5 5.9 21.5 7Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[1] || ""}`}
      />
      <path
        d="M11.6398 5H6.11978C5.77978 4.63 5.78978 4.06 6.14978 3.7L7.56978 2.28C7.93978 1.91 8.54978 1.91 8.91978 2.28L11.6398 5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
         className={`${pathClassNames?.[2] || ""}`}
      />
      <path
        d="M17.8701 5H12.3501L15.0701 2.28C15.4401 1.91 16.0501 1.91 16.4201 2.28L17.8401 3.7C18.2001 4.06 18.2101 4.63 17.8701 5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
         className={`${pathClassNames?.[3] || ""}`}
      />
      <path
        d="M8.93994 10V15.14C8.93994 15.94 9.81994 16.41 10.4899 15.98L11.4299 15.36C11.7699 15.14 12.1999 15.14 12.5299 15.36L13.4199 15.96C14.0799 16.4 14.9699 15.93 14.9699 15.13V10H8.93994Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
         className={`${pathClassNames?.[4] || ""}`}
      />
    </svg>
  );
};

const MemoSubscriptionIcon = memo(SubscriptionIcon);
export default MemoSubscriptionIcon;
