import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { tagApi } from "./tagApi";

interface UserState {
  tagList: any;
  tagVendor: any;
  tagListData: any;
  tagData: any;
  currTab: any;
  productTag: any;
}

const initialState: UserState = {
  tagList: {},
  tagVendor: {},
  tagListData: {},
  tagData: {},
  productTag: {},
  currTab: "Admin",
};

export const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    clearTag: () => initialState,
    setTag: (state, { payload }) => {
      state.tagList = payload;
    },
    setCurrTab: (state, { payload }) => {
      state.currTab = payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      tagApi.endpoints.getTag.matchFulfilled,
      (state, action: any) => {
        if (action?.meta?.arg?.originalArgs?.filter_data?.is_admin === 1) {
          state.tagList = action?.payload;
        } else {
          state.tagVendor = action?.payload;
        }
      }
    );
    builder.addMatcher(
      tagApi.endpoints.addTag.matchFulfilled,
      (state, { payload }) => {
        state.tagData = payload || {};
      }
    );
    builder.addMatcher(
      tagApi.endpoints.getTagList.matchFulfilled,
      (state, { payload }) => {
        state.tagListData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default tagSlice.reducer;
export const { clearTag, setTag, setCurrTab } = tagSlice.actions;

export const selectTagList = (state: RootState) => state.tag.tagList;
export const useTagList = () => {
  const tagList = useSelector(selectTagList);
  return useMemo(() => tagList, [tagList]);
};
export const selectVendorTagList = (state: RootState) => state.tag.tagVendor;
export const useVendorTagList = () => {
  const tagVendorList = useSelector(selectVendorTagList);
  return useMemo(() => tagVendorList, [tagVendorList]);
};

export const selectTagListData = (state: RootState) => state.tag.tagListData;
export const useTagListData = () => {
  const tagListData = useSelector(selectTagListData);
  return useMemo(() => tagListData, [tagListData]);
};

export const selectTagData = (state: RootState) => state.tag.tagData;
export const useTagData = () => {
  const tagData = useSelector(selectTagData);
  return useMemo(() => tagData, [tagData]);
};

export const selectCurrentTab = (state: RootState) => state.tag.currTab;
export const useCurrentTab = () => {
  const currentTab = useSelector(selectCurrentTab || "Admin");
  return useMemo(() => currentTab, [currentTab]);
};
