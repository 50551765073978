import React, { memo } from "react";
import { IconProps } from ".";

export interface DownArrowIconProps extends IconProps {}
const DownArrowIcon = (props: DownArrowIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="m6 9 6 6 6-6" className={`${pathClassNames?.[0] || ""}`} />
    </svg>
  );
};

const MemoDownArrowIcon = memo(DownArrowIcon);
export default MemoDownArrowIcon;
