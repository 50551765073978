import { InputTypes } from "../theme";
import { IconKeys } from "../icons";

export interface TextAreaProps {
  className?: string;
  inputClassName?: string;
  lableClassName?: string;
  name: string;
  label?: string;
  value?: any;
  onChange?: (e?: any) => void;
  righticonClick?: (e?: any) => void;
  type?: InputTypes;
  icon?: IconKeys;
  rightIcon?: IconKeys | null;
  placeholder?: string;
  errors?: any;
  err?: any;
  register?: any;
  disabled?: boolean;
  min?: number;
  autoComplete?: any;
  max?: number;
  spellCheck?: boolean;
}

const TextArea = (props: TextAreaProps) => {
  const {
    className = "",
    lableClassName = "",
    label,
    icon,
    rightIcon,
    righticonClick,
    errors,
    err,
    register,
    name,
    min,
    max,
    type,
    spellCheck = false,
    ...rest
  } = props;

  const message = errors?.[name]?.message || err || "";

  return (
    <div
      className={`${className} w-full flex flex-col justify-center relative`}
    >
      {label ? (
        <label className={`${lableClassName} text-sm text-ev-secondary`}>
          {label}
        </label>
      ) : null}
      <div className="flex flex-col justify-center">
        <textarea
          spellCheck={spellCheck}
          className={`p-2 w-full bg-chatlook-gray placeholder-[#9ba1af] text-xs h-9 rounded outline-none border-[1px] border-[#EFF0F6] ${
            message ? "border-red-500" : "focus:border-chatlook-primary"
          }`}
          placeholder="Enter Description"
          {...rest}
        />
      </div>
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default TextArea;
