import { PropsWithChildren, useState } from "react";
import { ButtonTypes, ButtonVariants, getButtonVariants } from "../theme";
import Loader from "./Loader";

interface ButtonProps extends PropsWithChildren<{}> {
  className?: string;
  type?: ButtonTypes;
  variant?: ButtonVariants;
  href?: any;
  title?: string;
  download?: string;
  target?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isLoader?: boolean;
  onClick?: any;
  color?: string;
  style?: any;
  classNm?: any;
}

const Button = (props: ButtonProps) => {
  const {
    className,
    type = "button",
    download,
    variant = "Primary",
    href,
    disabled,
    isLoading = false,
    isLoader,
    children,
    color,
    onClick,
    classNm,
    ...rest
  } = props;

  const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false);

  const buttonClassName = getButtonVariants(variant);

  const prepareIsDisabled = () => disabled || isLoading || isLocalLoading;
  return (
    <>
      {href ? (
        <a
          href={href}
          className={`${className} ${buttonClassName} ${
            !disabled ? "appl-btn" : "px-4"
          } rounded-md h-9 flex items-center justify-center whitespace-nowrap`}
          onClick={onClick}
          {...rest}
        >
          {children}
        </a>
      ) : (
        <button
          type={type}
          color="#2B80F7"
          disabled={prepareIsDisabled()}
          className={`${className} ${buttonClassName} ${
            disabled ? "bg-opacity-60" : ""
          } ${
            !disabled
              ? `appl-btn ${classNm}`
              : "px-4 !bg-[#dadada88] !text-black !font-bold clear-btn"
          } rounded-md h-9 flex items-center justify-center whitespace-nowrap`}
          onClick={async (e) => {
            if (isLoader) {
              setIsLocalLoading(true);
            }
            await onClick?.(e);

            if (isLoader) {
              setIsLocalLoading(false);
            }
          }}
          {...rest}
        >
          {isLoading || isLocalLoading ? (
            isLoader ? (
              <Loader loaderClassName="create-edit-bonus" fill="fill-white" />
            ) : (
              "Processing . . . ."
            )
          ) : (
            children
          )}
        </button>
      )}
    </>
  );
};

export default Button;
