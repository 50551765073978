import Select from "react-select";
import {
  DROPDOWN_ALL_LABEL,
  DROPDOWN_ALL_VALUE,
  DROPDOWN_SEARCH_LABEL,
  DROPDOWN_SEARCH_VALUE,
  DROPDOWN_SELECT_LABEL,
  DROPDOWN_SELECT_VALUE,
} from "../../constant";

const defaultOptionTypes = {
  [DROPDOWN_ALL_VALUE]: {
    value: DROPDOWN_ALL_VALUE,
    label: DROPDOWN_ALL_LABEL,
  },
  Select: {
    value: DROPDOWN_SELECT_VALUE,
    label: DROPDOWN_SELECT_LABEL,
  },
  [DROPDOWN_SEARCH_VALUE]: {
    value: DROPDOWN_SEARCH_VALUE,
    label: DROPDOWN_SEARCH_LABEL,
  },
};

const DropDown = (props) => {
  const {
    defaultValue,
    options = [],
    name,
    className = "",
    inputClassName = "",
    label,
    value,
    styles = {},
    isMulti,
    onChange,
    errors,
    width,
    placeholder,
    isClearable = false,
    isDisabled = false,
    isDefaultOption = "Select",
    ...rest
  } = props;
  const message = errors?.[name]?.message ? errors?.[name]?.message : "";
  const prepareDefaultOptions = () => {
    return defaultOptionTypes?.[isDefaultOption] || defaultOptionTypes?.Select;
  };

  const prepareOptions = () => {
    return !!isDefaultOption ? [prepareDefaultOptions(), ...options] : options;
  };

  return (
    <div
      className={`${className} select-div w-full flex flex-col justify-center relative`}
    >
      {label ? (
        <label className={`text-sm text-ev-secondary`}>{label}</label>
      ) : null}
      <Select
        name={name}
        isDisabled={isDisabled}
        menuPosition="fixed"
        styles={{
          control: (provided) => ({
            ...provided,
            width: "width",
            minHeight: "36px",
            height: "36px",
            borderRadius: "6px",
            // borderColor:"none",
            borderColor: message ? "#ef4444" : "none",
            background: "var(--chatlook-gray--)",
            // color: "#fff",
            fontSize: 12,
            outline: "none",
            // opacity: isDisabled ? 0.5 : 1,
            // zIndex: 99999,
          }),
          valueContainer: (provided) => ({
            ...provided,
            overflowX: "scroll",
            flexWrap: "nowrap",
            marginRight: "0px",
            fontSize: 12,
          }),
          multiValue: (provided) => ({
            ...provided,
            minWidth: "unset",
            // background: "var(--chatlook-white--)",
          }),
          MultiValueRemove: (provided) => ({
            ...provided,
            color: "var(--chatlook-gray--)",
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: "var(--ev-EerieBlack--)",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            background: "none",
          }),
          menu: (style) => ({
            ...style,
            zIndex: 999999,
            borderRadius: "10px",
            width: "fit-content",
          }),
          menuList: (style) => ({
            ...style,
            // zIndex: 99999,
            padding: "9px",
            background: "var(--chatlook-white--)",
            border: "1px solid white",
            borderRadius: "10px",
          }),
          input: (style) => ({
            ...style,
            fontSize: 14,
            color: "var(--ev-Tertiary--)",
          }),
          singleValue: (style) => ({
            ...style,
            fontSize: 14,
            color: "#6e6b7b",
          }),
          placeholder: (style) => ({
            ...style,
            fontSize: 12,
            color: "#000",
          }),
          option: (style) => ({
            ...style,
            padding: "2px 5px",
            fontSize: 13,
            whiteSpace: "nowrap",
            width: "100%",
            // color: "var(--ev-Tertiary--)",
            // backgroundColor: "var(--chatlook-white--)",
          }),
          menuPortal: (style) => ({
            ...style,
            zIndex: 9999999,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: "0 5px",
          }),
          container: (style) => ({
            ...style,
            marginRight: 0,
          }),
          ...styles,
        }}
        onChange={(e, option) => {
          if (isMulti) {
            onChange?.(
              e?.map((item) => item?.value),
              option,
              e
            );
          } else {
            onChange?.(e.value, option, e);
          }
        }}
        className={`${inputClassName} w-100`}
        options={prepareOptions()}
        isMulti={isMulti}
        isClearable={isClearable}
        value={
          isMulti
            ? options?.filter((option) => value?.includes(option?.value))
            : options?.find((option) => option?.value === value) || ""
        }
        defaultValue={options?.[0]?.label}
        placeholder={placeholder}
        {...rest}
      />
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default DropDown;
