import { CSSProperties } from "react";

// UI IMPORT
import * as iconComponents from "./icons";
import * as imageComponents from "./images";

export {iconComponents,imageComponents};
export type IconKeys = keyof typeof iconComponents;
export type ImageKeys = keyof typeof imageComponents;

export { default as Icon } from "./Icon";
export { default as Image } from "./Image";

export interface IconProps {
  className?: string;
  pathClassNames?: any;
  name?: IconKeys;
  onClick?: (e?: any) => void;
  size?: number;
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
}

export interface ImageProps {
  className?: string;
  alt?: string;
  style?: CSSProperties;
  name: ImageKeys;
  src?:string;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  width?: number;
  fill?: string;
  height?: number;
  size?: number;
}
