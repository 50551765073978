import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { categoryApi } from "./categoryApi";

interface UserState {
  categoryList: any;
  categoryUserList: any;
  categoryData: any;
}

const initialState: UserState = {
  categoryList: {},
  categoryUserList: {},
  categoryData: {},
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    clearCategory: () => initialState,
    setCategory: (state, { payload }) => {
      state.categoryList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      categoryApi.endpoints.getCategory.matchFulfilled,
      (state, { payload }) => {
        state.categoryList = payload || {};
      }
    );
    builder.addMatcher(
      categoryApi.endpoints.addCategory.matchFulfilled,
      (state, { payload }) => {
        state.categoryData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default categorySlice.reducer;
export const { clearCategory, setCategory } = categorySlice.actions;

export const selectCategoryList = (state: RootState) =>
  state.category.categoryList;
export const useCategoryList = () => {
  const categoryList = useSelector(selectCategoryList);
  return useMemo(() => categoryList, [categoryList]);
};

export const selectCategoryUserList = (state: RootState) =>
  state.category.categoryUserList;
export const useCategoryUserList = () => {
  const categoryUserList = useSelector(selectCategoryUserList);
  return useMemo(() => categoryUserList, [categoryUserList]);
};

export const selectCategoryData = (state: RootState) =>
  state.category.categoryData;
export const useCategoryData = () => {
  const categoryData = useSelector(selectCategoryData);
  return useMemo(() => categoryData, [categoryData]);
};
