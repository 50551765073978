import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form } from "reactstrap";
import { Button, ControlledDropDown } from "../../ui";
import { useImportProductMutation } from "./productApi";
import {  useVendorList } from "./productSlice";
import { RiUploadCloud2Line } from "react-icons/ri";
import {
  prepareVendorOptions,
  responseToaster,
} from "../../helperFunctions";

const BulkUploadModal = (props: any) => {
  const { onHide, isOpen, currentTab, onGet } = props;
  const [addBULoading, setaddBULoading] = useState<boolean>(false);
  const [uploadFile, setUploadFile] = useState<any>(null);
  const [importProduct] = useImportProductMutation();
  const vendorList = useVendorList();

  const schema: any = yup.object({
    vendor_id: yup.string().required("Please select Vendor"),
    // category_id: yup.string().required("Please select main category"),
    file: yup.string().required("Please add a file"),
  });

  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    reset();
    onHide();
    setUploadFile(null);
  };

  if (!isOpen) return null;
  const onSubmit = async (values: any) => {
    try {
      setaddBULoading(true);
      const formData: any = new FormData();
      formData.append("vendor_id", values?.vendor_id);
      // formData.append("category_id", values?.category_id);
      formData.append("file", uploadFile);
      formData.append("is_admin", currentTab === "Admin" ? 1 : 0);

      const res: any = await importProduct(formData);
      if (res?.data?.status) {
        onClose?.();
        onGet?.();
      }
      responseToaster(res?.data);
      setaddBULoading(false);
    } catch (error) {
      setaddBULoading(false);
      console.log("error", error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addBULoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-6/12 xl:w-6/12 lg:w-5/12  md:w-7/12 sm:w-10/12  rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-9 px-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                Bulk Upload
              </h4>
            </div>
            <div className="modal-body">
              <div className="grid grid-cols-1 items-start gap-3 pt-2 pb-3">
                <ControlledDropDown
                  name="vendor_id"
                  label="Vendor"
                  placeholder="Select Vendor"
                  options={prepareVendorOptions(vendorList)}
                  control={control}
                  errors={errors}
                  onChange={(e: any) => {
                    setValue("vendor_id", e);
                    // setValue("category_id", null);
                  }}
                />
                {/* <ControlledDropDown
                  name="category_id"
                  label="Main Category"
                  isDisabled={!watch("vendor_id")}
                  placeholder="Select Main Category"
                  options={prepareMainCategoryOptions(
                    vendorList,
                    watch("vendor_id"),
                    categories
                  )}
                  control={control}
                  errors={errors}
                /> */}
                <div className="mt-1 col-span-1">
                  <label className="text-sm text-ev-secondary">
                    Upload File
                  </label>
                  <div
                    className={`${
                      errors?.file?.message ? "border-red-500" : ""
                    } pload-file-container border-[1px] bg-chatlook-gray p-3 rounded proof-sec text-center cursor-pointer`}
                  >
                    <label className="label cursor-pointer">
                      <input
                        id="excelFile"
                        type="file"
                        accept=".csv"
                        className="visually-hidden"
                        onChange={(e: any) => {
                          setValue("file", e?.target?.files[0]);
                          setUploadFile(e?.target?.files[0]);
                          if (e?.target?.files[0]) {
                            clearErrors("file");
                          }
                        }}
                      />
                      <p>
                        <RiUploadCloud2Line
                          size={25}
                          className="m-auto"
                          style={{ color: "#ffb24a" }}
                        />
                        <span
                          className={`${
                            watch("file")?.name ? "themecolor" : ""
                          }`}
                        >
                          {watch("file")?.name || "Upload File"}
                        </span>
                      </p>
                    </label>
                  </div>
                  {errors?.file?.message ? (
                    <span className="text-red-500 text-sm">
                      {errors?.file?.message}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="modal-footer pb-1">
              <div className="flex items-center justify-end gap-2">
                <Button
                  type="submit"
                  className="mt-[10px] !h-9 !text-sm"
                  isLoading={addBULoading}
                  isLoader={addBULoading}
                >
                  Submit
                </Button>
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={addBULoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BulkUploadModal;
