import { api } from "../../utills/api";

export const subscribeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSubscribe: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/subscribe/get-subscribe-list",
        body: params,
        method: "POST",
      }),
    }),
    deleteSubscribe: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/subscribe/delete-subscribe",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetSubscribeMutation, useDeleteSubscribeMutation } =
  subscribeApi;
export const {
  endpoints: { getSubscribe, deleteSubscribe },
} = subscribeApi;
