import React, { Fragment, useEffect, useState } from "react";
import { Button, Icon, Select, Table, TableRow } from "../../ui";
import { FILTER, statusOptions } from "../../constant";
import {
  useDeletePromotionMutation,
  useGetPromotionMutation,
  useUpdatePromotionMutation,
} from "./promotionApi";
import {
  setCurrTab,
  setPromotion,
  useCurrentTab,
  usePromotionList,
  useVendorpromotionList,
} from "./promotionSlice";
import PromotionModal from "./PromotionModal";
import { MdEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "../common";
import Switch from "../../ui/forms/Switch";
import {
  prepareImageUrl,
  prepareVendorOptions,
  responseToaster,
} from "../../helperFunctions";
import { useDispatch } from "react-redux";
import Tooltip from "../../ui/elements/Tooltip";
import { useVendorList } from "../vendor/vendorList/vendorSlice";
import { useForm } from "react-hook-form";
import { IoEye } from "react-icons/io5";
import { useGetVendorMutation } from "../vendor/vendorList/vendorApi";
import CustomeBreadcrumbs from "../common/CustomeBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useRole } from "../auth/authSlice";

interface PromotionTabs {
  label: string;
  Component?: any;
}

const Promotion = () => {
  const [getPromotion, { isLoading }] = useGetPromotionMutation();
  const promotionList = usePromotionList();
  const couponPromotionList = useVendorpromotionList();
  const vendorList = useVendorList();
  const currentTab = useCurrentTab();
  const [getVendorList] = useGetVendorMutation();
  const [updatePromotion] = useUpdatePromotionMutation();

  const [deletePromotion, { isLoading: isDeleteLoading }] =
    useDeletePromotionMutation();
  const [isPromotionModal, setIsPromotionModal] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();
  const [promotionData, setPromotionData] = useState({});
  const [statusLoading, setStatusLoading] = useState(false);
  const navigate = useNavigate();
  const role = useRole("promotion");

  if (!role.includes("list")) {
    navigate("/");
  }
  const breadcrumbs = [
    { label: "Master" },
    { label: "Promotion" },
    { label: currentTab },
  ];

  const promotionTab: PromotionTabs[] = [
    { label: "Admin", Component: Promotion },
    { label: "Vendor", Component: Promotion },
  ];
  const dispatch = useDispatch();

  const handleAddPromotion = () => {
    setIsPromotionModal(true);
  };

  useEffect(() => {
    if (role.includes("list")) {
      getVendorList();
    }
    // eslint-disable-next-line
  }, []);

  const onUpdateStatus = async (payload: any) => {
    try {
      setStatusLoading(payload?.id);
      const res = await updatePromotion(payload).unwrap();

      const index = tableData?.findIndex(
        (item: { id: any }) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...tableData];
        newData[index] = {
          ...newData[index],
          status: payload?.status,
        };
        dispatch(setPromotion({ ...promotionList, data: newData, currentTab }));
      }
      responseToaster(res);
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Image",
      name: "photo",
      Cell: (data: any) => {
        return (
          <span>
            <img
              src={prepareImageUrl(data?.photo)?.url}
              alt="img"
              width={40}
              height={40}
            />
          </span>
        );
      },
    },
    {
      title: "Vendor",
      name: "vendor_name",
      sortable: true,
      showColumn: currentTab === "Vendor",
      sortableKey: "vendor.name",
      Cell: (data: any) => {
        return <span>{data?.vendor?.name || "-"}</span>;
      },
    },
    {
      title: "Status",
      name: "status",
      sortable: true,
      sortableKey: "status",
      Cell: (data: any) => (
        <Switch
          checked={data?.status === "active"}
          isLoading={statusLoading === data?.id}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                status: value ? "active" : "inactive",
                id: data?.id,
              };
              await onUpdateStatus(payload);
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Action",
      name: "action",
      Cell: (data: any) => (
        <>
          <div className="flex gap-2">
            {currentTab !== "Vendor" ? (
              <Tooltip text="Edit">
                <Button
                  className="!h-8 !w-8 !p-0"
                  onClick={() => {
                    setIsPromotionModal(true);
                    setPromotionData(data);
                  }}
                  disabled={!role.includes("edit")}
                >
                  <MdEdit />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip text="Preview">
                <Button
                  className="!h-8 !w-8 !p-0"
                  onClick={() => {
                    setIsPromotionModal(true);
                    setPromotionData(data);
                  }}
                >
                  <IoEye />
                </Button>
              </Tooltip>
            )}
            <Tooltip text="Delete">
              <Button
                className="!h-8 !w-8 !p-0"
                disabled={!role.includes("delete")}
                onClick={() => {
                  setDeleteData(data);
                  setIsDeleteModalOpen(true);
                }}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const onPromotionDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deletePromotion(payload).unwrap();
      if (res) {
        responseToaster(res);
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getPromotionList = async () => {
    try {
      const { vendor_id = 0, status, ...rest } = filter;
      const payload: any = {
        ...rest,
        filter_data: {
          status,
        },
      };
      if (currentTab !== "Vendor") {
        payload.filter_data.vendor_id = 0;
      } else if (currentTab === "Vendor" && vendor_id) {
        payload.filter_data.vendor_id = vendor_id;
      }
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getPromotion(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const { register, handleSubmit, reset } = useForm<any>();

  useEffect(() => {
    if (role.includes("list")) {
      getPromotionList();
    }
    // eslint-disable-next-line
  }, [filter, currentTab]);

  useEffect(() => {
    if (currentTab === "Admin") {
      setTableData(promotionList?.data?.length ? [...promotionList?.data] : []);
    } else {
      setTableData(
        couponPromotionList?.data?.length ? [...couponPromotionList?.data] : []
      );
    }
  }, [promotionList, couponPromotionList, currentTab]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div className="">
        <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
          <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
          {role.includes("create") ? (
            <div className="flex gap-2 justify-between">
              <Button
                classNm={`!text-ev-white !border-0 appl-btn1`}
                onClick={() => {
                  setShowDiv(!showDiv);
                }}
                className={`${
                  !showDiv ? "!bg-chatlook-Cyan" : "!bg-[#22a99c]"
                }`}
              >
                <Icon name="FilterIcon" />
              </Button>
              {currentTab !== "Vendor" ? (
                <div className="flex justify-end">
                  <Button onClick={() => handleAddPromotion()}>
                    Add Promotion
                  </Button>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        {showDiv ? (
          <div className="cardcss flex justify-between items-end mb-3">
            <form
              className="flex gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              {currentTab === "Vendor" ? (
                <Select
                  name="vendor_id"
                  label="Vendor"
                  placeholder="Select Vendor"
                  options={prepareVendorOptions(vendorList)}
                  register={register}
                />
              ) : null}
              <Select
                name="status"
                label="Status"
                // className="w-1/5"
                placeholder="All"
                options={statusOptions}
                register={register}
              />
              <Button type="submit" className="!text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm"
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true, isClear: true });
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        ) : null}
        {role.includes("list") ? (
          <div className="cardcss">
            <div className="flex mb-3 gap-4 justify-between border-b-2 p-1 ">
              <div className="flex gap-2 pb-1">
                {promotionTab?.map((tab, index) => {
                  return (
                    <div
                      key={index}
                      className={` px-6 h-9 flex items-center justify-center cursor-pointer tab-transition ${
                        currentTab !== tab?.label ? " " : "active"
                      }`}
                      onClick={() => {
                        setFilter({
                          ...FILTER,
                        });
                        reset();
                        dispatch(setCurrTab(tab?.label));
                      }}
                    >
                      {tab?.label}
                    </div>
                  );
                })}
              </div>
            </div>
            <Table
              columns={columns}
              isExpendable={false}
              isLoading={isLoading}
              data={tableData || []}
              setData={setTableData}
              count={
                currentTab === "Admin"
                  ? promotionList?.total_item
                  : currentTab === "Vendor"
                  ? couponPromotionList?.total_item
                  : 0
              }
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        ) : null}
      </div>
      <PromotionModal
        currentTab={currentTab}
        isOpen={isPromotionModal}
        onGet={getPromotionList}
        promotionData={promotionData}
        onHide={() => {
          setIsPromotionModal(false);
          setPromotionData({});
        }}
      />
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onPromotionDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default Promotion;
