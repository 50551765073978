import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { settlementApi } from "./settlementApi";

interface UserState {
  settlementList: any;
  currentSettlement: any | null;
  vendorSettlement: any;
  transationList: any;
  currTab: any;
}

const initialState: UserState = {
  settlementList: {},
  currentSettlement: null,
  vendorSettlement: {},
  transationList: {},
  currTab: 0,
};

export const settlementSlice = createSlice({
  name: "settlement",
  initialState,
  reducers: {
    clearSettlement: () => initialState,
    setCurrentSettlement: (state, { payload }) => {
      state.currentSettlement = payload || null;
    },
    setSettlement: (state, { payload }) => {
      state.settlementList = payload;
    },
    setCurrTab: (state, { payload }) => {
      state.currTab = payload || 0;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      settlementApi.endpoints.getSettlement.matchFulfilled,
      (state, { payload }) => {
        state.settlementList = payload || {};
      }
    );
    builder.addMatcher(
      settlementApi.endpoints.vendorSettlement.matchFulfilled,
      (state, { payload }) => {
        state.vendorSettlement = payload || {};
      }
    );
    builder.addMatcher(
      settlementApi.endpoints.getTransation.matchFulfilled,
      (state, { payload }) => {
        state.transationList = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default settlementSlice.reducer;
export const {
  clearSettlement,
  setCurrentSettlement,
  setSettlement,
  setCurrTab,
} = settlementSlice.actions;

export const selectSettlementList = (state: RootState) =>
  state.settlement.settlementList;
export const useSettlementList = () => {
  const settlementList = useSelector(selectSettlementList);
  return useMemo(() => settlementList, [settlementList]);
};

export const selectCurrentSettlement = (state: RootState) =>
  state.settlement.currentSettlement;
export const useCurrentSettlement = () => {
  const currentSettlement = useSelector(selectCurrentSettlement);
  return useMemo(() => currentSettlement, [currentSettlement]);
};

export const selectVendorSettlement = (state: RootState) =>
  state.settlement.vendorSettlement;
export const useVendorSettlement = () => {
  const vendorSettlementData = useSelector(selectVendorSettlement);
  return useMemo(() => vendorSettlementData, [vendorSettlementData]);
};

export const selectTransationList = (state: RootState) =>
  state.settlement.transationList;
export const useTransactionList = () => {
  const transactionData = useSelector(selectTransationList);
  return useMemo(() => transactionData, [transactionData]);
};

export const selectCurrentTab = (state: RootState) => state.settlement.currTab;
export const useCurrentTab = () => {
  const currentTab = useSelector(selectCurrentTab);
  return useMemo(() => currentTab, [currentTab]);
};
