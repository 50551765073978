import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { ratingReviewApi } from "./ratingReviewApi";

interface RatingReviewState {
  ratingReviewList: any;
  ratingShop: any;
  currTab: any;
}

const initialState: RatingReviewState = {
  ratingReviewList: {},
  ratingShop: {},
  currTab: "Product",
};

export const ratingReviewSlice = createSlice({
  name: "ratingReview",
  initialState,
  reducers: {
    clearRatingReview: () => initialState,
    setRatingReview: (state, action) => {
      if (action?.payload?.currentTab === "Product") {
        state.ratingReviewList = action?.payload;
      } else {
        state.ratingShop = action?.payload;
      }
    },
    setCurrTab: (state, { payload }) => {
      state.currTab = payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ratingReviewApi.endpoints.getRatingReview.matchFulfilled,
      (state, action: any) => {
        if (
          action?.meta?.arg?.originalArgs?.filter_data?.product_id === "All"
        ) {
          state.ratingReviewList = action?.payload;
        } else {
          state.ratingShop = action?.payload;
        }
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default ratingReviewSlice.reducer;
export const { clearRatingReview, setRatingReview, setCurrTab } =
  ratingReviewSlice.actions;

export const selectRatingReviewList = (state: RootState) =>
  state.ratingReview.ratingReviewList;
export const useRatingReviewList = () => {
  const ratingReviewList = useSelector(selectRatingReviewList);
  return useMemo(() => ratingReviewList, [ratingReviewList]);
};

export const selectRatingReviewListShop = (state: RootState) =>
  state.ratingReview.ratingShop;
export const useRatingReviewListShop = () => {
  const ratingReviewListShop = useSelector(selectRatingReviewListShop);
  return useMemo(() => ratingReviewListShop, [ratingReviewListShop]);
};

export const selectCurrentTab = (state: RootState) =>
  state.ratingReview.currTab;
export const useCurrentTab = () => {
  const currentTab = useSelector(selectCurrentTab);
  return useMemo(() => currentTab, [currentTab]);
};
