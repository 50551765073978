import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { subCategoryApi } from "./subCategoryApi";

interface UserState {
  subCategoryList: any;
  subCategoryVendor: any;
  subCategoryListData: any;
  subCategoryData: any;
  currTab: any;
  productSubCategory: any;
}

const initialState: UserState = {
  subCategoryList: {},
  subCategoryVendor: {},
  subCategoryListData: {},
  subCategoryData: {},
  productSubCategory: {},
  currTab: "Admin",
};

export const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {
    clearSubCategory: () => initialState,
    setSubCategory: (state, { payload }) => {
      state.subCategoryList = payload;
    },
    setCurrTab: (state, { payload }) => {
      state.currTab = payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      subCategoryApi.endpoints.getSubCategory.matchFulfilled,
      (state, action: any) => {
        if (action?.meta?.arg?.originalArgs?.filter_data?.is_admin === 1) {
          state.subCategoryList = action?.payload;
        } else {
          state.subCategoryVendor = action?.payload;
        }
      }
    );
    builder.addMatcher(
      subCategoryApi.endpoints.addSubCategory.matchFulfilled,
      (state, { payload }) => {
        state.subCategoryData = payload || {};
      }
    );
  
  },
});

// Action creators are generated for each case reducer function
export default subCategorySlice.reducer;
export const { clearSubCategory, setSubCategory, setCurrTab } = subCategorySlice.actions;

export const selectSubCategoryList = (state: RootState) => state.subCategory.subCategoryList;
export const useSubCategoryList = () => {
  const subCategoryList = useSelector(selectSubCategoryList);
  return useMemo(() => subCategoryList, [subCategoryList]);
};
export const selectVendorSubCategoryList = (state: RootState) => state.subCategory.subCategoryVendor;
export const useVendorSubCategoryList = () => {
  const subCategoryVendorList = useSelector(selectVendorSubCategoryList);
  return useMemo(() => subCategoryVendorList, [subCategoryVendorList]);
};

export const selectSubCategoryListData = (state: RootState) => state.subCategory.subCategoryListData;
export const useSubCategoryListData = () => {
  const subCategoryListData = useSelector(selectSubCategoryListData);
  return useMemo(() => subCategoryListData, [subCategoryListData]);
};

export const selectSubCategoryData = (state: RootState) => state.subCategory.subCategoryData;
export const useSubCategoryData = () => {
  const subCategoryData = useSelector(selectSubCategoryData);
  return useMemo(() => subCategoryData, [subCategoryData]);
};

export const selectCurrentTab = (state: RootState) => state.subCategory.currTab;
export const useCurrentTab = () => {
  const currentTab = useSelector(selectCurrentTab || "Admin");
  return useMemo(() => currentTab, [currentTab]);
};
