import { api } from "../../../utills/api";

export const supportLogsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSupportLogs: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/developer/get-support-logs",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetSupportLogsMutation } = supportLogsApi;
export const {
  endpoints: { getSupportLogs },
} = supportLogsApi;
