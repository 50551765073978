import React, { useState, useRef, useEffect } from "react";

const OTPInput = ({ numInputs, onOtpChange, isValid, triggerVibrate }: any) => {
  const [otp, setOtp] = useState(new Array(numInputs).fill(""));
  const inputRefs = useRef<any>([]);

  useEffect(() => {
    setOtp(new Array(numInputs).fill(""));
  }, [numInputs]);

  useEffect(() => {
    if (!isValid && triggerVibrate) {
      inputRefs.current.forEach((input: any) => {
        input.classList.add("vibrate");
        setTimeout(() => input.classList.remove("vibrate"), 300);
      });
    }
  }, [isValid, triggerVibrate]);

  const handleChange = (element: any, index: number) => {
    const value = element.value.replace(/[^0-9]/g, "");
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    onOtpChange(newOtp.join(""));

    // Move to the next input field if there's a value
    if (value && index < numInputs - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e: any, index: number) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      if (otp[index]) {
        newOtp[index] = "";
      } else if (index > 0) {
        inputRefs.current[index - 1].focus();
        newOtp[index - 1] = "";
      }
      setOtp(newOtp);
      onOtpChange(newOtp.join(""));
    }
  };

  return (
    <div className={`flex gap-2 justify-center`}>
      {otp.map((data, index) => (
        <input
          key={index}
          type="tel"
          maxLength={1}
          value={data}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el: any) => (inputRefs.current[index] = el)}
          className={`h-10 w-10 sm:w-8 sm:h-8 text-center text-lg sm:text-base rounded-md border ${
            isValid ? "border-secondary-darkgray" : "border-red-500"
          }`}
        />
      ))}
    </div>
  );
};

export default OTPInput;
