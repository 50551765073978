import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { ticketApi } from "./ticketApi";

interface UserState {
  ticketList: any;
  vandorUserList: any;
}

const initialState: UserState = {
  ticketList: {},
  vandorUserList: {},
};

export const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    clearTicket: () => initialState,
    setTickets: (state, { payload }) => {
      state.ticketList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ticketApi.endpoints.getTicket.matchFulfilled,
      (state, { payload }) => {
        state.ticketList = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default ticketSlice.reducer;
export const { clearTicket, setTickets } = ticketSlice.actions;

export const selectTicketList = (state: RootState) => state.ticket.ticketList;
export const useTicketList = () => {
  const ticketList = useSelector(selectTicketList);
  return useMemo(() => ticketList, [ticketList]);
};