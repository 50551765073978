import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { cityApi } from "./cityApi";

interface UserState {
  cityList: any;
}

const initialState: UserState = {
  cityList: {},
};

export const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    clearCity: () => initialState,
    setCity: (state, action) => {
      state.cityList = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      cityApi.endpoints.getCity.matchFulfilled,
      (state, action: any) => {
        state.cityList = action?.payload;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default citySlice.reducer;
export const { clearCity, setCity } =
  citySlice.actions;

export const selectCityList = (state: RootState) =>
  state.city.cityList;
export const useCityList = () => {
  const cityList = useSelector(selectCityList);
  return useMemo(() => cityList, [cityList]);
};
