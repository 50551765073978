import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, ControlledDropDown } from "../../../ui";

import { responseToaster } from "../../../helperFunctions";
import {
  useAssignDeliveryPersonMutation,
  useGetDeliveryPersonListMutation,
} from "./deliveredByApi";
import { useDeliveredPersonData } from "./deliveredBySlice";

const AssignDeliveryPerson = (props: any) => {
  const { onHide, isOpen,onGet, deliveredPersonData } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);

  const [assignDeliveryPerson] = useAssignDeliveryPersonMutation();
  const deliveredPersonData1 = useDeliveredPersonData();
  const [getDeliveryPersonList] = useGetDeliveryPersonListMutation();

  const schema: any = yup.object({
    delivery_persons_id: yup.string().required("Please select delivery person"),
  });

  const preparePersonOptions = () => {
    const options = deliveredPersonData1?.data
      ?.map((val: any) => ({
        value: val?.id,
        label: val?.first_name,
      }))
      ?.sort((a: any, b: any) => a?.label?.localeCompare?.(b?.label));

    return options || [];
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      delivery_persons_id: "",
    },
  });

  const onClose = () => {
    reset();
    onHide();
  };

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const payload = {
        ...values,
        ...(deliveredPersonData?.id ? { id: deliveredPersonData?.id } : null),
      };

      const res: any = await assignDeliveryPerson(payload).unwrap();
      if (res?.status) {
        onClose();
        onGet?.();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  const getDeliveryPerson = async () => {
    try {
      await getDeliveryPersonList();
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };
  useEffect(() => {
    if (isOpen) {
      getDeliveryPerson();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addCpisLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-4/12 2xl:w-4/12 xl:w-4/12 lg:w-6/12  md:w-7/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                Assign Delivery Person
              </h4>
            </div>
            <div className="modal-body">
              <div className="grid  items-start gap-3 pt-2 pb-3">
                <ControlledDropDown
                  name="delivery_persons_id"
                  label="Delivery Person"
                  placeholder="Select Delivery Person"
                  options={preparePersonOptions()}
                  control={control}
                  errors={errors}
                />
              </div>
            </div>
            <div className="modal-footer pb-1">
              <div className="flex items-center justify-end gap-2">
                <Button
                  type="submit"
                  className="mt-[10px] !h-9 !text-sm"
                  isLoading={addCpisLoading}
                  isLoader={addCpisLoading}
                >
                  Submit
                </Button>
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={addCpisLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AssignDeliveryPerson;
