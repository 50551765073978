import { api } from "../../utills/api";

export const settlementApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSettlement: build.mutation<{ data: any }, void>({
      query: () => ({
        url: "/admin/settlement/list-settlement",
        method: "POST",
      }),
    }),
    getTransation: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/settlement/get-settlement",
        body: params,
        method: "POST",
      }),
    }),
    vendorSettlement: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/settlement/vendor-settlement",
        body: params,
        method: "POST",
      }),
    }),
    updateSettlement: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/settlement/add-settlement",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetSettlementMutation,
  useUpdateSettlementMutation,
  useVendorSettlementMutation,
  useGetTransationMutation,
} = settlementApi;
export const {
  endpoints: {
    getSettlement,
    updateSettlement,
    vendorSettlement,
    getTransation,
  },
} = settlementApi;
