import React, { memo } from "react";
import { IconProps } from ".";

export interface VendorPlanIconProps extends IconProps {}
const VendorPlanIcon = (props: VendorPlanIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M8 2V5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
         className={`${pathClassNames?.[0] || ""}`}
      />
      <path
        d="M16 2V5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
         className={`${pathClassNames?.[1] || ""}`}
      />
      <path
        d="M7 11H15"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
         className={`${pathClassNames?.[2] || ""}`}
      />
      <path
        d="M7 15H12"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
         className={`${pathClassNames?.[3] || ""}`}
      />
      <path
        d="M15 22H9C4 22 3 19.94 3 15.82V9.65C3 4.95 4.67 3.69 8 3.5H16C19.33 3.68 21 4.95 21 9.65V16"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
         className={`${pathClassNames?.[4] || ""}`}
      />
      <path
        d="M21 16L15 22V19C15 17 16 16 18 16H21Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
         className={`${pathClassNames?.[5] || ""}`}
      />
    </svg>
  );
};

const MemoVendorPlanIcon = memo(VendorPlanIcon);
export default MemoVendorPlanIcon;
