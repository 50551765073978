import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { subscribeApi } from "./subscribeApi";

interface UserState {
  subscribeList: any;
  subscribeOtherCitiesList: any;
  vandorUserList: any;
  currTab: any;
}

const initialState: UserState = {
  subscribeList: {},
  subscribeOtherCitiesList: {},
  vandorUserList: {},
  currTab: "Subscribe",
};

export const subscribeSlice = createSlice({
  name: "subscribe",
  initialState,
  reducers: {
    clearSubscribe: () => initialState,
    setSubscribes: (state, { payload }) => {
      state.subscribeList = payload;
    },
    setCurrTab: (state, { payload }) => {
      state.currTab = payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      subscribeApi.endpoints.getSubscribe.matchFulfilled,
      (state, action: any) => {
        if (action?.meta?.arg?.originalArgs?.filter_data?.is_other_city === 1) {
          state.subscribeOtherCitiesList = action?.payload || {};
        } else {
          state.subscribeList = action?.payload || {};
        }
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default subscribeSlice.reducer;
export const { clearSubscribe, setSubscribes, setCurrTab } =
  subscribeSlice.actions;

export const selectSubscribeList = (state: RootState) =>
  state.subscribe.subscribeList;
export const useSubscribeList = () => {
  const subscribeList = useSelector(selectSubscribeList);
  return useMemo(() => subscribeList, [subscribeList]);
};

export const selectSubscribeOtherCitiesList = (state: RootState) =>
  state.subscribe.subscribeOtherCitiesList;
export const useSubscribeOtherCitiesList = () => {
  const subscribeCitiesList = useSelector(selectSubscribeOtherCitiesList);
  return useMemo(() => subscribeCitiesList, [subscribeCitiesList]);
};

export const selectCurrentTab = (state: RootState) => state.subscribe.currTab;
export const useCurrentTab = () => {
  const currentTab = useSelector(selectCurrentTab || "Subscribe");
  return useMemo(() => currentTab, [currentTab]);
};
