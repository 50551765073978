import React, { memo } from "react";
import { IconProps } from ".";

export interface CheckIconProps extends IconProps {}
const CheckIcon = (props: CheckIconProps) => {
  const {
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 2,
    ...rest
  } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="M20 6 9 17l-5-5" className={`${pathClassNames?.[0] || ""}`}/>
    </svg>
  );
};

const MemoCheckIcon = memo(CheckIcon);
export default MemoCheckIcon;
