import { api } from "../../utills/api";

export const roleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRole: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/role/get-roles",
        body: params,
        method: "POST",
      }),
    }),
    updateRole: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/role/update-roles",
        body: params,
        method: "POST",
      }),
    }),
    addRole: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/role/add-roles",
        body: params,
        method: "POST",
      }),
    }),
    deleteRole: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/role/delete-role",
        body: params,
        method: "POST",
      }),
    }),
    getRoleById: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/role/get-role-by-id",
        body: params,
        method: "POST",
      }),
    }),
    getRoleList: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/role/role-list",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetRoleMutation,
  useUpdateRoleMutation,
  useAddRoleMutation,
  useDeleteRoleMutation,
  useGetRoleByIdMutation,
  useGetRoleListMutation,
} = roleApi;
export const {
  endpoints: {
    getRole,
    addRole,
    updateRole,
    deleteRole,
    getRoleById,
    getRoleList,
  },
} = roleApi;
