import { api } from "../../utills/api";

export const categoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCategory: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/category/get-category",
        body: params,
        method: "POST",
      }),
    }),
    addCategory: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/category/add-category",
        body: params,
        method: "POST",
      }),
    }),
    updateCategory: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/category/update-category",
        body: params,
        method: "POST",
      }),
    }),
    deleteCategory: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/category/delete-category",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetCategoryMutation,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoryApi;
export const {
  endpoints: { getCategory, addCategory, updateCategory, deleteCategory },
} = categoryApi;
