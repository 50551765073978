import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { vendorPlanApi } from "./vendorPlanApi";

interface UserState {
  vendorPlanList: any;
}

const initialState: UserState = {
  vendorPlanList: {},
};

export const vendorPlanSlice = createSlice({
  name: "vendorPlan",
  initialState,
  reducers: {
    clearVendorPlan: () => initialState,
    setVendorPlans: (state, { payload }) => {
      state.vendorPlanList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      vendorPlanApi.endpoints.getVendorPlan.matchFulfilled,
      (state, { payload }) => {
        state.vendorPlanList = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default vendorPlanSlice.reducer;
export const { clearVendorPlan, setVendorPlans } = vendorPlanSlice.actions;

export const selectVendorPlanList = (state: RootState) => state.vendorPlan.vendorPlanList;
export const useVendorPlanList = () => {
  const vendorPlanList = useSelector(selectVendorPlanList);
  return useMemo(() => vendorPlanList, [vendorPlanList]);
};
