import { useLayoutEffect, useState } from "react";

// UI IMPORT
import { Loader } from "./ui";

// PROJECT IMPORT
import { prepareBlobUrl, setFontFamily, setStyles } from "./helperFunctions";
import { AllRoutes } from "./components/routes";
import { DEFAULT_FONT_FAMILY } from "./constant";
import { useLogout } from "./hooks";
import "./App.css";

// THIRD - PARTY IMPORT
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";

const color: any = process.env.REACT_APP_COLOR;
const fontFamily = process.env.REACT_APP_FONT_FAMILY || DEFAULT_FONT_FAMILY;
const favicon = process.env.REACT_APP_FAVICON;

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    if (color) {
      setIsLoading(true);
      setStyles("--chatlook-primary--", color);
      setStyles("--ev-primary--", color);
    }

    if (fontFamily) {
      setFontFamily(fontFamily);
    }

    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useLogout();

  if (isLoading) {
    return (
      <>
        <div className="flex items-center justify-center loader_height">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <div className="App">
      <Helmet>
        <link rel="icon" type="image/svg+xml" href={prepareBlobUrl(favicon)} />
      </Helmet>
      <AllRoutes />
      <Toaster />
    </div>
  );
}

export default App;
