import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, ControlledInput } from "../../ui";
import {
  useUpdatePolicyMutation,
} from "./policyApi";
import { responseToaster } from "../../helperFunctions";
import { IoIosCloseCircle } from "react-icons/io";
import ControlledTextArea from "../../ui/forms/ControlledTextArea";

const PolicyModal = (props: any) => {
  const { onHide, isOpen, onGet, policyData, currentTab } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<any>(true);
  const [updatePolicy] = useUpdatePolicyMutation();

  const schema: any = yup.object({
    description: yup.string().required("Please add description"),
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
  });
  const onClose = () => {
    reset();
    onHide();
    setIsAdmin(true);
  };

  useEffect(() => {
    if (isOpen) {
      if (policyData?.id) {
        if (policyData?.id) {
          setValue("attribute", policyData?.attribute);
          setValue("description", policyData?.description);
        }
      }
    }
    // eslint-disable-next-line
  }, [isOpen, policyData?.id]);

  const onSubmit = async (values: any) => {
    try {
      const { attribute, ...rest } = values;
      setaddCpIsLoading(true);
      const payload = {
        ...rest,
      };
      const res: any =  await updatePolicy({ id: policyData?.id, ...payload }).unwrap()
      if (res?.status) {
        onClose();
        onGet?.();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addCpisLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-6/12 xl:w-6/12 lg:w-8/12  md:w-9/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                {`${
                  policyData?.id
                    ? currentTab === "Vendor"
                      ? "View"
                      : "Update"
                    : "Add"
                }`}{" "}
                Policy
              </h4>
              {!isAdmin ? (
                <div onClick={() => onClose()} className="cursor-pointer">
                  <IoIosCloseCircle color="var(--ev-primary--)" size={22} />
                </div>
              ) : null}
            </div>
            <div className="modal-body">
              <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 items-start gap-3 pt-2 pb-3">
                <ControlledInput
                  name="attribute"
                  label="Attribute"
                  type="text"
                  className="sm:col-span-2"
                  errors={errors}
                  disabled
                  control={control}
                />
                <ControlledTextArea
                  name="description"
                  label="Description"
                  className="col-span-2 textareacss"
                  placeholder="Select Vendor"
                  control={control}
                  errors={errors}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div className="flex items-center justify-end gap-2">
                <Button
                  type="submit"
                  className="mt-[10px] !h-9 !text-sm"
                  isLoading={addCpisLoading}
                  isLoader={addCpisLoading}
                >
                  {policyData?.id ? "Update" : "Submit"}
                </Button>
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={addCpisLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PolicyModal;
