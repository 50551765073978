export const shippingCostOptions = [
  { value: "Free", label: "Free" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
  { value: "150", label: "150" },
  { value: "200", label: "200" },
  { value: "250", label: "250" },
  { value: "300", label: "300" },
  { value: "350", label: "350" },
  { value: "400", label: "400" },
];

export const shippingTimeOptions = [
  { value: "24 Hours", label: "24 Hours" },
  { value: "1 - 3 Days", label: "1 - 3 Days" },
  { value: "3 - 5 Days", label: "3 - 5 Days" },
  { value: "5 - 10 Days", label: "5 - 10 Days" },
  { value: "More Than 10 Days", label: "More Than 10 Days" },
];

export const returnTimeLimitOptions = [
  { value: "1 Days", label: "1 Days" },
  { value: "2 Days", label: "2 Days" },
  { value: "3 Days", label: "3 Days" },
  { value: "4 Days", label: "4 Days" },
  { value: "5 - 7 Days", label: "5 - 7 Days" },
  { value: "8 - 14 Days", label: "8 - 14 Days" },
  { value: "More Than 15 Days", label: "More Than 10 Days" },
];

export const returnConditionOptions = [
  { value: "Unopened", label: "Unopened" },
  { value: "Unused", label: "Unused" },
  { value: "Original packaging intact", label: "Original packaging intact" },
  { value: "Defective upon arrival", label: "Defective upon arrival" },
];

export const returnFeeOptions = [
  { value: "0", label: "No return fee" },
  { value: "100", label: "100" },
  { value: "150", label: "150" },
  { value: "200", label: "200" },
  { value: "250", label: "250" },
  { value: "300", label: "300" },
  { value: "350", label: "350" },
  { value: "400", label: "400" },
];

export const refundTimeframeOptions = [
  // { value: "7 days", label: "7 days" },
  // { value: "14 days", label: "14 days" },
  // { value: "30 days", label: "30 days" },
  // { value: "60 days", label: "60 days" },
  // { value: "90 days", label: "90 days" },

  { value: "1 Days", label: "1 Days" },
  { value: "2 Days", label: "2 Days" },
  { value: "3 Days", label: "3 Days" },
  { value: "4 Days", label: "4 Days" },
  { value: "5 - 7 Days", label: "5 - 7 Days" },
  { value: "8 - 14 Days", label: "8 - 14 Days" },
  { value: "More Than 15 Days", label: "More Than 10 Days" },
];

export const refundMethodOptions = [
  { value: "Cash", label: "Cash" },
  { value: "UPI", label: "UPI" },
  { value: "Exchange", label: "Exchange" },
];

export const deliveryMethodOptions = [
  { value: "Standard delivery", label: "Standard delivery" },
  { value: "Express delivery", label: "Express delivery" },
  { value: "Next-day delivery", label: "Next-day delivery" },
  { value: "Same-day delivery", label: "Same-day delivery" },
  { value: "Economy shipping", label: "Economy shipping" },
  { value: "Priority shipping", label: "Priority shipping" },
  { value: "Freight shipping", label: "Freight shipping" },
  { value: "Courier service", label: "Courier service" },
  { value: "Pickup in-store", label: "Pickup in-store" },
  { value: "Curbside pickup", label: "Curbside pickup" },
  { value: "White glove delivery", label: "White glove delivery" },
  { value: "Postal service", label: "Postal service" },
  { value: "Air mail", label: "Air mail" },
  { value: "Ground shipping", label: "Ground shipping" },
  { value: "Local delivery", label: "Local delivery" },
  { value: "International shipping", label: "International shipping" },
  { value: "Freight forwarding", label: "Freight forwarding" },
  { value: "Dropshipping", label: "Dropshipping" },
  { value: "Truck delivery", label: "Truck delivery" },
  { value: "Drone delivery", label: "Drone delivery" },
];

export const deliveryCostOptions = [
  { value: "100", label: "100" },
  { value: "150", label: "150" },
  { value: "200", label: "200" },
  { value: "250", label: "250" },
  { value: "300", label: "300" },
  { value: "350", label: "350" },
  { value: "400", label: "400" },
];

export const acceptedPaymentOptions = [
  { value: "Credit card", label: "Credit card" },
  { value: "Debit card", label: "Debit card" },
  { value: "PayPal", label: "PayPal" },
  { value: "Apple Pay", label: "Apple Pay" },
  { value: "Google Pay", label: "Google Pay" },
  { value: "Bank transfer", label: "Bank transfer" },
  { value: "Cash on delivery", label: "Cash on delivery" },
  { value: "Check", label: "Check" },
  { value: "Gift card", label: "Gift card" },
  { value: "Cryptocurrency", label: "Cryptocurrency" },
  { value: "Mobile payment", label: "Mobile payment" },
  { value: "Invoice", label: "Invoice" },
];

export const paymentTermsOptions = [
  { value: "Net 30 days", label: "Net 30 days" },
  { value: "Net 60 days", label: "Net 60 days" },
  { value: "Net 90 days", label: "Net 90 days" },
  { value: "Pay on delivery", label: "Pay on delivery" },
  { value: "Cash on delivery", label: "Cash on delivery" },
  { value: "Full upfront payment", label: "Full upfront payment" },
  { value: "Payment due upon receipt", label: "Payment due upon receipt" },
  { value: "Installment payments", label: "Installment payments" },
  { value: "Credit card payment", label: "Credit card payment" },
  { value: "PayPal payment", label: "PayPal payment" },
  { value: "Bank transfer", label: "Bank transfer" },
  { value: "Check payment", label: "Check payment" },
];

export const attributeOption: any = {
  "Shipping & Delivery": [
    {
      value: "Shipping Cost",
      label: "Shipping & Delivery Cost",
      // options: shippingCostOptions,
      validation: true,
      placeholder: "Enter Shipping Cost in Ruppes",
    },
    {
      value: "Shipping Time",
      label: "Shipping & Delivery Time",
      // validation: true,
      options: shippingTimeOptions,
      placeholder: "Enter shipping time (e.g., 3-5 days)",
    },
    {
      value: "Region Covered",
      label: "Distance Area",
      validation: true,
      placeholder: "Enter distance km(10km)",
    },
  ],
  Return: [
    {
      value: "Return Time Limit",
      label: "Return Time Limit",
      options: returnTimeLimitOptions,
      placeholder: "Select return time limit",
    },
    {
      value: "Return Condition",
      label: "Return Condition",
      options: returnConditionOptions,
      placeholder: "Select return condition",
    },
    {
      value: "Return Fee",
      label: "Return Fee",
      // options: returnFeeOptions,
      validation: true,
      placeholder: "Enter return fee in ruppes",
    },
  ],
  Refund: [
    {
      value: "Refund Timeframe",
      label: "Refund Timeframe",
      options: refundTimeframeOptions,
      placeholder: "Select refund timeframe",
    },
    {
      value: "Refund Method",
      label: "Refund Method",
      options: refundMethodOptions,
      placeholder: "Select refund method",
    },
  ],
  // Delivery: [
  //   {
  //     value: "Delivery Method",
  //     label: "Delivery Method",
  //     options: deliveryMethodOptions,
  //     placeholder: "Select delivery method",
  //   },
  //   {
  //     value: "Delivery Cost",
  //     label: "Delivery Cost",
  //     options: deliveryCostOptions,
  //     placeholder: "Select delivery cost",
  //   },
  // ],
  // Payment: [
  //   {
  //     value: "Accepted Payment",
  //     label: "Accepted Payment",
  //     options: acceptedPaymentOptions,
  //     placeholder: "Select accepted payment",
  //   },
  //   {
  //     value: "Payment Terms",
  //     label: "Payment Terms",
  //     options: paymentTermsOptions,
  //     placeholder: "Select payment terms",
  //   },
  // ],
};
