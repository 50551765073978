import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, ControlledDropDown, ControlledInput } from "../../../ui";
import {
  useGetShopCategoryMutation,
  useUpdateVendorMutation,
} from "./vendorApi";
import { responseToaster } from "../../../helperFunctions";
import ControlledTextArea from "../../../ui/forms/ControlledTextArea";
import ControlledSwitch from "../../../ui/forms/ControlledSwitch";
import { useShopCategoryList } from "./vendorSlice";

const paymentMethodOptions = [
  {
    value: "1",
    label: "Cash",
  },
  {
    value: "2",
    label: "Online",
  },
  {
    value: "0",
    label: "All",
  },
];

const VendorModal = (props: any) => {
  const { onHide, isOpen, onGet, vendorData } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);

  const [updateVendor] = useUpdateVendorMutation();
  const [getShopCategory] = useGetShopCategoryMutation();
  const shopCategoryList = useShopCategoryList();

  const schema: any = yup.object({
    name: yup.string().required("Please enter a name"),
    store_name: yup.string().required("Please add a store name"),
    email: yup
      .string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
      .required("Please enter a Email"),
    address: yup.string().required("Please add a Address"),
    upi_id: yup
      .string()
      .matches(/^[\w.-]+@[\w.-]+$/, "Invalid UPI ID format")
      .required("UPI ID is required"),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    reset();
    onHide();
  };

  useEffect(() => {
    if (isOpen) {
      if (vendorData?.id) {
        setValue("address", vendorData?.address);
        setValue("contact", vendorData?.contact);
        setValue("delivery_charge", vendorData?.delivery_charge);
        setValue("email", vendorData?.email);
        setValue("facebook_link", vendorData?.facebook_link);
        setValue("instagram_link", vendorData?.instagram_link);
        setValue("twitter_link", vendorData?.twitter_link);
        setValue("is_approved", vendorData?.is_approved);
        setValue("status", vendorData?.status === "active" ? 1 : 0);
        setValue("is_auto_approved", vendorData?.is_auto_approved);
        setValue("is_email_verify", vendorData?.is_email_verify);
        setValue("name", vendorData?.name);
        setValue("order_service", vendorData?.order_service);
        setValue("upi_id", vendorData?.upi_id);
        setValue("payment_method", vendorData?.payment_method);
        setValue("store_name", vendorData?.store_name);
        setValue("store_status", vendorData?.store_status);
        setValue("city", vendorData?.city);
        setValue("shop_id", vendorData?.shop_id);
      }
    }
    // eslint-disable-next-line
  }, [isOpen, vendorData, vendorData?.id]);

  const getShopCategoryList = async () => {
    try {
      await getShopCategory().unwrap();
    } catch (error) {}
  };

  const prepareShopcategoryOptions = () => {
    const categoriesOptions = shopCategoryList?.data
      // eslint-disable-next-line
      ?.slice()
      ?.sort((a: { title: string }, b: { title: any }) =>
        a?.title?.localeCompare?.(b?.title)
      )
      ?.map((val: { id: any; title: any }) => ({
        value: val?.id,
        label: val?.title,
      }));
    return categoriesOptions || [];
  };

  useEffect(() => {
    if (isOpen) {
      getShopCategoryList();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const payload = {
        ...values,
        status: values?.status ? "active" : "inactive",
      };
      const res: any = await updateVendor({
        id: vendorData?.id,
        ...payload,
      }).unwrap();
      if (res?.status) {
        onClose();
        onGet?.();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  const deliveryTypeOptions = [
    {
      value: 0,
      label: "All",
    },
    {
      value: 1,
      label: "Delivery",
    },
    {
      value: 2,
      label: "Instore Pickup",
    },
  ];
  const citiesOptions = [
    {
      value: "Surat",
      label: "Surat",
    },
    {
      value: "Rajkot",
      label: "Rajkot",
    },
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addCpisLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-6/12 xl:w-6/12 lg:w-8/12  md:w-9/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-9 px-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                Update Vendor
              </h4>
            </div>
            <div className="modal-body">
              <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 items-start gap-3 pt-2 pb-3">
                <ControlledInput
                  name="name"
                  label="Name"
                  placeholder="Enter Name"
                  type="text"
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="contact"
                  label="Contact"
                  placeholder="Enter Contact"
                  type="text"
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="delivery_charge"
                  label="Delivery Charge"
                  placeholder="Enter Delivery Charge"
                  onChange={(e) => {
                    if (
                      e?.target?.value === "" ||
                      /^\d+$/.test(e?.target?.value)
                    ) {
                      setValue("delivery_charge", e?.target?.value);
                    }
                  }}
                  type="text"
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="email"
                  label="Email"
                  placeholder="Enter Email"
                  type="text"
                  errors={errors}
                  control={control}
                />

                <ControlledInput
                  name="store_name"
                  label="Store Name"
                  placeholder="Enter Store Name"
                  type="text"
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="upi_id"
                  label="UPI Id"
                  placeholder="Enter UPI Id"
                  type="text"
                  errors={errors}
                  control={control}
                />
                <ControlledDropDown
                  name="shop_id"
                  label="Shop Category"
                  placeholder="Select Shop Category"
                  options={prepareShopcategoryOptions()}
                  errors={errors}
                  control={control}
                />
                <ControlledDropDown
                  name="order_service"
                  label="Order Service"
                  placeholder="Select Order Service"
                  options={deliveryTypeOptions}
                  errors={errors}
                  control={control}
                />
                <ControlledDropDown
                  name="city"
                  label="City"
                  placeholder="Select City"
                  options={citiesOptions}
                  errors={errors}
                  control={control}
                />

                <ControlledDropDown
                  name="payment_method"
                  label="Payment Method"
                  placeholder="Select Payment Method"
                  options={paymentMethodOptions}
                  control={control}
                  errors={errors}
                />
                <ControlledInput
                  name="facebook_link"
                  label="Facebook Link"
                  placeholder="Enter Facebook Link"
                  type="text"
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="instagram_link"
                  label="Instagram Link"
                  placeholder="Enter Instagram Link"
                  type="text"
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="twitter_link"
                  label="Twitter Link"
                  placeholder="Enter Twitter Link"
                  type="text"
                  errors={errors}
                  control={control}
                />
                <div>
                  <ControlledSwitch
                    name="status"
                    label="Status"
                    control={control}
                  />
                  <ControlledSwitch
                    name="store_status"
                    label="Store Status"
                    control={control}
                  />
                  <ControlledSwitch
                    name="is_approved"
                    label="Is Approved"
                    control={control}
                  />
                  <ControlledSwitch
                    name="is_auto_approved"
                    label="Is Auto Approved"
                    control={control}
                  />
                  <ControlledSwitch
                    name="is_email_verify"
                    label="Is Email Verify"
                    control={control}
                  />
                </div>
                <ControlledTextArea
                  name="address"
                  label="Address"
                  placeholder="Enter Address"
                  control={control}
                  errors={errors}
                  className="textareacss"
                />
              </div>
            </div>
            <div className="modal-footer pb-5">
              <div className="flex items-center justify-end gap-2">
                <Button
                  type="submit"
                  className="mt-[10px] !h-9 !text-sm"
                  isLoading={addCpisLoading}
                  isLoader={addCpisLoading}
                >
                  Update
                </Button>
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={addCpisLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default VendorModal;
