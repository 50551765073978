import { api } from "../../../utills/api";

export const courierPartnerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCourierPartner: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/courier/get-courier-partner-list",
        body: params,
        method: "POST",
      }),
    }),
    updateCourierPartner: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/courier/update-courier-partner",
        body: params,
        method: "POST",
      }),
    }),
    addCourierPartner: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/courier/add-courier-partner",
        body: params,
        method: "POST",
      }),
    }),
    deleteCourierPartner: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/courier/delete-courier-partner",
        body: params,
        method: "POST",
      }),
    }),
    getCourierPartnerById: build.mutation<{ data: any }, void>({
      query: (params: any) => ({
        url: `/admin/courier/get-courier-by-id/${params?.id}`,
        // body: params,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetCourierPartnerMutation,
  useUpdateCourierPartnerMutation,
  useAddCourierPartnerMutation,
  useDeleteCourierPartnerMutation,
  useGetCourierPartnerByIdMutation,
} = courierPartnerApi;
export const {
  endpoints: {
    getCourierPartner,
    addCourierPartner,
    updateCourierPartner,
    deleteCourierPartner,
    getCourierPartnerById,
  },
} = courierPartnerApi;
