import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { policyApi } from "./policyApi";

interface UserState {
  policyList: any;
  policyData: any;
  productPolicy: any;
}

const initialState: UserState = {
  policyList: {},
  policyData: {},
  productPolicy: {},
};

export const policySlice = createSlice({
  name: "policy",
  initialState,
  reducers: {
    clearPolicy: () => initialState,
    setPolicy: (state, { payload }) => {
      state.policyList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      policyApi.endpoints.getPolicy.matchFulfilled,
      (state, action: any) => {
        state.policyList = action?.payload;
      }
    );
  },
});

export default policySlice.reducer;
export const { clearPolicy, setPolicy } = policySlice.actions;

export const selectPolicyList = (state: RootState) => state.policy.policyList;
export const usePolicyList = () => {
  const policyList = useSelector(selectPolicyList);
  return useMemo(() => policyList, [policyList]);
};

export const selectPolicyData = (state: RootState) => state.policy.policyData;
export const usePolicyData = () => {
  const policyData = useSelector(selectPolicyData);
  return useMemo(() => policyData, [policyData]);
};
