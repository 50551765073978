import React, { Fragment, useEffect, useState } from "react";
import { Button, Table, TableRow } from "../../ui";
import { FILTER } from "../../constant";
import { useDeleteRoleMutation, useGetRoleMutation } from "./roleApi";
import { useRoleList, useVendorRoleList } from "./roleSlice";
import RoleModal from "./RoleModal";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import { Modal } from "../common";
import { responseToaster } from "../../helperFunctions";
import Tooltip from "../../ui/elements/Tooltip";
import { useNavigate } from "react-router-dom";
import { useRole } from "../auth/authSlice";

const Role = () => {
  const [getRole, { isLoading }] = useGetRoleMutation();
  const roleList = useRoleList();
  const roleVendorList = useVendorRoleList();
  const [deleteRole, { isLoading: isDeleteLoading }] = useDeleteRoleMutation();
  const [isRoleModal, setIsRoleModal] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();
  const [roleData, setRoleData] = useState({});
  const navigate = useNavigate();
  const role = useRole("role");

  if (!role.includes("list")) {
    navigate("/");
  }
  const handleAddRole = () => {
    setIsRoleModal(true);
  };

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Name",
      name: "name",
      sortable: true,
      sortableKey: "name",
    },
    {
      title: "Guard Name",
      name: "guard_name",
      sortable: true,
      sortableKey: "guard_name",
    },
    {
      title: "Date",
      name: "created_at_ist",
      sortable: true,
      sortableKey: "created_at_ist",
      type: "date",
    },
    {
      title: "Action",
      name: "action",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            <Tooltip text="Edit">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setIsRoleModal(true);
                  setRoleData(item);
                }}
                disabled={!role.includes("edit")}
              >
                <MdEdit />
              </Button>
            </Tooltip>

            <Tooltip text="Delete" className="right-0">
              <Button
                className="!h-8 w-8 !p-0"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeleteData(item);
                }}
                disabled={!role.includes("delete")}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onRoleDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteRole(payload).unwrap();
      if (res) {
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
      responseToaster(res);
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getRoleList = async () => {
    try {
      const payload: any = {
        ...filter,
        filter_data: {
          vendor_id: filter?.vendor_id,
        },
      };

      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getRole(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    getRoleList();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setTableData(roleList?.data?.length ? [...roleList?.data] : []);
  }, [roleList, roleVendorList]);

  return (
    <>
      <div>
        {role.includes("create") ? (
          <div className="flex mb-3 gap-2 justify-end">
            <div className="flex justify-end">
              <Button onClick={() => handleAddRole()}>Add Role</Button>
            </div>
          </div>
        ) : null}

        {role.includes("list") ? (
          <div className="cardcss">
            <Table
              columns={columns}
              className="roleTable"
              isExpendable={false}
              isLoading={isLoading}
              data={tableData || []}
              setData={setTableData}
              count={roleList?.total_item}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                      onClick={(item: any) => {}}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        ) : null}
      </div>
      <RoleModal
        isOpen={isRoleModal}
        onGet={getRoleList}
        roleData={roleData}
        onHide={() => {
          setIsRoleModal(false);
          setRoleData({});
        }}
      />
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onRoleDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default Role;
