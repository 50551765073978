import React, { Fragment, useEffect, useState } from "react";
import { FILTER, statusOptions } from "../../constant";
import { Button, Icon, Select, Table, TableRow } from "../../ui";
import { setUsers, useUsersList } from "./usersSlice";
import { useGetUsersMutation, useUpdateUsersMutation } from "./usersApi";
import { prepareVendorOptions, responseToaster } from "../../helperFunctions";
import { useDispatch } from "react-redux";
import Switch from "../../ui/forms/Switch";
import { useForm } from "react-hook-form";

import { useRole } from "../auth/authSlice";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const [getUsers, { isLoading }] = useGetUsersMutation();
  const [updateUsers] = useUpdateUsersMutation();
  const usersList = useUsersList();
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [statusLoading, setStatusLoading] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = useRole("user");

  const onUpdateStatus = async (payload: any) => {
    try {
      setStatusLoading(payload?.id);
      const res = await updateUsers(payload).unwrap();

      const index = tableData?.findIndex(
        (item: { id: any }) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...tableData];
        newData[index] = {
          ...newData[index],
          status: payload?.status,
        };
        dispatch(setUsers({ ...usersList, data: newData }));
      }
      responseToaster(res);
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };

  if (!role.includes("list")) {
    navigate("/");
  }
  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Name",
      name: "firstName",
      sortable: true,
      sortableKey: "firstName",
    },
    {
      title: "Email",
      name: "email",
      sortable: true,
      sortableKey: "email",
    },
    {
      title: "Status",
      name: "status",
      Cell: (data: any) => (
        <Switch
          checked={data?.status === "Active" || data?.status === "active"}
          isLoading={statusLoading === data?.id}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                status: value ? "active" : "inactive",
                id: data?.id,
              };
              await onUpdateStatus(payload);
            } catch (err) {}
          }}
        />
      ),
      sortable: true,
      sortableKey: "status",
    },
    {
      title: "Created",
      name: "created_at_ist",
      type: "date",
      Cell: (item: any) => {
        return <span>{item?.created_at_ist || "-"}</span>;
      },
      sortable: true,
      sortableKey: "created_at_ist",
    },
  ];

  const getUsersList = async () => {
    try {
      const { vendor_id, status, ...rest } = filter;
      const payload: any = {
        vendor_id,
        ...rest,
        filter_data: {
          status,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getUsers(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const { register, handleSubmit, reset } = useForm<any>();

  useEffect(() => {
    setTableData(usersList?.data?.length ? [...usersList?.data] : []);
  }, [usersList]);

  useEffect(() => {
    if (role.includes("list")) {
      getUsersList();
    }
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      {role.includes("list") ? (
        <div className="flex mb-3 gap-2 justify-end">
          <Button
            classNm={`!text-ev-white !border-0 appl-btn1`}
            onClick={() => {
              setShowDiv(!showDiv);
            }}
            className={`${!showDiv ? "!bg-chatlook-Cyan" : "!bg-[#22a99c]"}`}
          >
            <Icon name="FilterIcon" />
          </Button>
        </div>
      ) : null}
      {showDiv ? (
        <div className="cardcss mb-3">
          <form
            className="flex gap-2 items-end sm:flex-wrap"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Select
              name="vendor_id"
              label="Vendor"
              placeholder="Select Vendor"
              options={prepareVendorOptions(usersList?.vendorsList)}
              register={register}
            />
            <Select
              name="status"
              label="Status"
              placeholder="All"
              options={statusOptions}
              register={register}
            />
            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isFilter: true, isClear: true });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      {role.includes("list") ? (
        <div className="cardcss">
          <Table
            className="usersTable"
            columns={columns}
            isExpendable={false}
            isLoading={isLoading}
            data={tableData || []}
            setData={setTableData}
            count={usersList?.total_item || 0}
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isFilter: false,
              });
            }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    isExpendable={false}
                    item={item}
                    onClick={(item: any) => {}}
                  ></TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
    </>
  );
};

export default Users;
