import React, { useEffect } from "react";
import { useDashboardList } from "./dashboardSlice";
import { useGetDashboardMutation } from "./dashboardApi";
import delivery from "../../assets/images/delivery.svg";
import amount from "../../assets/images/amount.svg";
import orders from "../../assets/images/orders.svg";
import users from "../../assets/images/userss.svg";
import vendors from "../../assets/images/vendors.svg";
import { seperator } from "../../helperFunctions";

const images: any = {
  new_delivery: delivery,
  total_orders: orders,
  total_user: users,
  total_vendor: vendors,
  total_amount: amount,
};
const getBg: any = {
  total_orders: "#dcfaf8",
  new_delivery: "#ffe0eb",
  total_user: "#e7edff",
  total_vendor: "#e6dcfa",
  total_amount: "#fff5d9",
};

const Dashboard = () => {
  const dashboardList = useDashboardList();
  const [getDashboard] = useGetDashboardMutation();

  useEffect(() => {
    getDashboard();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="summaryMainBox grid  grid-cols-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxl:grid-cols-4 ">
        {Object.entries({
          "New Delivery": "new_delivery",
          "Total Customers": "total_user",
          "Today's Total Orders": "total_orders",
          "Total Vendors": "total_vendor",
          "Total Amount": "total_amount",
        }).map(([heading, dataKey]) => (
          <div
            key={dataKey}
            className={`summaryBox overflow-hidden relative bg-[${getBg?.[dataKey]}]`}
          >
            <div className="summaryBox1">
              <img src={images?.[dataKey]} alt={dataKey} />
            </div>
            <div className="summaryBox2">
              <p className="box-heading">{heading}</p>
              <p className="value">
                {seperator(
                  dashboardList?.data?.[dataKey],
                  dataKey === "total_amount"
                ) ?? "N/A"}
              </p>
            </div>
            {/* <div className={`absolute dashPt bg-[#fca2c2]`}></div>
            <div className={`absolute dashPt1 bg-[#c07d94] opacity-10`}></div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
