import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import {  useForm } from "react-hook-form";
import { Button, ControlledInput } from "../../ui";
import {
  useAddCategoryMutation,
  useUpdateCategoryMutation,
} from "./categoryApi";
import { responseToaster } from "../../helperFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ControlledSwitch from "../../ui/forms/ControlledSwitch";
import ControlledTextArea from "../../ui/forms/ControlledTextArea";
import SingleImagePicker from "../../ui/forms/SingleImagePicker";

interface ShopModalProps {
  onHide: () => void;
  isOpen: boolean;
  onGet?: () => void;
  categoryId?: number;
  categoryData?: any;
  level?: boolean;
}
interface FormValues {
  title: string;
  keyword: string;
  photo: File | Blob | string;
  parent_id?: any;
  status?: any;
}

const CategoryModal = (props: ShopModalProps) => {
  const { onHide, isOpen, onGet, categoryId, categoryData, level } = props;
  const [addCategory] = useAddCategoryMutation();
  const [updateCategory] = useUpdateCategoryMutation();
  const [addCtisLoading, setaddCtIsLoading] = useState<boolean>(false);
  const [image, setImage] = useState<any>();
  const [image1, setImage1] = useState<any>();
  const schema: any = yup.object({
    title: yup.string().required("Please enter a title"),
    // photo: yup.string().required("Please add a image"),
  });

  useEffect(() => {
    if (categoryData?.id) {
      setImage(
        `${process.env.REACT_APP_BASE_IMAGE_URL}${categoryData?.photo}`
      );
      setValue("title", categoryData?.title);
      setValue("keyword", categoryData?.keyword);
      setValue("parent_id", categoryData?.parent_id);
      setValue("photo", categoryData?.photo);
      setValue("status", categoryData?.status === "active" ? 1 : 0);
    } else {
      setValue("status", level ? 1 : 0);
    }
    // eslint-disable-next-line
  }, [categoryData, categoryData?.id, level]);

  const onSubmit = async (values: FormValues) => {
    try {
      setaddCtIsLoading(true);
      const formData: any = new FormData();
      formData.append("title", values.title);
      formData.append("keyword", values.keyword);
      if(image1){
        formData.append("photo", image1);
      }
      formData.append("parent_id", categoryId);
      formData.append("status", values?.status ? "active" : "inactive");

      const updateFormDate: any = new FormData();
      updateFormDate.append("title", values.title);
      updateFormDate.append("keyword", values.keyword);
      if (image1) {
        updateFormDate.append("photo", image1);
      }
      // updateFormDate.append("parent_id", values.parent_id);
      updateFormDate.append("id", categoryData.id);
      updateFormDate.append("status", values?.status ? "active" : "inactive");

      const res: any = categoryData?.id
        ? await updateCategory(updateFormDate).unwrap()
        : await addCategory(formData).unwrap();
      if (res?.status) {
        onClose();
        onGet?.();
      }
      responseToaster(res);
      setaddCtIsLoading(false);
    } catch (err) {
      setaddCtIsLoading(false);
    }
  };

  const prepareDefaultValues = () => {
    if (categoryData?.id) {
      return {
        title: categoryData?.title,
        keyword: categoryData?.keyword,
        status: categoryData?.status,
      };
    } else {
      return {
        status: level ? 1 : 0,
      };
    }
  };

  const {
    control,
    clearErrors,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...prepareDefaultValues(),
    },
  });

  const onClose = () => {
    reset();
    onHide();
    setImage("");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addCtisLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-6/12 xl:w-6/12 lg:w-8/12  md:w-9/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-9 px-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                {`${categoryData?.id ? "Update" : "Add"}`} category
              </h4>
              <div className="flex items-start gap-4">
                <ControlledSwitch
                  name="status"
                  label="Status"
                  control={control}
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 items-start gap-3 pt-2 pb-3">
                <ControlledInput
                  name="title"
                  className="md:col-span-2 lg:col-span-2 2xl:col-span-2 xl:col-span-2 col-span-1"
                  label="Title"
                  placeholder="Enter Title"
                  type="text"
                  autoFocus
                  errors={errors}
                  control={control}
                />
                <ControlledTextArea
                  name="keyword"
                  label="Keywords"
                  placeholder="Enter Keywords (abc,xyz...)"
                  control={control}
                  errors={errors}
                  className="textareacss"
                />
                <SingleImagePicker
                  setImage={setImage}
                  setValue={setValue}
                  setImage1={setImage1}
                  clearErrors={clearErrors}
                  image={image}
                  errors={errors}
                  name={"photo"}
                  watch={watch}
                  label={"Image"}
                />
              </div>
            </div>
            <div className="modal-footer pb-1">
              <div className="flex items-center justify-end gap-2">
                <Button
                  type="submit"
                  className="mt-[10px] !h-9 !text-sm"
                  isLoading={addCtisLoading}
                  isLoader={addCtisLoading}
                >
                  {categoryData?.id ? "Update" : "Submit"}
                </Button>
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={addCtisLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;
