import React, { memo } from "react";
import { IconProps } from ".";

export interface DashboardIconProps extends IconProps {}
const DashboardIcon = (props: DashboardIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M18.32 12.0001C20.92 12.0001 22 11.0001 21.04 7.72006C20.39 5.51006 18.49 3.61006 16.28 2.96006C13 2.00006 12 3.08006 12 5.68006V8.56006C12 11.0001 13 12.0001 15 12.0001H18.32Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[0] || ""}`}
      />
      <path
        d="M19.9999 14.7C19.0699 19.33 14.6299 22.69 9.57993 21.87C5.78993 21.26 2.73993 18.21 2.11993 14.42C1.30993 9.39001 4.64993 4.95001 9.25993 4.01001"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[1] || ""}`}
      />
    </svg>
  );
};

const MemoDashboardIcon = memo(DashboardIcon);
export default MemoDashboardIcon;
