import { Route, Routes } from "react-router";
import { Outlet } from "react-router-dom";
import { Fragment, Key } from "react";

// PROJECT IMPORT
import { Login, NotificationForm } from "../../features";
import { useUser } from "../../features/auth/authSlice";
import { Navigate } from "react-router-dom";
import Layout from "../../features/layouts/Layout";
import { Category } from "../../features/category";
import { Product } from "../../features/product";
import { Customers } from "../../features/customers";
import ProductForm from "../../features/product/ProductForm";
import Subscriptions from "../../features/subscriptions/Subscriptions";
import Coupon from "../../features/coupon/Coupon";
import Promotion from "../../features/promotion/Promotion";
import Settlement from "../../features/settlement/Settlement";
import Dashboard from "../../features/dashboard/Dashboard";
import Orders from "../../features/orders/Orders";
import OrdersDetail from "../../features/orders/OrdersDetails";
import DeliveredBy from "../../features/delivered/deliveredBy/DeliveredBy";
import DeliveredByDetail from "../../features/delivered/deliveredBy/DeliveredByDetails";
import { Ticket } from "../../features/ticket";
import { Transaction } from "../../features/subscriptions/transaction";
import Plan from "../../features/subscriptions/plan/Plan";
import Master from "../../features/master/Master";
import VendorList from "../../features/vendor/vendorList/VendorList";
import VendorPlan from "../../features/vendor/vendorPlan/VendorPlan";
import Vendor from "../../features/vendor/Vendor";
import Delivered from "../../features/delivered/Delivered";
import DeliveredPerson from "../../features/delivered/deliveredPerson/DeliveredPerson";
import { Tag } from "../../features/master/tag";
import Variant from "../../features/variant/Variant";
import VariantForm from "../../features/variant/VariantForm";
import { Video } from "../../features/video";
import { SubCategory } from "../../features/master/subCategory";
import { Policy } from "../../features/policy";
import { Subscribe } from "../../features/subscribe";
import User from "../../features/user/User";
import Role from "../../features/role/Role";
import Permission from "../../features/permission/Permission";
import RoleMangement from "../../features/roleMangement/RoleMangement";
import VendorForm from "../../features/vendor/vendorList/VendorForm";
import { RatingReview } from "../../features/RatingReview";
import Courier from "../../features/courier/Courier";
import CourierPartner from "../../features/courier/courierPartner/CourierPartner";
import City from "../../features/courier/city/City";
import PinCode from "../../features/courier/pinCode/PinCode";
import Settings from "../../features/developer/settings/Settings";
import Developer from "../../features/developer/Developer";
import SupportLogs from "../../features/developer/supportLogs/SupportLogs";

const AllRoutes = () => {
  const user = useUser();

  const Components: any = {
    Dashboard: Dashboard,
    Vendor: Vendor,
    "Vendor List": VendorList,
    "Vendor Plan": VendorPlan,
    Category: Category,
    Product: Product,
    Customers: Customers,
    Subscription: Subscriptions,
    Master: Master,
    Coupon: Coupon,
    Notification: NotificationForm,
    Promotion: Promotion,
    Settlement: Settlement,
    Orders: Orders,
    Delivery: Delivered,
    "Delivery By": DeliveredBy,
    "Delivery Person": DeliveredPerson,
    "Support Ticket": Ticket,
    Transaction: Transaction,
    Plan: Plan,
    Tag: Tag,
    Variant: Variant,
    Video: Video,
    "Sub Category": SubCategory,
    Policy: Policy,
    Subscribe: Subscribe,
    User: User,
    Role: Role,
    Permission: Permission,
    "Role-Management": RoleMangement,
    "Rating Review": RatingReview,
    Courier: Courier,
    "Courier Partner": CourierPartner,
    City: City,
    Pincode: PinCode,
    Developer,
    Settings: Settings,
    "Support Logs": SupportLogs,
  };

  const pathsToRedirect = [
    "/vendor",
    "/master",
    "/subscription",
    "/delivery",
    "/role-management",
    "/courier",
  ];

  const generatePath = () => {
    const isArrayItem: any = Object.entries?.(
      user?.data?.sidebar || {}
    )?.[0]?.[1];
    const path = Array.isArray(isArrayItem)
      ? Object.entries?.(user?.data?.sidebar || {})?.[0]?.[0]
          ?.split?.("_")?.[1]
          ?.toLowerCase()
      : isArrayItem?.route;

    const path1: any = Object.entries?.(user?.data?.sidebar || {})?.[0]?.[1];
    const path2 = path1?.[0]?.route;

    return Array.isArray(isArrayItem) ? path + path2 : path;
  };

  return (
    <Routes>
      {user?.data?.access_token ? (
        <Route path={``} element={<Layout />}>
          {Object.entries?.(user?.data?.sidebar || {})?.map(
            ([k, item]: any, index: number) => {
              const Element =
                Components?.[
                  Array.isArray(item) ? k?.split?.("_")?.[1] : item?.name
                ];
              if (!Element) {
                return <></>;
              }
              const generatedPath = `/${k?.split?.("_")?.[1]?.toLowerCase()}`;
              return (
                <Fragment key={index}>
                  {index === 0 ? (
                    <Route path="/" element={<Navigate to={generatePath()} />} />
                  ) : null}
                  {Array.isArray(item) ? (
                    <>
                      <Route
                        path={`${k?.split?.("_")?.[1]?.toLowerCase()}`}
                        element={<Element />}
                      >
                        {item?.map(
                          (
                            subItem: {
                              name: string | number;
                              route: string | undefined;
                            },
                            subIndex: Key | null | undefined
                          ) => {
                            const SubElement = Components[subItem?.name];
                            return (
                              <Route
                                key={subIndex}
                                path={subItem?.route?.substring(1)}
                                element={<SubElement />}
                              />
                            );
                          }
                        )}
                        {pathsToRedirect?.includes?.(generatedPath) ? (
                          <Route
                            path=""
                            element={<Navigate to={generatePath()} replace />}
                          />
                        ) : null}
                      </Route>
                    </>
                  ) : (
                    <Route path={item.route} element={<Element />} />
                  )}
                </Fragment>
              );
            }
          )}
          <Route path="/order/get-order/:id" element={<OrdersDetail />} />
          <Route
            path={`/master/product/get-product/create`}
            element={<ProductForm />}
          />
          <Route
            path="/master/product/get-product/:id"
            element={<ProductForm />}
          />
          <Route
            path={`/master/variant/get-variant-list/create`}
            element={<VariantForm />}
          />
          <Route
            path={`/master/variant/get-variant-list/:id`}
            element={<VariantForm />}
          />
          <Route
            path="/delivery/order/get-order/:id"
            element={<DeliveredByDetail />}
          />
          <Route
            path="/vendor/vendor/get-vendor/:id"
            element={<VendorForm />}
          />
          {Object.entries?.(user?.data?.sidebar || {})?.find(
            (item: any) => item?.route === "/product"
          ) ? (
            <>
              <Route path={`/product/create`} element={<ProductForm />} />
              <Route path="/product/:id" element={<ProductForm />} />
            </>
          ) : null}
          {Object.entries?.(user?.data?.sidebar || {})?.find(
            (item: any) => item?.route === "/subscriptions"
          ) ? (
            <>
              <Route path={`/subscriptions/plan`} element={<Plan />} />
              <Route path="/product/:id" element={<ProductForm />} />
            </>
          ) : null}
          {Object.entries?.(user?.data?.sidebar || {})?.find(
            (item: any) => item?.route === "/master"
          ) ? (
            <>
              <Route
                path={`/master/product/create`}
                element={<ProductForm />}
              />
              <Route path={`/master/product/:id`} element={<ProductForm />} />
            </>
          ) : null}
          {Object.entries?.(user?.data?.sidebar || {})?.find(
            (item: any) => item?.route === "/master"
          ) ? (
            <>
              <Route
                path={`/master/variant/create`}
                element={<VariantForm />}
              />
              <Route path={`/master/variant/:id`} element={<VariantForm />} />
            </>
          ) : null}

          {Object.entries?.(user?.data?.sidebar || {})?.find(
            (item: any) => item?.route === "/order"
          ) ? (
            <>
              <Route path="/order/get-order/:id" element={<OrdersDetail />} />
            </>
          ) : null}

          {Object.entries?.(user?.data?.sidebar || {})?.find(
            (item: any) => item?.route === "/delivered"
          ) ? (
            <>
              <Route
                path="/delivered/deliveredBy/:id"
                element={<DeliveredByDetail />}
              />
            </>
          ) : null}

          <Route path="*" element={<Navigate to={generatePath()} replace />} />
        </Route>
      ) : (
        <Route element={<Outlet />}>
          <Route index path="*" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
        </Route>
      )}
    </Routes>
  );
};

export default AllRoutes;
