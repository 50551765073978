import React, { memo } from "react";
import { IconProps } from ".";

export interface CourierIconProps extends IconProps {}
const CourierIcon = (props: CourierIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        stroke={stroke}
        className={`${pathClassNames?.[0] || ""}`}
        d="M22 17a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9.5C2 7 4 5 6.5 5H18c2.2 0 4 1.8 4 4v8Z"
      />
      <polyline
        stroke={stroke}
        className={`${pathClassNames?.[1] || ""}`}
        points="15,9 18,9 18,11"
      />
      <path
        stroke={stroke}
        className={`${pathClassNames?.[2] || ""}`}
        d="M6.5 5C9 5 11 7 11 9.5V17a2 2 0 0 1-2 2"
      />
      <line
        stroke={stroke}
        className={`${pathClassNames?.[3] || ""}`}
        x1="6"
        x2="7"
        y1="10"
        y2="10"
      />
    </svg>
  );
};

const MemoCourierIcon = memo(CourierIcon);
export default MemoCourierIcon;
