import React, { memo } from "react";
import { IconProps } from ".";

export interface FilterIconProps extends IconProps {}
const FilterIcon = (props: FilterIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={stroke}
          d="M3 4.5A1.5 1.5 0 0 1 4.5 3h15A1.5 1.5 0 0 1 21 4.5v2.086A2 2 0 0 1 20.414 8L15 13.414v7.424a1.1 1.1 0 0 1-1.592.984l-3.717-1.858A1.25 1.25 0 0 1 9 18.846v-5.432L3.586 8A2 2 0 0 1 3 6.586zM5 5v1.586l5.56 5.56a1.5 1.5 0 0 1 .44 1.061v5.175l2 1v-6.175c0-.398.158-.78.44-1.06L19 6.585V5z"
          stroke={stroke}
          className={`${pathClassNames?.[0] || ""}`}
        />
      </g>
    </svg>
  );
};

const MemoFilterIcon = memo(FilterIcon);
export default MemoFilterIcon;
