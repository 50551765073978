// UI IMPORT
import { Icon, IconKeys } from "../icons";

// THIRD - PARTY IMPORT
import Picker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface DatePickerProps {
  className?: string;
  inputClassName?: string;
  name?: any;
  label?: string;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  selectesStart?: boolean;
  selectsEnd?: boolean;
  selectsRange?: boolean;
  onChange?: (dates?: any) => void;
  icon?: IconKeys;
  rightIcon?: IconKeys;
  placeholder?: string;
  errors?: any;
  startDate?: any;
  endDate?: any;
  disabled?: boolean;
}

const DatePicker = (props: DatePickerProps) => {
  const {
    className = "",
    inputClassName = "",
    label,
    icon,
    rightIcon,
    errors,
    value,
    placeholder = "Enter Date",
    name,
    onChange,
    ...rest
  } = props;

  const message = errors?.[name]?.message || "";
  return (
    <div className={`${className} flex flex-col relative`}>
      {label ? (
        <label className="text-ev-secondary text-sm">{label}</label>
      ) : null}
      {icon ? (
        <Icon className="absolute me-4 text-xl ms-4" name={icon} />
      ) : null}
      <Picker
        className={`${inputClassName} text-sm ${icon ? "pl-11" : "pl-3"} ${
          rightIcon ? "pr-11" : "pr-3"
        } ${
          message ? "border-red-500" : ""
        } w-[195px] text-base h-9 rounded-md bg-chatlook-gray outline-none border-[1px] border-[#EFF0F6] focus:border-chatlook-primary`}
        placeholderText={placeholder}
        dateFormat="dd/MM/yyyy"
        selected={!Array.isArray(value) ? value : null}
        startDate={Array.isArray(value) ? value?.[0] : null}
        endDate={Array.isArray(value) ? value?.[1] : null}
        onChange={(dates) => onChange?.(dates)}
        {...rest}
      />
      {rightIcon ? (
        <Icon className="absolute right-0 me-4 text-xl" name={rightIcon} />
      ) : null}
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default DatePicker;
