import { api } from "../../utills/api";

export const ordersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrders: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/order/get-order",
        body: params,
        method: "POST",
      }),
    }),
    getOrder: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/order/get-order-by-id",
        body: params,
        method: "POST",
      }),
    }),
    deleteOrder: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/order/delete-order",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetOrdersMutation,
  useGetOrderMutation,
  useDeleteOrderMutation,
} = ordersApi;
export const {
  endpoints: { getOrders, getOrder, deleteOrder },
} = ordersApi;
