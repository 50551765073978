import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { ordersApi } from "./ordersApi";

interface UserState {
  ordersList: any;
}

const initialState: UserState = {
  ordersList: {},
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    clearOrders: () => initialState,

    setOrders: (state, { payload }) => {
      state.ordersList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ordersApi.endpoints.getOrders.matchFulfilled,
      (state, { payload }) => {
        state.ordersList = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default ordersSlice.reducer;
export const { clearOrders, setOrders } = ordersSlice.actions;

export const selectOrdersList = (state: RootState) => state.orders.ordersList;
export const useOrdersList = () => {
  const ordersList = useSelector(selectOrdersList);
  return useMemo(() => ordersList, [ordersList]);
};
