import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { supportLogsApi } from "./supportLogsApi";

interface UserState {
  supportLogsList: any;
  supportLogsData: any;
  productSupportLogs: any;
}

const initialState: UserState = {
  supportLogsList: {},
  supportLogsData: {},
  productSupportLogs: {},
};

export const supportLogsSlice = createSlice({
  name: "supportLogs",
  initialState,
  reducers: {
    clearSupportLogs: () => initialState,
    setSupportLogs: (state, { payload }) => {
      state.supportLogsList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      supportLogsApi.endpoints.getSupportLogs.matchFulfilled,
      (state, action: any) => {
        state.supportLogsList = action?.payload;
      }
    );
  },
});

export default supportLogsSlice.reducer;
export const { clearSupportLogs, setSupportLogs } = supportLogsSlice.actions;

export const selectSupportLogsList = (state: RootState) => state.supportLogs.supportLogsList;
export const useSupportLogsList = () => {
  const supportLogsList = useSelector(selectSupportLogsList);
  return useMemo(() => supportLogsList, [supportLogsList]);
};

export const selectSupportLogsData = (state: RootState) => state.supportLogs.supportLogsData;
export const useSupportLogsData = () => {
  const supportLogsData = useSelector(selectSupportLogsData);
  return useMemo(() => supportLogsData, [supportLogsData]);
};
