import React, { createRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  useAddVariantMutation,
  useGetVariantByIdMutation,
  useUpdateVariantMutation,
} from "./variantApi";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  prepareVendorOptions,
  responseToaster,
  showToaster,
} from "../../helperFunctions";
import { Button, ControlledDropDown, ControlledInput, Loader } from "../../ui";
import { IoIosCloseCircle, IoMdAdd } from "react-icons/io";
import { Form } from "reactstrap";
import { MdEdit } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import CustomeBreadcrumbs from "../common/CustomeBreadcrumbs";
import { useRole } from "../auth/authSlice";
import { useVariantList } from "./variantSlice";

const VariantForm = () => {
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);
  const [chipValues, setChipValues] = useState<any>([]);
  const [editedIndx, setEditedIndx] = useState<any>(null);
  const [addVariant] = useAddVariantMutation();
  const [updateVariant] = useUpdateVariantMutation();
  const [getVariantById, { isLoading }] = useGetVariantByIdMutation();
  const variantList = useVariantList();
  const inputRef: any = createRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const role = useRole("variant");

  if (!role.includes("create") && !id) {
    navigate("/");
  }
  if (!role.includes("edit") && id) {
    navigate("/");
  }

  if (!role.includes("list")) {
    navigate("/");
  }

  const breadcrumbs = [
    { label: "Master" },
    { label: "Variant", link: "/master/variant/get-variant-list" },
    { label: id ? "Update" : "Add" },
  ];

  const schema: any = yup.object({
    variant_name: yup.string().required("Please enter option"),
    variant:
      chipValues.length === 0
        ? yup.string().required("Please add at least one variant")
        : yup.string().nullable(),
  });
  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    reset();
    setChipValues([]);
    setEditedIndx(null);
  };

  const getVariantList = async () => {
    try {
      const payload: any = {
        id,
      };
      const res: any = await getVariantById(payload);
      if (res?.data?.status) {
        setFormData(res?.data?.data);
      }
    } catch (error) {}
  };

  const setFormData = (variantData: any) => {
    if (variantData?.id) {
      setChipValues(variantData?.variants_value);
      setValue("variant_name", variantData?.variant_name);
    }
  };

  useEffect(() => {
    if (id) {
      getVariantList();
    }
    // eslint-disable-next-line
  }, [id]);


  const onSubmit = async (values: any) => {
    try {
      if (!chipValues?.length) {
        setError("variant", {
          type: "manual",
          message: "Please add at least one variant",
        });
        return;
      }
      setaddCpIsLoading(true);
      const { variant_name } = values;
      const payload: any = {
        variant_name,
        value: chipValues,
      };

      const res: any = id
        ? await updateVariant({ id, ...payload }).unwrap()
        : await addVariant(payload).unwrap();
      if (res?.status && !res?.warning) {
        onClose();
        setChipValues([]);
        navigate("/master/variant/get-variant-list");
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  const handleChipAdd = () => {
    const value = watch("variant");
    const vendorId = watch("vendor_id");

    if (value && value.trim() !== "") {
      const vendor_id = vendorId ? vendorId : 0;
      const alreadyExists = chipValues
        .filter((_: any, index: number) => index !== editedIndx)
        .some((chipValue: any) => {
          if (!vendor_id) {
            return chipValue.value === value;
          } else {
            return (
              chipValue.value === value && chipValue.vendor_id === vendor_id
            );
          }
        });

      if (editedIndx) {
        if (!alreadyExists) {
          const clonedChip = [...chipValues];
          clonedChip[editedIndx] = {
            value,
            vendor_id: vendorId,
          };
          setChipValues(clonedChip);
          setEditedIndx(null);
          setValue("variant", "");
          setValue("vendor_id", "");
        } else {
          showToaster("Already exist", "Warning");
        }
      } else {
        if (!alreadyExists) {
          const chipValue: any = { value, vendor_id };
          setChipValues([...chipValues, chipValue]);
          setValue("variant", "");
          setValue("vendor_id", "");
          clearErrors("variant");
        } else {
          showToaster("Already exist", "Warning");
        }
      }

      inputRef.current?.focus();
    }
  };

  const handleChipRemove = (index: number) => {
    const updatedChips = chipValues?.filter((_: any, i: number) => i !== index);
    setChipValues(updatedChips);
  };

  const handleChipEdit = (index: number) => {
    const chipValue = chipValues[index];
    setValue("variant", chipValue?.value);
    setValue("vendor_id", chipValue?.vendor_id);
    setEditedIndx(index);
  };
  const handleChipCancel = () => {
    setValue("variant", "");
    setValue("vendor_id", "");
    setEditedIndx(null);
  };

  if (isLoading) {
    return (
      <>
        <div className="flex items-center justify-center loader_height">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body cardcss">
            <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 items-start gap-3 pt-2 pb-3">
              <ControlledInput
                name="variant_name"
                label="Options"
                placeholder="Enter Option"
                type="text"
                className="col-span-2"
                errors={errors}
                control={control}
              />
            </div>
            <div className="flex items-start gap-3 sm:grid sm:grid-cols-1">
              <ControlledDropDown
                name="vendor_id"
                label="Vendor"
                className="sm:col-span-2"
                placeholder="Select Vendor"
                options={prepareVendorOptions(variantList?.vendorsList)}
                control={control}
                errors={errors}
              />
              <ControlledInput
                name="variant"
                refa={inputRef}
                label="Variant"
                placeholder="Enter Variant"
                type="text"
                errors={errors}
                control={control}
              />
              <div className="flex gap-2">
                <Button onClick={handleChipAdd} className="mt-5">
                  {editedIndx !== null ? <MdEdit /> : <IoMdAdd />}
                </Button>
                {editedIndx !== null ? (
                  <Button onClick={handleChipCancel} className="mt-5">
                    <IoIosCloseCircle />
                  </Button>
                ) : null}
              </div>
            </div>
          </div>

          {chipValues?.length ? (
            <div className="cardcss grid grid-cols-2 gap-5 mt-2">
              <div className="border-r border-gray-300 pr-5">
                <label className="text-sm text-gray-600 font-medium mb-2">
                  Admin
                </label>
                <div className="flex flex-wrap gap-2">
                  {chipValues?.map((chip: any, index: number) => {
                    return !chip?.vendor_id ? (
                      <span
                        key={index}
                        className="flex items-center gap-2 text-xs bg-gray-200 px-3 py-1 my-1 rounded-full w-fit"
                      >
                        {chip?.value}
                        <button
                          type="button"
                          onClick={() => handleChipEdit(index)}
                          className="chip-remove"
                        >
                          <MdEdit color="#00000042" />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleChipRemove(index)}
                          className="chip-remove"
                        >
                          <IoIosCloseCircle color="#00000042" />
                        </button>
                      </span>
                    ) : null;
                  })}
                </div>
              </div>
              <div>
                <label className="text-sm text-gray-600 font-medium mb-2">
                  Vendor
                </label>
                <div className="flex flex-wrap gap-2">
                  {chipValues?.map((chip: any, index: number) => {
                    const vendor = variantList?.vendorsList?.find(
                      (vendor: any) => vendor.id === chip?.vendor_id
                    );
                    return chip?.vendor_id ? (
                      <span
                        key={index}
                        className="flex items-center gap-2 text-xs bg-gray-200 px-3 py-1 my-1 rounded-full w-fit"
                      >
                        {vendor?.name} - {chip?.value}
                        <button
                          type="button"
                          onClick={() => handleChipEdit(index)}
                          className="chip-remove"
                        >
                          <MdEdit color="#00000042" />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleChipRemove(index)}
                          className="chip-remove"
                        >
                          <IoIosCloseCircle color="#00000042" />
                        </button>
                      </span>
                    ) : null;
                  })}
                </div>
              </div>
            </div>
          ) : null}

          <div className="cardcss mt-2">
            <div className="flex items-center justify-end gap-2">
              <Button
                type="submit"
                className="!h-9 !text-sm"
                isLoading={addCpisLoading}
                isLoader={addCpisLoading}
              >
                {id ? "Update" : "Add"}
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm !h-9"
                disabled={addCpisLoading}
                onClick={() => {
                  onClose();
                  navigate("/master/variant/get-variant-list");
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default VariantForm;
