import { api } from "../../utills/api";

export const couponApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCoupon: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/coupon/list-coupon",
        body: params,
        method: "POST",
      }),
    }),
    updateCoupon: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/coupon/update-coupon",
        body: params,
        method: "POST",
      }),
    }),
    addCoupon: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/coupon/add-coupon",
        body: params,
        method: "POST",
      }),
    }),
    deleteCoupon: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/coupon/delete-coupon",
        body: params,
        method: "POST",
      }),
    }),
    getCouponById: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/coupon/get-coupon-by-id",
        body: params,
        method: "POST",
      }),
    }),
    getProductsForCoupon: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/product/get-product",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetCouponMutation,
  useUpdateCouponMutation,
  useAddCouponMutation,
  useDeleteCouponMutation,
  useGetCouponByIdMutation,
  useGetProductsForCouponMutation
} = couponApi;
export const {
  endpoints: {
    getCoupon,
    addCoupon,
    updateCoupon,
    deleteCoupon,
    getCouponById,
    getProductsForCoupon
  },
} = couponApi;
