import { api } from "../../../utills/api";

export const vendorPlanApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVendorPlan: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/plan/get-vendor-plan",
        body: params,
        method: "POST",
      }),
    }),
    updateVendorPlan: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/plan/update-vendor-plan",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetVendorPlanMutation, useUpdateVendorPlanMutation } =
  vendorPlanApi;
export const {
  endpoints: { getVendorPlan, updateVendorPlan },
} = vendorPlanApi;
