import React, { Fragment, useEffect, useState } from "react";
import { Button, Table, TableRow } from "../../ui";
import { FILTER } from "../../constant";
import { useDeleteUserMutation, useGetUserMutation } from "./userApi";
import { useUserList, useVendorUserList } from "./userSlice";
import UserModal from "./UserModal";
import { MdDeleteOutline, MdEdit, MdLockReset } from "react-icons/md";
import { Modal } from "../common";
import { responseToaster } from "../../helperFunctions";
import Tooltip from "../../ui/elements/Tooltip";
import { useNavigate } from "react-router-dom";
import { useRole } from "../auth/authSlice";

const User = () => {
  const [getUser, { isLoading }] = useGetUserMutation();
  const userList = useUserList();
  const userVendorList = useVendorUserList();
  const [deleteUser, { isLoading: isDeleteLoading }] = useDeleteUserMutation();
  const [isUserModal, setIsUserModal] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();
  const [userData, setUserData] = useState({});
  const [pwdData, setPwdData] = useState<any>(null);
  const navigate = useNavigate();
  const role = useRole("subUser");

  if (!role.includes("list")) {
    navigate("/");
  }
  const handleAddUser = () => {
    setIsUserModal(true);
  };

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Name",
      name: "name",
      sortable: true,
      sortableKey: "name",
    },
    {
      title: "Email",
      name: "email",
      sortable: true,
      sortableKey: "email",
    },
    {
      title: "Created",
      name: "created_at_ist",
      sortable: true,
      sortableKey: "created_at_ist",
      type: "date",
    },
    {
      title: "Action",
      name: "action",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            <Tooltip text="Reset Password">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setPwdData(item);
                }}
                disabled={!role.includes("edit")}
              >
                <MdLockReset />
              </Button>
            </Tooltip>
            <Tooltip text="Edit">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setIsUserModal(true);
                  setUserData(item);
                }}
                disabled={!role.includes("edit")}
              >
                <MdEdit />
              </Button>
            </Tooltip>
            <Tooltip text="Delete" className="right-0">
              <Button
                className="!h-8 w-8 !p-0"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeleteData(item);
                }}
                disabled={!role.includes("delete")}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onUserDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteUser(payload).unwrap();
      if (res) {
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
      responseToaster(res);
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getUserList = async () => {
    try {
      const payload: any = {
        ...filter,
        filter_data: {
          vendor_id: filter?.vendor_id,
        },
      };

      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getUser(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    getUserList();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setTableData(userList?.data?.length ? [...userList?.data] : []);
  }, [userList, userVendorList]);

  return (
    <>
      <div>
        {role.includes("create") ? (
          <div className="flex mb-3 gap-2 justify-end">
            <div className="flex justify-end">
              <Button onClick={() => handleAddUser()}>Add User</Button>
            </div>
          </div>
        ) : null}

        {role.includes("list") ? (
          <div className="cardcss">
            <Table
              columns={columns}
              className="userTable"
              isExpendable={false}
              isLoading={isLoading}
              data={tableData || []}
              setData={setTableData}
              count={userList?.total_item}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                      onClick={(item: any) => {}}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        ) : null}
      </div>
      <UserModal
        isOpen={isUserModal || pwdData}
        onGet={getUserList}
        pwdData={pwdData}
        userData={userData}
        onHide={() => {
          setIsUserModal(false);
          setUserData({});
          setPwdData(null);
        }}
      />
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onUserDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default User;
