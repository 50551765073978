import React, { memo } from "react";
import { IconProps } from ".";

export interface MasterIconProps extends IconProps {}
const MasterIcon = (props: MasterIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "black",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M22 11V17C22 21 21 22 17 22H7C3 22 2 21 2 17V7C2 3 3 2 7 2H8.5C10 2 10.33 2.44 10.9 3.2L12.4 5.2C12.78 5.7 13 6 14 6H17C21 6 22 7 22 11Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        className={`${pathClassNames?.[0] || ""}`}
      />
      <path
        d="M8 2H17C19 2 20 3 20 5V6.38"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[1] || ""}`}
      />
    </svg>
  );
};

const MemoMasterIcon = memo(MasterIcon);
export default MemoMasterIcon;
