import React from "react";
import { Button } from "../../ui";
import { useAppDispatch } from "../../utills/hooks";
import { verifyingUser } from "../../helperFunctions";

const TwoFactorAuthModal = ({ isOpen, onHide, svgString }: any) => {
  const dispatch = useAppDispatch();
  if (!isOpen) return null;

  const handleOtpModal = () => {
    onHide?.();
    verifyingUser(dispatch, true);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-60"
        /*  onClick={() => {
          onHide();
        }} */
      />
      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-4/12 xl:w-4/12 lg:w-5/12  md:w-6/12 sm:w-10/12 mx-auto rounded-lg shadow-lg z-50 overflow-y-auto p-4">
        <div className="modal-header flex justify-between">
          <h4 className="modal-title mb-4 font-bold capitalize">
            Two factor authentication
          </h4>
        </div>
        <div className="modal-body">
          <div className="flex flex-col items-center">
            {/* <div
              dangerouslySetInnerHTML={{ __html: `data:image/png;base64,${svgString}` }}
              style={{ width: "200px", height: "200px" }}
            /> */}
            <img
              src={svgString}
              alt="QR Code"
              style={{ width: "200px", height: "200px" }}
            />
            <p className="mt-4 text-center">
              To set up two-factor authentication, please scan the QR code above
              with Google Authenticator.
            </p>
          </div>
        </div>
        <div className="modal-footer pb-2">
          <div className="flex items-center justify-end gap-2">
            <Button
              className="mt-[10px]  !text-sm !h-9"
              onClick={() => handleOtpModal()}
            >
              Scanned
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuthModal;
