import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { responseToaster } from "../../../helperFunctions";
import { useUpdateSubscriptionsMutation } from "./subscriptionsApi";
import { setSubscriptions } from "./subscriptionsSlice";
import { DropDown, Loader } from "../../../ui";
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";

const statusOptions = [
  {
    value: "Standard",
    label: "Standard",
  },
  {
    value: "Priority",
    label: "Priority",
  },
];

const UpdateDD = (props: any) => {
  const { data, tableData, currKey, isDisable } = props;
  const dispatch = useDispatch();
  const [updateSubscriptions] = useUpdateSubscriptionsMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [support, setSupport] = useState<any>();

  const onUpdate = async () => {
    try {
      setStatusLoading(data?.id);
      const payload: any = {
        id: data?.id,
        [currKey]: support,
      };
      const res = await updateSubscriptions(payload).unwrap();

      const index = tableData?.findIndex(
        (item: { id: any }) => item?.id === data?.id
      );
      if (index > -1) {
        const newData = [...tableData];
        newData[index] = {
          ...newData[index],
          [currKey]: support,
        };
        dispatch(setSubscriptions({ ...tableData, data: newData }));
      }
      responseToaster(res);
      setStatusLoading(false);
    } catch (error) {
      setStatusLoading(false);
    }
  };

  return (
    <>
      <div className={`${isOpen ? "cell-order-white" : "cell-order"}`}>
        <div>
          {isOpen ? (
            <form className="flex tableinput">
              <DropDown
                isDefaultOption={false}
                options={statusOptions}
                value={support}
                onChange={(e: any) => {
                  setSupport(e);
                }}
              />

              {statusLoading ? (
                <Loader loaderClassName="w-[15px] ml-2" />
              ) : (
                <div className="flex gap-1 ml-1">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-smallsize"
                    onClick={(e) => {
                      e.preventDefault();
                      onUpdate();
                    }}
                  >
                    <IoMdCheckmark
                      color="var(--chatlook-primary--)"
                      size={18}
                    />
                  </Button>
                  <Button
                    color="danger"
                    className="btn-smallsize"
                    onClick={() => {
                      setIsOpen(false);
                      setSupport(null);
                    }}
                  >
                    <RxCross2 color="red" size={18} />
                  </Button>
                </div>
              )}
            </form>
          ) : (
            <span
              className="cursor-pointer"
              style={{ color: "#ff9300" }}
              onClick={() => {
                if (isDisable) {
                  setIsOpen(true);
                  setSupport(data?.[currKey]);
                }
              }}
            >
              {data?.[currKey]}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default UpdateDD;
