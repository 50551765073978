import React, { memo } from "react";
import { IconProps } from ".";

export interface VendorListIconProps extends IconProps {}
const VendorListIcon = (props: VendorListIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
    className={`${className}`}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    {...rest}
  >
    <path
      d="M12.3701 8.88H17.6201"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`${pathClassNames?.[0] || ""}`}
    />
    <path
      d="M6.37988 8.88L7.12988 9.63L9.37988 7.38"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`${pathClassNames?.[1] || ""}`}
    />
    <path
      d="M12.3701 15.88H17.6201"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`${pathClassNames?.[2] || ""}`}
    />
    <path
      d="M6.37988 15.88L7.12988 16.63L9.37988 14.38"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`${pathClassNames?.[3] || ""}`}
    />
    <path
      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`${pathClassNames?.[4] || ""}`}
    />
  </svg>
  );
};

const MemoVendorListIcon = memo(VendorListIcon);
export default MemoVendorListIcon;
