import React, { useEffect, useState } from "react";
import { Icon } from "../icons";

type Position = "Default" | "Open" | "Close";
interface AccordionProps {
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  render?: any;
  header?: any;
  position?: Position;
  children?: any;
}
const Accordion = (props: AccordionProps) => {
  const {
    className = "",
    headerClassName = "",
    contentClassName = "",
    header,
    children,
    render,
    position = "Default",
  } = props;

  const [isOpen, setIsOpen] = useState(true);

  const prepareIsOpen = () =>
    position === "Close" ? false : position === "Open" ? true : isOpen;

  useEffect(() => {
    setIsOpen(true);
  }, [render]);

  return (
    <>
      <div className={`${className} flex flex-col  gap-2 border  rounded-md `}>
        <div
          className={`${headerClassName} flex justify-between px-4 py-2  rounded-md bg-chatlook-gray ${
            prepareIsOpen() ? "border-b" : ""
          } cursor-pointer`}
          onClick={() => {
            if (position === "Default") {
              setIsOpen(!isOpen);
            }
          }}
        >
          {header}
          <div className="flex justify-end items-start">
            <Icon name={prepareIsOpen() ? "UpArrowIcon" : "DownArrowIcon"} />
          </div>
        </div>
        {prepareIsOpen() ? (
          <div className={`${contentClassName} py-2 `}>{children}</div>
        ) : null}
      </div>
    </>
  );
};

export default Accordion;
