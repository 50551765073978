import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const CustomeBreadcrumbs = ({ breadcrumbs }: any) => {
  return (
    <div className="breadcrumbs mb-3 font-bold text-ev-secondary sm:flex-wrap">
      {breadcrumbs.map(
        (
          breadcrumb: {
            link: any;
            label: any;
          },
          index: number
        ) => (
          <span key={index} className="flex justify-center items-center">
            {index > 0 && (
              <span className="separator">
                <MdKeyboardArrowRight size={15} />
              </span>
            )}
            {breadcrumb?.link ? (
              <Link to={breadcrumb.link}>{breadcrumb.label}</Link>
            ) : (
              <span
                className={`${
                  breadcrumbs?.length - 1 === index && "activePage"
                }`}
              >
                {breadcrumb.label}
              </span>
            )}
          </span>
        )
      )}
    </div>
  );
};

export default CustomeBreadcrumbs;
