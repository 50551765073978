import { api } from "../../utills/api";

export const notificationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVendors: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/vendor/get-vendor",
        body: params,
        method: "POST",
      }),
    }),
    getUsers: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/user/get-user",
        body: params,
        method: "POST",
      }),
    }),
    sendNotification: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/notification/send-notification",
        body: params,
        method: "POST",
      }),
    }),
    getFilter: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/notification/get-filter",
        body: params,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetVendorsMutation,
  useGetUsersMutation,
  useSendNotificationMutation,
  useGetFilterMutation,
} = notificationApi;
export const {
  endpoints: { getVendors, getUsers, sendNotification, getFilter },
} = notificationApi;
