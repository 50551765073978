import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { usersApi } from "./usersApi";

interface UserState {
  usersList: any;
}

const initialState: UserState = {
  usersList: {},
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearUsers: () => initialState,
    setUsers: (state, { payload }) => {
      state.usersList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      usersApi.endpoints.getUsers.matchFulfilled,
      (state, { payload }) => {
        state.usersList = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default usersSlice.reducer;
export const { clearUsers, setUsers } = usersSlice.actions;

export const selectUsersList = (state: RootState) => state.users.usersList;
export const useUsersList = () => {
  const usersList = useSelector(selectUsersList);
  return useMemo(() => usersList, [usersList]);
};
