import { api } from "../../utills/api";

export const ticketApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTicket: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/ticket/list-ticket",
        body: params,
        method: "POST",
      }),
    }),
    updateTicket: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/ticket/update-ticket-status",
        body: params,
        method: "POST",
      }),
    }),
    replyTicket: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/ticket/ticket-reply",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetTicketMutation,
  useUpdateTicketMutation,
  useReplyTicketMutation,
} = ticketApi;
export const {
  endpoints: { getTicket, updateTicket, replyTicket },
} = ticketApi;
