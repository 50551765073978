import { InputTypes } from "../theme";
import { Icon, IconKeys } from "../icons";

export interface InputProps {
  className?: string;
  inputClassName?: string;
  lableClassName?: string;
  name: string;
  label?: string;
  value?: any;
  onChange?: (e?: any) => void;
  righticonClick?: (e?: any) => void;
  type?: InputTypes;
  icon?: IconKeys;
  rightIcon?: IconKeys | null;
  placeholder?: string;
  errors?: any;
  err?: any;
  register?: any;
  disabled?: boolean;
  min?: number;
  autoComplete?: any;
  max?: number;
  isMultiple?: boolean;
  autoFocus?: any;
  refa?: any;
}

const Input = (props: InputProps) => {
  const {
    className = "",
    inputClassName = "",
    lableClassName = "",
    label,
    icon,
    rightIcon,
    righticonClick,
    errors,
    err,
    register,
    name,
    min,
    max,
    type,
    isMultiple = false,
    refa,
    ...rest
  } = props;

  const getRegister = (register: any) =>
    register && name ? { ...register?.(name) } : {};

  const message = errors?.[name]?.message || err || "";
  return (
    <div
      className={`${className} w-full flex flex-col justify-center relative`}
      onClick={(e) => e.stopPropagation()}
    >
      {label ? (
        <label className={`${lableClassName} text-sm text-ev-secondary`}>
          {label}
        </label>
      ) : null}
      <div className="flex flex-col justify-center">
        {icon ? (
          <Icon className="absolute me-4 text-xl ms-4" name={icon} />
        ) : null}
        <input
          type={type}
          minLength={min}
          maxLength={max}
          ref={refa}
          name={name}
          multiple={isMultiple}
          className={`${inputClassName} text-sm ${icon ? "pl-11" : "pl-3"} ${
            rightIcon ? "pr-11" : "pr-3"
          } ${
            message ? "border-red-500" : ""
          } w-full text-base h-9 rounded-md bg-chatlook-gray outline-none border-[1px] border-[#EFF0F6] focus:border-chatlook-primary`}
          {...(type === "number" && {
            onFocus: (e) =>
              e.target.addEventListener(
                "wheel",
                (e) => {
                  e.preventDefault();
                },
                { passive: false }
              ),
          })}
          {...getRegister(register)}
          {...rest}
        />
        {rightIcon ? (
          <Icon
            className="absolute right-0 me-4 text-xl"
            name={rightIcon}
            onClick={righticonClick}
          />
        ) : null}
      </div>
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default Input;
