import { api } from "../../../utills/api";

export const transactionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTransaction: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/subscription/get-subscription-transaction",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetTransactionMutation } = transactionApi;
export const {
  endpoints: { getTransaction },
} = transactionApi;
