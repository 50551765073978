import React, { Fragment, useEffect, useState } from "react";
import { Button, Table, TableRow } from "../../../ui";
import { FILTER } from "../../../constant";
import {
  useDeleteCourierPartnerMutation,
  useGetCourierPartnerMutation,
  useUpdateCourierPartnerMutation,
} from "./courierPartnerApi";
import {
  setCourierPartner,
  useCourierPartnerList,
} from "./courierPartnerSlice";
import CourierPartnerModal from "./CourierPartnerModal";
import { MdEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "../../common";
import Switch from "../../../ui/forms/Switch";
import { responseToaster } from "../../../helperFunctions";
import { useDispatch } from "react-redux";
import Tooltip from "../../../ui/elements/Tooltip";
import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../auth/authSlice";

const CourierPartner = () => {
  const [getCourierPartner, { isLoading }] = useGetCourierPartnerMutation();
  const courierPartnerList = useCourierPartnerList();
  const [updateCourierPartner] = useUpdateCourierPartnerMutation();

  const [deleteCourierPartner, { isLoading: isDeleteLoading }] =
    useDeleteCourierPartnerMutation();
  const [isCourierPartnerModal, setIsCourierPartnerModal] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();
  const [courierPartnerData, setCourierPartnerData] = useState({});
  const [statusLoading, setStatusLoading] = useState(false);
  const navigate = useNavigate();
  const role = useRole("courier");

  if (!role.includes("list")) {
    navigate("/");
  }
  const breadcrumbs = [{ label: "Courier" }, { label: "Courier Partner" }];

  const dispatch = useDispatch();

  const handleAddCourierPartner = () => {
    setIsCourierPartnerModal(true);
  };

  const onUpdateStatus = async (payload: any) => {
    try {
      setStatusLoading(payload?.id);
      const res = await updateCourierPartner(payload).unwrap();

      const index = tableData?.findIndex(
        (item: { id: any }) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...tableData];
        newData[index] = {
          ...newData[index],
          status: payload?.status,
        };
        dispatch(
          setCourierPartner({
            ...courierPartnerList,
            data: newData,
          })
        );
      }
      responseToaster(res);
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Name",
      name: "name",
      sortable: true,
      sortableKey: "name",
    },
    {
      title: "localfirst courier",
      name: "is_local",
      sortable: true,
      sortableKey: "is_local",
      Cell: (data: any) => {
        return <span>{data?.is_local ? "Yes" : "No"}</span>;
      },
    },
    {
      title: "Status",
      name: "status",
      sortable: true,
      sortableKey: "status",
      Cell: (data: any) => (
        <Switch
          checked={data?.status === 1}
          // checked={data?.status === "active"}
          isLoading={statusLoading === data?.id}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                status: value ? 1 : 0,
                // status: value ? "active" : "inactive",
                id: data?.id,
              };
              await onUpdateStatus(payload);
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Action",
      name: "action",
      Cell: (data: any) => (
        <>
          <div className="flex gap-1">
            <Tooltip text="Edit">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setIsCourierPartnerModal(true);
                  setCourierPartnerData(data);
                }}
                disabled={!role.includes("edit")}
              >
                <MdEdit />
              </Button>
            </Tooltip>
            <Tooltip text="Delete" className="right-0">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setDeleteData(data);
                  setIsDeleteModalOpen(true);
                }}
                disabled={!role.includes("delete")}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const onCourierPartnerDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteCourierPartner(payload).unwrap();
      if (res) {
        responseToaster(res);
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getCourierPartnerList = async () => {
    try {
      // eslint-disable-next-line
      const { vendor_id = 0, status, ...rest } = filter;
      const payload: any = {
        ...rest,
        filter_data: {
          status,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getCourierPartner(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    if (role.includes("list")) {
      getCourierPartnerList();
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setTableData(
      courierPartnerList?.data?.length ? [...courierPartnerList?.data] : []
    );
    // eslint-disable-next-line
  }, [courierPartnerList]);

  // const onSubmit = async (values: any) => {
  //   setFilter({ ...FILTER, ...values, isFilter: true });
  // };

  return (
    <>
      <div className="">
        <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
          <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
          {role.includes("create") ? (
            <div className="flex gap-2 justify-between">
              <div className="flex justify-end">
                <Button onClick={() => handleAddCourierPartner()}>
                  Add Courier Partner
                </Button>
              </div>
            </div>
          ) : null}
        </div>

        {role.includes("list") ? (
          <div className="cardcss">
            <Table
              columns={columns}
              isExpendable={false}
              isLoading={isLoading}
              data={tableData || []}
              setData={setTableData}
              count={courierPartnerList?.total_item}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        ) : null}
      </div>
      <CourierPartnerModal
        isOpen={isCourierPartnerModal}
        onGet={getCourierPartnerList}
        courierPartnerData={courierPartnerData}
        onHide={() => {
          setIsCourierPartnerModal(false);
          setCourierPartnerData({});
        }}
      />
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onCourierPartnerDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default CourierPartner;
