import React, { useEffect, useState } from "react";
import { Button } from "../../../ui";
import CopyToClipboard from "react-copy-to-clipboard";
import { showToaster } from "../../../helperFunctions";

const ResetPwdModal = (props: any) => {
  const { onHide, isOpen, personData } = props;
  const [data, setData] = useState<any>({});
  const onClose = () => {
    onHide();
  };

  useEffect(() => {
    if (isOpen) {
      setData(personData);
    }
  }, [isOpen, personData]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onClose();
        }}
      />

      <div className="modal-container bg-chatlook-white border w-4/12 2xl:w-4/12 xl:w-4/12 lg:w-6/12  md:w-7/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-9 px-6 text-left  max-h-[84vh]">
          <div className="modal-header flex justify-between">
            <h4 className="modal-title mb-4 font-bold ccapitalize">
              Delivery person's password has been changed.
            </h4>
          </div>
          <div
            className="modal-body"
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>
              <span>ID :- </span>
              <span className="bold text-[#4d4444]">{data?.email_id}</span>
            </span>
            <span>
              <span>Password :- </span>
              <span className="bold text-[#4d4444]">{data?.password}</span>
            </span>
          </div>
          <div className="modal-footer pb-1">
            <div className="flex items-center justify-end gap-2">
              <CopyToClipboard
                text={`Id: ${data?.email_id}\npassword: ${data?.password}`}
              >
                <Button
                  className="submitbutton"
                  onClick={() => {
                    onClose();
                    showToaster("Copied");
                  }}
                >
                  Copy & Close
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPwdModal;
