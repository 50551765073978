import React, { useEffect, useState } from "react";
import { Button, Loader } from "../../ui";
import { FILTER } from "../../constant";
import { useDeleteVariantMutation, useGetVariantMutation } from "./variantApi";
import { useVariantList, useCurrentTab } from "./variantSlice";
import { MdEdit } from "react-icons/md";
import { Modal } from "../common";
import { responseToaster } from "../../helperFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdTrash } from "react-icons/io";
import CustomeBreadcrumbs from "../common/CustomeBreadcrumbs";
import Tooltip from "../../ui/elements/Tooltip";
import { useRole } from "../auth/authSlice";

const Variant = () => {
  const [getVariant, { isLoading }] = useGetVariantMutation();
  const variantList = useVariantList();
  const [deleteVariant, { isLoading: isDeleteLoading }] =
    useDeleteVariantMutation();
  const currentTab = useCurrentTab();
  const [filter, setFilter] = useState<any>(FILTER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();
  const route = useLocation()?.pathname;
  const navigate = useNavigate();
  const role = useRole("variant");
  
  if (!role.includes("list")) {
    navigate("/");
  }
  const breadcrumbs = [{ label: "Master" }, { label: "Variant" }];
  const handleAddVariant = () => {
    navigate(`${route}/create`);
  };
  const onVariantDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteVariant(payload).unwrap();
      if (res) {
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
      responseToaster(res);
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };
  const getVariantList = async () => {
    try {
      const payload: any = {
        ...filter,
      };

      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getVariant(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    if (role.includes("list")) {
      getVariantList();
    }
    // eslint-disable-next-line
  }, [filter, currentTab]);

  if (isLoading) {
    return (
      <>
        <div className="flex items-center justify-center loader_height">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
          {role.includes("list") ? (
            <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
          ) : null}
          {role.includes("create") ? (
            <Button onClick={() => handleAddVariant()}>Add Variant</Button>
          ) : null}
        </div>

        {role.includes("list") ? (
          <div>
            {variantList?.data?.length ? (
              variantList?.data?.map((variant: any, index: number) => {
                return (
                  <div
                    className="cardcss my-2 flex justify-between"
                    key={index}
                  >
                    <p>{variant?.variant_name}</p>
                    <div className="flex gap-2">
                      <Tooltip text="Edit">
                        <Button
                          type="button"
                          className="!h-8 !w-8 !p-0"
                          onClick={() => {
                            navigate(`${route}/${variant?.id}`);
                          }}
                          disabled={!role.includes("edit")}
                        >
                          <MdEdit />
                        </Button>
                      </Tooltip>
                      <Tooltip text="Delete">
                        <Button
                          type="button"
                          className="!h-8 !w-8 !p-0"
                          onClick={() => {
                            setDeleteData(variant);
                            setIsDeleteModalOpen(true);
                          }}
                          disabled={!role.includes("delete")}
                        >
                          <IoMdTrash />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-gray-600">
                No variants found. You can add a new one using the button above.
              </p>
            )}
          </div>
        ) : null}
      </div>

      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onVariantDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default Variant;
