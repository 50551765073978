import React, { memo } from "react";
import { IconProps } from ".";

export interface CourierPartnerIconProps extends IconProps {}
const CourierPartnerIcon = (props: CourierPartnerIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 0.01,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 512 512"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        fill={stroke}
        d="m485.165 132.431c16.391-13.769 26.835-34.399 26.835-57.431 0-41.355-33.645-75-75-75s-75 33.645-75 75c0 21.981 9.506 41.783 24.622 55.514-15.85 10.327-26.933 27.365-29.185 47.059l-20.437 7.805v-97.378h-162v97.378l-20.437-7.805c-2.252-19.694-13.335-36.732-29.185-47.059 15.116-13.731 24.622-33.533 24.622-55.514 0-41.355-33.645-75-75-75s-75 33.645-75 75c0 23.032 10.444 43.662 26.835 57.431-16.247 11.828-26.835 30.98-26.835 52.569v157h25v170h88.339l20-170h21.661v-67.92l40.147 15.333 18.871-49.413h83.965l18.871 49.413 40.146-15.333v67.92h21.661l20 170h88.339v-170h25v-157c0-21.589-10.588-40.741-26.835-52.569zm-48.165-102.431c24.813 0 45 20.187 45 45s-20.187 45-45 45-45-20.187-45-45 20.187-45 45-45zm-362 0c24.813 0 45 20.187 45 45s-20.187 45-45 45-45-20.187-45-45 20.187-45 45-45zm11.661 452h-31.661v-140h48.132zm38.339-170h-95v-127c0-19.299 15.701-35 35-35h25c11.181 0 21.147 5.277 27.559 13.464-10.995-3.954-22.881-3.523-33.577 1.262-10.972 4.908-19.377 13.794-23.665 25.023-8.853 23.18 2.803 49.241 25.983 58.093l38.7 14.78zm52.825-61.317-80.82-30.866c-3.743-1.43-6.705-4.231-8.341-7.889-1.636-3.657-1.75-7.733-.32-11.476s4.231-6.705 7.889-8.341c1.958-.876 4.036-1.315 6.119-1.315 1.807 0 3.618.331 5.357.995l80.82 30.866zm47.65-40.683 1.782-4.665-22.256-8.5v-78.835h36v30h30v-30h36v78.835l-22.256 8.5 1.782 4.665zm97.997 12.657 80.82-30.866c3.744-1.43 7.818-1.316 11.476.32s6.459 4.598 7.889 8.341c1.429 3.743 1.315 7.819-.32 11.476-1.636 3.658-4.598 6.459-8.341 7.889l-80.82 30.866zm102.227 25.186c23.18-8.853 34.836-34.913 25.983-58.093-4.288-11.229-12.693-20.116-23.665-25.023-10.697-4.783-22.583-5.215-33.577-1.262 6.413-8.188 16.379-13.465 27.56-13.465h25c19.299 0 35 15.701 35 35v127h-95v-49.378zm31.301 234.157h-31.661l-16.471-140h48.132z"
        className={`${pathClassNames?.[0] || ""}`}
      />
    </svg>
  );
};

const MemoCourierPartnerIcon = memo(CourierPartnerIcon);
export default MemoCourierPartnerIcon;
