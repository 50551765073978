import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { courierPartnerApi } from "./courierPartnerApi";

interface UserState {
  courierPartnerList: any;
}

const initialState: UserState = {
  courierPartnerList: {},
};

export const courierPartnerSlice = createSlice({
  name: "courierPartner",
  initialState,
  reducers: {
    clearCourierPartner: () => initialState,
    setCourierPartner: (state, action) => {
      state.courierPartnerList = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      courierPartnerApi.endpoints.getCourierPartner.matchFulfilled,
      (state, action: any) => {
        state.courierPartnerList = action?.payload;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default courierPartnerSlice.reducer;
export const { clearCourierPartner, setCourierPartner } =
  courierPartnerSlice.actions;

export const selectCourierPartnerList = (state: RootState) =>
  state.courierPartner.courierPartnerList;
export const useCourierPartnerList = () => {
  const courierPartnerList = useSelector(selectCourierPartnerList);
  return useMemo(() => courierPartnerList, [courierPartnerList]);
};
