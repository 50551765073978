import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { variantApi } from "./variantApi";

interface UserState {
  variantList: any;
  variantVendor: any;
  variantOptions: any;
  variantData: any;
  currTab: any;
  productVariant: any;
}

const initialState: UserState = {
  variantList: {},
  variantVendor: {},
  variantOptions: {},
  variantData: {},
  productVariant: {},
  currTab: "Admin",
};

export const variantSlice = createSlice({
  name: "variant",
  initialState,
  reducers: {
    clearVariant: () => initialState,
    setVariant: (state, { payload }) => {
      state.variantList = payload;
    },
    setCurrTab: (state, { payload }) => {
      state.currTab = payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      variantApi.endpoints.getVariant.matchFulfilled,
      (state, action: any) => {
          state.variantList = action?.payload;
      }
    );
    builder.addMatcher(
      variantApi.endpoints.addVariant.matchFulfilled,
      (state, { payload }) => {
        state.variantData = payload || {};
      }
    );
    builder.addMatcher(
      variantApi.endpoints.getVariantOption.matchFulfilled,
      (state, { payload }) => {
        state.variantOptions = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default variantSlice.reducer;
export const { clearVariant, setVariant, setCurrTab } = variantSlice.actions;

export const selectVariantList = (state: RootState) => state.variant.variantList;
export const useVariantList = () => {
  const variantList = useSelector(selectVariantList);
  return useMemo(() => variantList, [variantList]);
};
export const selectVendorVariantList = (state: RootState) => state.variant.variantVendor;
export const useVendorVariantList = () => {
  const variantVendorList = useSelector(selectVendorVariantList);
  return useMemo(() => variantVendorList, [variantVendorList]);
};

export const selectVariantOptionData = (state: RootState) => state.variant.variantOptions;
export const useVariantOptionData = () => {
  const variantOptions = useSelector(selectVariantOptionData);
  return useMemo(() => variantOptions, [variantOptions]);
};

export const selectVariantData = (state: RootState) => state.variant.variantData;
export const useVariantData = () => {
  const variantData = useSelector(selectVariantData);
  return useMemo(() => variantData, [variantData]);
};

export const selectCurrentTab = (state: RootState) => state.variant.currTab;
export const useCurrentTab = () => {
  const currentTab = useSelector(selectCurrentTab || "Admin");
  return useMemo(() => currentTab, [currentTab]);
};
