import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";

import {
  Button,
  CheckBox,
  ControlledDropDown,
  ControlledInput,
  ControlledPicker,
  DropDown,
  Icon,
  Input,
  Loader,
} from "../../ui";
import {
  prepareCopy,
  prepareMainCategoryOptions,
  prepareVendorOptions,
  responseToaster,
} from "../../helperFunctions";
import {
  useAddProductMutation,
  useGetCategoriesMutation,
  useGetPolicyOptionMutation,
  useGetProductByIdMutation,
  useGetVendorsMutation,
  useUpdateProductMutation,
} from "./productApi";
import {
  unsetPolicyList,
  useCategories,
  usePolicyList,
  useVendorList,
} from "./productSlice";
import { useRole, useUser } from "../auth/authSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import CustomeBreadcrumbs from "../common/CustomeBreadcrumbs";
import { useTagListData } from "../master/tag/tagSlice";
import { useGetTagListMutation } from "../master/tag/tagApi";
import {
  IoIosAddCircleOutline,
  IoIosCloseCircle,
  IoMdTrash,
} from "react-icons/io";
import { MdEdit } from "react-icons/md";
import Tooltip from "../../ui/elements/Tooltip";
import { useGetVariantOptionMutation } from "../variant/variantApi";
import { useVariantOptionData } from "../variant/variantSlice";
import Switch from "../../ui/forms/Switch";
import { useGetSubCategoryMutation } from "../master/subCategory/subCategoryApi";
import { useVendorSubCategoryList } from "../master/subCategory/subCategorySlice";
import { attributeOption } from "./mock";
import { useDispatch } from "react-redux";
import ControlledRichTextBox from "../../ui/forms/ControlledRichTextBox";

const ProductForm = () => {
  const [addProduct, { isLoading }] = useAddProductMutation();
  const [updateProduct, { isLoading: isUpdateProductLoading }] =
    useUpdateProductMutation();
  const [getProductById, { isLoading: isGetProductByIdLoading }] =
    useGetProductByIdMutation();
  const [getPolicyOption] = useGetPolicyOptionMutation();
  const [getTagList] = useGetTagListMutation();
  const [getVariantOption] = useGetVariantOptionMutation();
  const [getSubCategory] = useGetSubCategoryMutation();
  const [getVendors] = useGetVendorsMutation();

  const [images, setImages] = useState<any>();
  const [isAdmin, setIsAdmin] = useState<any>(true);
  const [isApproved, setIsApproved] = useState<any>(true);
  const [editableKey, setEditableKey] = useState<number>(-1);
  const [tagListOptions, setTagListOptions] = useState([]);
  const [subCatOptions, setSubCatOptions] = useState([]);
  const [addVariant, setAddVariant] = useState<any>(false);
  const [dynamicKeyValuePairs, setDynamicKeyValuePairs] = useState<any>([]);
  const [variantOption, setVariantOption] = useState<any>([]);
  const [variantValueOption, setVariantValueOption] = useState<any>([]);
  const [policies, setPolicies] = useState<any>([]);
  const [policies1, setPolicies1] = useState<any>(attributeOption);
  const [originalPolicy, setOriginalPolicy] = useState<any>([]);
  const [activeId, setActiveId] = useState<any>([]);
  const [missingType, setMissingType] = useState<any>("");
  const [activeAccordions, setActiveAccordions] = useState<any>([]);
  const dispatch = useDispatch();

  const vendorList = useVendorList();
  const variantOptions = useVariantOptionData();
  const categories = useCategories();
  const policyList = usePolicyList();
  const tagList = useTagListData();
  const [getCategories] = useGetCategoriesMutation();
  const subCategoryList = useVendorSubCategoryList();
  const { id } = useParams();
  const user = useUser();
  const navigate = useNavigate();
  const role = useRole("product");
  if (!role.includes("list")) {
    navigate("/");
  }

  const breadcrumbs = [
    { label: "Master", link: "/master/product/get-product" },
    { label: "Product", link: "/master/product/get-product" },
    {
      label:
        isAdmin && isApproved
          ? "Admin"
          : !isAdmin && isApproved
          ? "Vendor"
          : "UnApproved",
      link: "/master/product/get-product",
    },
    { label: (id ? (!isAdmin ? "View" : "Update") : "Add") + " Product" },
  ];
  const initialValues = {
    title: "",
    price: "",
    discount_price: "",
    description: "",
    main_category: null,
    sub_category: "",
    image: [],
    vendor_id: null,
    status: "active",
  };
  const schema = yup.object({
    title: yup.string().required("Title is required"),
    price: yup.string().required("Price is required"),
    discount_price: yup
      .string()
      .test(
        "is-less",
        "Discount price must be less than the price",
        function (value: any, context: any) {
          const { price } = context.parent;
          if (value?.length) {
            return parseFloat(value) <= parseFloat(price);
          } else {
            return true;
          }
        }
      ),
    vendor_id: yup.string().required("Vendor is required"),
    main_category: yup.string().required("Category is required"),
    // sub_category: yup.string().required("Sub category is required"),
    /* image: yup
      .mixed()
      .test("image", "Please add a Image", (value: any) => value?.length), */
  });
  const {
    control,
    setValue,
    watch,
    trigger,
    getValues,
    handleSubmit,
    clearErrors,
    reset,
    setError,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver<any>(schema),
    defaultValues: initialValues,
  });

  const prepareTagsOptions = () => {
    if (tagList?.data?.length) {
      const tagOptions = tagList?.data
        ?.slice?.()
        ?.sort?.((a: any, b: any) => a?.localeCompare?.(b))
        ?.map?.((val: any) => ({
          value: val,
          label: val,
        }));
      return tagOptions || [];
    }
    return [];
  };

  const prepareMainCategoryOptions1 = () => {
    const options: any = [];
    categories?.data?.forEach((category: any) => {
      prepareCategoryOptionsRecursive(category, options);
    });
    return options;
  };

  const prepareCategoryOptionsRecursive = (
    category: { title: any; children: any[]; id: any; parent_id?: any },
    options: { value: any; label: any }[],
    level = 1
  ) => {
    const mainCatId: any = getValues().main_category;
    if (category.children && category.children.length > 0 && level !== 3) {
      category.children.forEach((child) => {
        prepareCategoryOptionsRecursive(child, options, level + 1);
      });
    } else {
      if (level === 3 && mainCatId === category?.parent_id) {
        options.push({
          value: category?.id,
          label: category.title,
        });
      }
    }
  };

  const prepareSubCategoryOptions = () => {
    if (subCategoryList?.data?.length) {
      const subCateOptions = subCategoryList?.data
        ?.slice?.()
        ?.sort?.((a: any, b: any) => a?.name?.localeCompare?.(b?.name))
        ?.map?.((val: any) => ({
          value: val?.id,
          label: val?.name,
        }));
      return subCateOptions || [];
    }
    return [];
  };

  useEffect(() => {
    setTagListOptions(prepareTagsOptions());
    // eslint-disable-next-line
  }, [tagList]);

  useEffect(() => {
    if (watch("vendor_id")) {
      setSubCatOptions(
        prepareSubCategoryOptions().concat(prepareMainCategoryOptions1())
      );
    }
    // eslint-disable-next-line
  }, [subCategoryList, watch("main_category")]);

  const convertToVariants = (data: any[]) => {
    const variants = data.map(
      (item: { value: any; stock: string }, index: any) => ({
        variant_id: item.value,
        stock: parseInt(item.stock),
      })
    );

    return variants;
  };
  const onChange = (
    key: string,
    value: any,
    index: number,
    subIndex: number,
    policyType?: string,
    att?: any
  ) => {
    const newPolicies = prepareCopy(policies, []);
    if (index > -1) {
      if (subIndex > -1) {
        newPolicies[index]["policy_condition"][subIndex][key] = value;
      } else {
        newPolicies[index]["policy_condition"].push({
          [key]: value,
          attribute: att?.value,
        });
      }
    } else {
      newPolicies.push({
        policy_type: policyType,
        policy_condition: [
          {
            [key]: value,
            attribute: att?.value,
          },
        ],
      });
    }
    setPolicies([...newPolicies]);
  };
  const onSubmit = async (values: any) => {
    try {
      const newPolicies: any = [];

      policies?.forEach?.((item: any) => {
        let isAdd = false;
        const newPolicyConditions: any = [];

        item?.policy_condition?.map((subItem: any) => {
          if (subItem?.status === 1 && subItem?.value) {
            isAdd = true;
            newPolicyConditions.push(subItem);
          }
          return null;
        });

        if (isAdd) {
          newPolicies.push({ ...item, policy_condition: newPolicyConditions });
        }
      });

      const formData = new FormData();
      formData.append("title", values?.title);
      formData.append("price", values?.price);
      if (values?.discount_price) {
        formData.append("discount_price", values?.discount_price);
      }
      formData.append("description", values?.description);
      formData.append("category_id", values?.main_category);
      // values?.image?.forEach((image: any, index: any) => {
      //   formData.append(`photo${index}`, image);
      // })
      if (id) {
        values?.image?.forEach?.((images: any, index: number) => {
          formData.append(`photo${index}`, images?.file || images);
        });
      }
      if (!id) {
        values?.image?.forEach?.((images: any, index: number) => {
          formData.append(`photo[]`, images?.file || images);
        });
      }
      formData.append("admin_id", user?.data?.admin?.id);
      formData.append("photo_count", values?.image?.length);
      formData.append("vendor_id", values?.vendor_id);
      formData.append("status", values?.status);
      formData.append("child", values?.sub_category);

      if (activeId?.length) {
        activeId?.forEach?.((policyId: any) => {
          formData.append("active_policy[]", policyId);
        });
      } else {
        formData.append("active_policy[]", "");
      }

      if (findDifferencesDynamically(newPolicies, originalPolicy)?.length) {
        findDifferencesDynamically(newPolicies, originalPolicy)?.forEach?.(
          (policy: any, index: number) => {
            if (policy?.policy_id) {
              formData.append(`policy[${index}][id]`, policy?.policy_id);
            }
            policy?.policy_condition?.forEach?.((condition: any, i: number) => {
              formData.append(
                `policy[${index}][policy_condition][${i}][attribute]`,
                condition?.attribute
              );

              formData.append(
                `policy[${index}][policy_condition][${i}][value]`,
                condition?.value
              );
            });
          }
        );
      } else {
        formData.append("policy[]", "");
      }
      values?.tags?.forEach?.((item: any, index: number) => {
        formData.append(`tags[]`, item);
      });
      if (addVariant && dynamicKeyValuePairs?.length) {
        convertToVariants(dynamicKeyValuePairs)?.forEach?.(
          (item: any, index: number) => {
            formData.append(`variants[${index}][variant_id]`, item?.variant_id);
            formData.append(`variants[${index}][stock]`, item?.stock);
          }
        );
      }
      if (!dynamicKeyValuePairs?.length) {
        formData.append(`variants[0][variant_id]`, "0");
        formData.append(`variants[0][stock]`, values?.stock1 || 0);
      }

      let res;
      if (id) {
        formData.append("id", id);
        res = await updateProduct(formData).unwrap();
      } else {
        res = await addProduct(formData).unwrap();
      }

      responseToaster(res);
      onClose();
    } catch (err) {}
  };

  const onClose = () => {
    reset();
    navigate("/master/product/get-product");
    setIsAdmin(false);
    setIsApproved(false);
    setDynamicKeyValuePairs([]);
    dispatch(unsetPolicyList());
  };

  const findDifferencesDynamically = (
    newPolicies: any[],
    originalPolicies: any[]
  ) => {
    const differences: {
      policy_type: any;
      policy_condition: any;
      policy_id: any;
    }[] = [];

    newPolicies.forEach(
      (newPolicy: {
        policy_type: any;
        policy_condition: any;
        policy_id: any;
      }) => {
        const originalPolicy = originalPolicies.find(
          (policy: { policy_type: any }) =>
            policy.policy_type === newPolicy.policy_type
        );

        if (originalPolicy) {
          const newConditions = newPolicy.policy_condition;
          const originalConditions = originalPolicy.policy_condition;

          const differentConditions = newConditions.filter(
            (newCond: { id: any; value: any }) => {
              const originalCond = originalConditions.find(
                (origCond: { id: any }) => origCond.id === newCond.id
              );
              return !originalCond || newCond.value !== originalCond.value;
            }
          );

          if (differentConditions.length > 0) {
            differences.push({
              policy_type: newPolicy.policy_type,
              policy_condition: differentConditions,
              policy_id: newPolicy.policy_id,
            });
          }
        } else {
          differences.push(newPolicy); // If the policy type is not found in the original policies, consider it as a difference
        }
      }
    );

    return differences;
  };

  useEffect(() => {
    if (policyList?.data?.length && watch("vendor_id")) {
      const convertedArray: any = {};

      policyList?.data?.forEach?.((policy: any) => {
        const policyType = policy.policy_type;
        const policyConditions = policy.policy_condition;

        if (attributeOption[policyType]) {
          convertedArray[policyType] = {
            activeId: policy.id,
            data: attributeOption[policyType]?.map?.((option: any) => {
              const condition = policyConditions?.find?.(
                (cond: any) => cond.attribute === option.value
              );
              if (condition) {
                return {
                  ...option,
                  value: condition.value,
                  id: condition.id,
                  status: condition.status,
                };
              } else {
                return option;
              }
            }),
          };
        }
      });

      const convertedArray1 = policyList?.data?.map((policy: any) => {
        const policyType = policy.policy_type;
        const policyConditions = policy.policy_condition
          .map((condition: any) => {
            const attribute = condition.attribute;
            const option = attributeOption?.[policyType]?.find?.(
              (opt: any) => opt.value === attribute
            );
            if (option) {
              return {
                status: condition.status,
                attribute: option.label,
                value: condition.value,
                id: condition?.id,
              };
            } else {
              return null;
            }
          })
          .filter(Boolean);

        return {
          policy_type: policyType,
          policy_condition: policyConditions,
          policy_id: policy.id,
        };
      });
      const existingPolicyTypes: any = policyList?.data?.map?.(
        (policy: any) => policy?.policy_type
      );

      // Get all possible policy types from attributeOption keys
      const allPolicyTypes = Object.keys?.(attributeOption);

      // Find policy types that are not in existingPolicyTypes
      const missingTypes = allPolicyTypes?.filter?.(
        (type: any) => !existingPolicyTypes?.includes?.(type)
      );

      setMissingType(missingTypes);

      setActiveId(policyList?.active_policy);
      setPolicies1(convertedArray);
      setOriginalPolicy(convertedArray1);
      setPolicies(convertedArray1);
    } else {
      setActiveId([]);
      setPolicies1([]);
      setPolicies([]);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(policyList), watch("vendor_id")]);

  const getProduct = async () => {
    try {
      const payload: any = {
        id,
      };
      const res: any = await getProductById(payload);
      if (res?.data?.status) {
        setFormData(res?.data?.data);
        setIsAdmin(res?.data?.data?.is_admin === 1);
        setIsApproved(res?.data?.data?.is_approved);
      }
    } catch (error) {}
  };

  const checkVendorId = (dataArray: any) => {
    if (!dataArray?.length) {
      return false;
    }
    for (const item of dataArray) {
      if (item?.variant_id === 0) {
        return false;
      }
    }
    return true;
  };

  const toggleAccordion = (policyType: any) => {
    setActiveAccordions((prevAccordions: any[]) => {
      if (prevAccordions.includes(policyType)) {
        return prevAccordions.filter(
          (accordion: any) => accordion !== policyType
        );
      } else {
        return [...prevAccordions, policyType];
      }
    });
  };

  const setFormData = (currentProduct: any) => {
    const imagePaths = currentProduct?.photo?.split?.(",");
    const formattedURLs: any = [];
    imagePaths?.forEach((imagePath: any) => {
      const url = `${process.env.REACT_APP_BASE_IMAGE_URL}${imagePath}`;
      formattedURLs?.push?.(url);
    });
    setImages(formattedURLs);
    setValue("title", currentProduct?.title);
    setValue("price", currentProduct?.price);
    setValue("discount_price", currentProduct?.discount_price);
    setValue("description", currentProduct?.description || "");
    setValue("main_category", currentProduct?.category_id);
    setValue("image", currentProduct?.photo?.split(","));
    setValue("vendor_id", currentProduct?.vendor_id);
    setValue("status", currentProduct?.status === "active" ? 1 : 0);
    setValue("sub_category", currentProduct?.sub_category?.id);
    setValue("tags", currentProduct?.tags);
    setAddVariant(checkVendorId(currentProduct?.product_variants));
    if (!checkVendorId(currentProduct?.product_variants)) {
      setValue("stock1", currentProduct?.product_variants?.[0]?.stock);
    }
    if (checkVendorId(currentProduct?.product_variants)) {
      const variant = currentProduct?.product_variants?.map((variant: any) => {
        return {
          key: variant?.option_id,
          value: variant?.variant_id,
          stock: variant?.stock,
        };
      });
      setDynamicKeyValuePairs(variant);
    }
  };

  useEffect(() => {
    if (id) {
      getProduct();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    getVendors();
    // eslint-disable-next-line
  }, []);

  const setPolicyList = async () => {
    try {
      const vendor_id: any = getValues().vendor_id;
      const payload: any = {
        vendor_id,
        ...(id && { product_id: id }),
      };
      const res: any = await getPolicyOption(payload);
      if (res?.status === false) {
        dispatch(unsetPolicyList());
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const getTags = async () => {
    try {
      const vendor_id: any = getValues().vendor_id;
      const payload: any = {
        vendor_id,
      };
      await getTagList(payload);
    } catch (error) {
      console.log("error", error);
    }
  };
  const getVariantOptions = async () => {
    try {
      const vendor_id: any = getValues().vendor_id;
      const payload: any = {
        vendor_id,
      };
      await getVariantOption(payload);
    } catch (error) {
      console.log("error", error);
    }
  };
  const getCategoriesList = async () => {
    try {
      const vendor_id: any = getValues().vendor_id;
      const payload: any = {
        vendor_id,
      };
      await getCategories(payload);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getSubCategoryOptions = async () => {
    try {
      const vendor_id: any = getValues().vendor_id;
      const payload: any = {
        filter_data: {
          vendor_id,
        },
      };
      await getSubCategory(payload);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (watch("vendor_id")) {
      getTags();
      getVariantOptions();
      getSubCategoryOptions();
      setPolicyList();
      getCategoriesList();
    }
    // eslint-disable-next-line
  }, [watch("vendor_id")]);

  useEffect(() => {
    if (!id) {
      getVariantOptions();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const key: any = getValues().key;
    const extractOption = variantOptions?.data?.find?.((option: any) => {
      return option?.id === key;
    });

    const options = extractOption?.variants_value
      ?.slice?.()
      ?.sort?.((a: { value: string }, b: { value: any }) =>
        a?.value?.localeCompare?.(b?.value)
      )
      ?.map?.((val: { id: any; value: any }) => ({
        value: val?.id,
        label: val?.value,
      }));
    setVariantValueOption(options);
    // eslint-disable-next-line
  }, [watch("key")]);

  useEffect(() => {
    setVariantOption(prepareVariantOption());
    // eslint-disable-next-line
  }, [variantOptions]);

  const prepareVariantOption = () => {
    const options = variantOptions?.data
      ?.slice?.()
      ?.sort?.((a: { variant_name: string }, b: { variant_name: any }) =>
        a?.variant_name?.localeCompare?.(b?.variant_name)
      )
      ?.map?.((val: { id: any; variant_name: any }) => ({
        value: val?.id,
        label: val?.variant_name,
      }));
    return options || [];
  };

  const handleAddValues = () => {
    const keyInput = getValues().key;
    const valueInput = getValues().value;
    const stockInput = getValues().stock1;
    if (
      addVariant &&
      (!keyInput?.toString()?.length ||
        !valueInput?.toString()?.length ||
        !stockInput?.toString()?.length)
    ) {
      !keyInput?.toString()?.length
        ? setError("key", {
            type: "manual",
            message: "Please select name",
          })
        : clearErrors("key");

      !valueInput?.toString()?.length
        ? setError("value", {
            type: "manual",
            message: "Please select value",
          })
        : clearErrors("value");

      !stockInput?.toString()?.length
        ? setError("stock1", {
            type: "manual",
            message: "Please add stock",
          })
        : clearErrors("stock1");
      return;
    }
    if (
      keyInput &&
      valueInput &&
      stockInput &&
      keyInput !== "" &&
      valueInput !== "" &&
      stockInput !== ""
    ) {
      const existingIndex = dynamicKeyValuePairs?.findIndex?.(
        (_: any, index: number) => editableKey === index
      );

      if (existingIndex !== -1) {
        const updatedPairs = [...dynamicKeyValuePairs];
        updatedPairs[existingIndex] = {
          key: keyInput,
          value: valueInput,
          stock: stockInput,
        };
        setDynamicKeyValuePairs(updatedPairs);
        setEditableKey(-1);
      } else {
        setDynamicKeyValuePairs([
          ...dynamicKeyValuePairs,
          { key: keyInput, value: valueInput, stock: stockInput },
        ]);
      }

      resetField("key");
      resetField("value");
      resetField("stock1");
    }
  };

  const handleRemoveValue = (indexToRemove: number) => {
    setDynamicKeyValuePairs(
      dynamicKeyValuePairs.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };

  const handleEditValue = (index: number) => {
    const keyValuePairsCopy = [...dynamicKeyValuePairs];
    const pairToEdit = keyValuePairsCopy[index];
    setValue("key", pairToEdit.key);
    setValue("value", pairToEdit.value);
    setValue("stock1", pairToEdit.stock);
    setEditableKey(index);
  };

  if (isGetProductByIdLoading && id) {
    return (
      <>
        <div className="flex items-center justify-center loader_height">
          <Loader />
        </div>
      </>
    );
  }

  const findValueById = (id: any) => {
    for (const item of variantOptions?.data || []) {
      const variantValue = item?.variants_value?.find?.(
        (option: any) => option.id === id
      );
      if (variantValue) {
        return variantValue?.value;
      }
    }
    return null;
  };

  return (
    <div className="main-content-wrap">
      <div className="flex items-center flex-wrap gap-3">
        <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="tf-section-2 form-add-product">
          <div className="wg-box flex-col !gap-3">
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 items-start gap-3 pt-2">
              <ControlledInput
                name="title"
                label="Title"
                placeholder="Enter Title"
                type="text"
                control={control}
                errors={errors}
                disabled={!isAdmin}
              />
              <ControlledDropDown
                name="vendor_id"
                label="Vendor"
                placeholder="Select Vendor"
                options={prepareVendorOptions(vendorList)}
                control={control}
                errors={errors}
                isDisabled={!isAdmin}
                onChange={(e: any) => {
                  setValue("vendor_id", e);
                  setValue("tags", []);
                  setValue("main_category", "");
                  setValue("sub_category", "");
                  trigger("vendor_id");
                }}
              />
              <ControlledDropDown
                name="main_category"
                label="Category"
                placeholder="Select Main Category"
                isDisabled={!watch("vendor_id") || !isAdmin}
                options={prepareMainCategoryOptions(
                  vendorList,
                  watch("vendor_id"),
                  categories
                )}
                onChange={(e: any) => {
                  setValue("main_category", e);
                  trigger("main_category");
                }}
                control={control}
                errors={errors}
              />
              <ControlledDropDown
                name="sub_category"
                label="Sub Category"
                placeholder="Enter Sub Category"
                isDisabled={!watch("vendor_id") || !isAdmin}
                options={subCatOptions}
                control={control}
                errors={errors}
              />
              {/* <ControlledDatalistInput
              id="sub_category"
              name="sub_category"
              label="Sub Category"
              placeholder="Enter Sub Category"
              type="text"
              disabled={!watch("main_category") || !isAdmin}
              options={prepareSubCategoryOptions()}
              control={control}
              errors={errors}
            /> */}
              <ControlledInput
                name="price"
                label="MRP"
                placeholder="Enter MRP"
                type="number"
                control={control}
                errors={errors}
                disabled={!isAdmin}
              />
              <ControlledInput
                name="discount_price"
                label="Listed Price"
                placeholder="Enter Listed Price"
                type="number"
                control={control}
                errors={errors}
                onChange={(e) => {
                  setValue("discount_price", e.target.value);
                  trigger("discount_price");
                }}
                disabled={!isAdmin}
              />
            </div>
            <div className="flex sm:flex-wrap gap-3">
              {/*  <div className="sm:w-full w-1/2">
                <ControlledInput
                  name="stock"
                  label="Stock"
                  placeholder="Enter Stock"
                  type="number"
                  control={control}
                  errors={errors}
                  disabled={!isAdmin}
                />
              </div> */}
              <div className="sm:w-full w-1/2">
                <ControlledDropDown
                  name="tags"
                  closeMenuOnSelect={false}
                  label="Tags"
                  placeholder="Select Tags"
                  isDisabled={!watch("vendor_id") || !isAdmin}
                  options={tagListOptions}
                  isMulti
                  control={control}
                  errors={errors}
                />
              </div>
            </div>
            <div className="relative">
              <ControlledRichTextBox
                label="Description"
                control={control}
                name="description"
                disabled={!isAdmin}
              />
            </div>
            {/*   <ControlledTextArea
              name="description"
              label="Description"
              placeholder="Enter Description"
              control={control}
              errors={errors}
              className="textareacss1 col-span-2"
              disabled={!isAdmin}
            /> */}
            <Switch
              checked={addVariant}
              label="Add Variant?"
              isDisabled={!isAdmin}
              onChange={() => {
                setAddVariant(!addVariant);
                resetField("key");
                resetField("value");
                resetField("stock1");
                setEditableKey(-1);
                setDynamicKeyValuePairs([]);
              }}
            />
            <div className="col-span-2 sm:flex-wrap flex items-start gap-2">
              {addVariant ? (
                <>
                  <ControlledDropDown
                    name="key"
                    label="Name"
                    placeholder="Select Name"
                    options={variantOption}
                    control={control}
                    errors={errors}
                    isDisabled={!isAdmin}
                    className="mr-2"
                  />
                  <ControlledDropDown
                    name="value"
                    label="Value"
                    placeholder="Select Value"
                    options={variantValueOption}
                    control={control}
                    errors={errors}
                    isDisabled={!watch("key") || !isAdmin}
                    className="mr-2"
                  />
                </>
              ) : null}

              <ControlledInput
                name="stock1"
                label="Stock"
                placeholder="Enter Stock"
                type="number"
                control={control}
                errors={errors}
                disabled={!isAdmin}
                onChange={(e) => {
                  if (
                    e?.target?.value === "" ||
                    /^\d+$/.test(e?.target?.value)
                  ) {
                    setValue("stock1", e.target?.value);
                  } else {
                    return;
                  }
                }}
              />
              {addVariant ? (
                <Tooltip text={`${editableKey !== -1 ? "Edit" : "Add"}`}>
                  <Button
                    onClick={handleAddValues}
                    disabled={!isAdmin}
                    className="btn-smallsize mt-6"
                  >
                    {editableKey !== -1 ? (
                      <MdEdit />
                    ) : (
                      <IoIosAddCircleOutline />
                    )}
                  </Button>
                </Tooltip>
              ) : null}
              {editableKey !== -1 ? (
                <Tooltip text="Cancel">
                  <Button
                    onClick={() => {
                      resetField("key");
                      resetField("value");
                      resetField("stock1");
                      setEditableKey(-1);
                    }}
                    className="btn-smallsize mt-6"
                  >
                    <IoIosCloseCircle />
                  </Button>
                </Tooltip>
              ) : null}
            </div>
            <div className="col-span-2 grid items-end">
              {dynamicKeyValuePairs?.length ? (
                <label className={`text-sm text-ev-secondary`}>Variants</label>
              ) : null}
              {dynamicKeyValuePairs?.map((pairValue: any, index: number) => {
                const variant = variantOption?.find?.(
                  (option: any) => option?.value === pairValue?.key
                );
                const variantValue = findValueById(pairValue?.value);
                return (
                  <div
                    className="flex items-end justify-between mb-2 border-b-2"
                    key={index}
                  >
                    <div className="grid grid-cols-3 w-full">
                      <span className="mr-2 break-words">{variant?.label}</span>
                      <span className="mr-2 break-words">{variantValue}</span>
                      <span className="mr-2 break-words">
                        {pairValue?.stock}
                      </span>
                    </div>
                    {isAdmin ? (
                      <div className="flex gap-2 mb-1">
                        <Tooltip text="Edit">
                          <Button
                            onClick={() => handleEditValue(index)}
                            className="btn-smallsize"
                          >
                            <MdEdit />
                          </Button>
                        </Tooltip>
                        <Tooltip text="Remove">
                          <Button
                            onClick={() => handleRemoveValue(index)}
                            className="btn-smallsize"
                          >
                            <IoMdTrash />
                          </Button>
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="wg-box flex-col !gap-3">
            <label className={`text-sm text-ev-secondary`}>Policy</label>
            {Object?.keys?.(policies1 || {})?.length ? (
              <>
                {Object?.entries?.(policies1 || {})?.map(
                  ([policyType, policy]: any, index) => (
                    <div
                      key={index}
                      className="border border-gray-200 rounded-lg p-4 "
                    >
                      <div className="flex justify-between ">
                        <div className="flex gap-2 ">
                          <CheckBox
                            name="checkbox"
                            disabled={!isAdmin}
                            checked={activeId.includes(policy?.activeId)}
                            onChange={() => {
                              if (
                                activeId &&
                                activeId?.includes?.(policy?.activeId)
                              ) {
                                const removedId = activeId.filter(
                                  (id: any) => id !== policy?.activeId
                                );
                                setActiveId(removedId);
                              } else {
                                setActiveId([...activeId, policy?.activeId]);
                              }
                            }}
                          />
                          {policyType}
                        </div>
                        <div
                          onClick={() => toggleAccordion(policyType)}
                          className="cursor-pointer"
                        >
                          <Icon name="DownArrow" />
                        </div>
                      </div>
                      {activeAccordions.includes(policyType) && (
                        <div className="grid grid-cols-1 gap-4 my-3">
                          {policy?.data?.map((att: any, idx: number) => {
                            const policyIndex = policies?.findIndex(
                              (policy: any) =>
                                policy?.policy_type === policyType
                            );
                            const policyAttIndex = policies?.[
                              policyIndex
                            ]?.policy_condition?.findIndex(
                              (i: any) => i?.attribute === att?.label
                            );

                            const newAtt =
                              policies?.[policyIndex]?.policy_condition?.[
                                policyAttIndex
                              ];

                            return (
                              <div
                                key={idx}
                                className="grid sm:grid-cols-1 grid-cols-2 gap-2"
                              >
                                <div className="flex gap-2 items-center">
                                  <CheckBox
                                    name="checkbox"
                                    checked={newAtt?.status}
                                    disabled={!isAdmin}
                                    onChange={(value: any) => {
                                      onChange(
                                        "status",
                                        value ? 1 : 0,
                                        policyIndex,
                                        policyAttIndex,
                                        policyType,
                                        att
                                      );
                                    }}
                                  />
                                  <div className="w-full flex items-center">
                                    {att?.label}
                                  </div>
                                </div>
                                {att?.options ? (
                                  <DropDown
                                    name={`value`}
                                    options={att?.options}
                                    placeholder={att?.placeholder}
                                    value={newAtt?.value}
                                    isDisabled={!isAdmin}
                                    onChange={(value: any) => {
                                      onChange(
                                        "value",
                                        value,
                                        policyIndex,
                                        policyAttIndex,
                                        policyType,
                                        att
                                      );
                                    }}
                                  />
                                ) : (
                                  <Input
                                    name={`value`}
                                    placeholder={att?.placeholder}
                                    // type="number"
                                    value={newAtt?.value}
                                    disabled={!isAdmin}
                                    onChange={(e: any) => {
                                      const value = e.target.value;
                                      if (att?.validation) {
                                        if (
                                          /^\d+$/.test(value) ||
                                          value === ""
                                        ) {
                                          onChange(
                                            "value",
                                            value,
                                            policyIndex,
                                            policyAttIndex,
                                            policyType,
                                            att
                                          );
                                        }
                                      } else {
                                        onChange(
                                          "value",
                                          value,
                                          policyIndex,
                                          policyAttIndex,
                                          policyType,
                                          att
                                        );
                                      }
                                    }}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )
                )}

                {missingType?.length
                  ? missingType?.map?.((type: any) => {
                      return (
                        <div className="border border-gray-200 rounded-lg p-4">
                          No Policies Found for {type}
                        </div>
                      );
                    })
                  : null}
              </>
            ) : (
              <p className="text-ev-secondary text-sm">
                {" "}
                No policy found for selected vendor.{" "}
              </p>
            )}
          </div>
        </div>
        <div className="wg-box flex mt-3 flex-col">
          <div className="">
            <div className="col-span-3">
              <ControlledPicker
                name="image"
                label="Upload images"
                control={control}
                errors={errors}
                setImages={setImages}
                images={images}
                accept=".jpg, .jpeg, .png"
                disabled={!isAdmin}
              />
            </div>
            <div className="body-text">
              You need to add at least 1 images. Pay attention to the quality of
              the pictures you add, comply with the background color standards.
              Pictures must be in certain dimensions. Notice that the product
              shows all the details
            </div>
          </div>
          {isAdmin ? (
            <div className="flex items-center justify-end gap-2">
              <Button
                type="submit"
                className="submitbutton"
                isLoading={isLoading || isUpdateProductLoading}
                isLoader={isLoading || isUpdateProductLoading}
              >
                {`${id ? "Update" : "Submit"}`}
              </Button>
              <Button
                variant="Transaparent"
                onClick={() => {
                  navigate("/master/product/get-product");
                }}
              >
                Cancel
              </Button>
            </div>
          ) : null}
        </div>
      </Form>
    </div>
  );
};

export default ProductForm;
