import {  forwardRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

interface RichTextEditorProps {
  value: string;
  onChange: (content: string) => void;
  onBlur?: () => void;
  name: string;
  label?: string;
  className?: string;
  lableClassName?: string;
  errors?: any;
  err?: any;
  disabled?: any;
}

const RichTextEditor = forwardRef<ReactQuill, RichTextEditorProps>(
  (
    {
      value,
      onChange,
      onBlur,
      label,
      errors,
      name,
      err,
      className,
      lableClassName,
      disabled,
    },
    ref
  ) => {
    const message = errors?.[name]?.message || err || "";

    return (
      <div
        className={`${className} w-full flex flex-col justify-center relative`}
      >
        {label ? (
          <label className={`${lableClassName} text-sm text-ev-secondary`}>
            {label}
          </label>
        ) : null}
        <div className="flex flex-col justify-center selfqa_editore">
          <ReactQuill
            ref={ref}
            value={value}
            onChange={onChange}
            modules={modules}
            formats={formats}
            onBlur={onBlur}
            readOnly={disabled}
          />
        </div>
        {message ? (
          <span className="text-red-500 text-sm">{message}</span>
        ) : null}
      </div>
    );
  }
);

export default RichTextEditor;
