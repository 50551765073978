import { api } from "../../../utills/api";

export const cityApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCity: build.mutation<{ data: any }, void>({
      query: (params: any) => ({
        url: `/admin/city/get-city-list${params?.list ? "/all" : ""}`,
        body: params,
        method: "POST",
      }),
    }),
    updateCity: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/city/update-city",
        body: params,
        method: "POST",
      }),
    }),
    addCity: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/admin/city/add-city",
        body: params,
        method: "POST",
      }),
    }),
    deleteCity: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/city/delete-city",
        body: params,
        method: "POST",
      }),
    }),
    getCityById: build.mutation<{ data: any }, void>({
      query: (params: any) => ({
        url: `/admin/city/get-city-by-id/${params?.id}`,
        // body: params,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetCityMutation,
  useUpdateCityMutation,
  useAddCityMutation,
  useDeleteCityMutation,
  useGetCityByIdMutation,
} = cityApi;
export const {
  endpoints: { getCity, addCity, updateCity, deleteCity, getCityById },
} = cityApi;
