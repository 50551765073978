// PROJECT IMPORT
import { FaStar } from "react-icons/fa";
import { clearAuth, setUnderMaintenance, verifyAuth } from "./features/auth/authSlice";
import { clearCommon } from "./features/common/commonSlice";

// THIRD - PARTY IMPORT
import { toast } from "react-hot-toast";
import { FiAlertTriangle } from "react-icons/fi";
import { FaStarHalfStroke } from "react-icons/fa6";

const root = window.document.documentElement;

export const kFormatter = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(2).replace(/\.0$/, "") + "K";
  }
  return num;
};

export const logout = async (dispatch) => {
  dispatch(clearAuth());
  dispatch(clearCommon());
};

export const verifyingUser = async (dispatch, payload) => {
  dispatch(verifyAuth(payload));
};

export const underMaintenance = async (dispatch, payload) => {
  dispatch(setUnderMaintenance(payload));
};

export const downloadFile = (url, type, name) => {
  let element = document.createElement("a");
  let file = new Blob([url], { type: `${type}/*` });
  element.href = URL.createObjectURL(file);
  element.download = name;
  element.click();
};

export const showToaster = (message, type = "Success") => {
  switch (type) {
    case "Error":
      toast.error(message || "Something Went Wrong!", {
        position: "top-right",
      });
      break;
    case "Success":
      toast.success(message, {
        position: "top-right",
      });
      break;
    case "Warning":
      toast.custom(
        (t) => (
          <div className="custom-toast">
            <FiAlertTriangle size={24} />
            <span>{message}</span>
          </div>
        ),
        {
          position: "top-right",
        }
      );
      break;
    default:
      toast.success(message, {
        position: "top-right",
      });
      break;
  }
};

export const changeFavicon = (newFaviconUrl) => {
  const favicon = window.document.querySelector('link[rel="icon"]') || {};
  favicon.href = newFaviconUrl;
};

export const setStyles = (varr, color) => {
  root?.style.setProperty(varr, color);
};

export const setFontFamily = (fontFamily) => {
  document.body.style.fontFamily = fontFamily;
};

export const dateFormatter = (d, type = "start", startDate = null) => {
  if (!d && !startDate) {
    return null;
  }
  const date =
    type === "end" && !!startDate && !d ? new Date(startDate) : new Date(d);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours()?.toString()?.padStart(2, "0");
  const minute = date.getMinutes()?.toString()?.padStart(2, "0");
  const seconds = date.getSeconds()?.toString()?.padStart(2, "0");

  return `${year}-${month?.toString()?.padStart(2, "0")}-${day
    ?.toString()
    ?.padStart(2, "0")} ${type === "end"
      ? "23:59:59"
      : type === "start"
        ? "00:00:00"
        : hour + ":" + minute + ":" + seconds
    }`;
};

export const seperator = (x, rupee = true) => {
  const f = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  const val = f.format(Math.abs(+x) || 0)?.toString();

  const newValues = val?.split(".");
  let newVal = val;
  if (newValues?.length === 2 && newValues?.at(-1) === "00") {
    newVal = newValues?.[0];
  }
  if (!rupee) {
    newVal = newVal?.slice(1);
  }

  return x?.toString()?.slice(0, 1) === "-" ? `-${newVal}` : newVal;
};

export const prepareMaxDate = (startDate, endDate, maxDay = 30) => {
  if (!startDate || (startDate && endDate)) {
    return new Date();
  }
  const d = new Date(startDate);
  d.setDate(d.getDate() + maxDay);
  return d > new Date() ? new Date() : d;
};
export const prepareBlobUrl = (svgString, type = "image/svg+xml") => {
  const blob = new Blob([svgString], { type });
  return URL.createObjectURL(blob);
};

export const responseToaster = (res) => {
  if (res) {
    showToaster(
      res.message,
      res?.warning ? "Warning" : res.status ? "Success" : "Error"
    );
  } else {
    showToaster("Something Went Wrong!", "Error");
  }
};

export const setLocalData = (key, val, defaultValue) => {
  localStorage.setItem(key, JSON.stringify(val ?? defaultValue));
};

export const getLocalData = (key, defaultValue = {}) => {
  return JSON.parse(localStorage.getItem(key)) ?? defaultValue;
};

export const sortByKey = (data, name, sort = "Asce") => {
  const newData = data?.length ? [...data] : [];
  const isDesc = sort === "Desc";
  const keys = name.split(".");

  const getValue = (obj, keys) => {
    return keys.reduce((acc, key) => acc?.[key], obj);
  };

  const compare = (a, b) => {
    const s = getValue(a, keys);
    const l = getValue(b, keys);

    // Check if s and l are numbers
    if (!isNaN(s) && !isNaN(l)) {
      return isDesc ? l - s : s - l;
    }

    // If not numbers, treat as strings and compare
    if (s?.toString()?.toLowerCase() < l?.toString()?.toLowerCase()) {
      return isDesc ? 1 : -1;
    }
    if (s?.toString()?.toLowerCase() > l?.toString()?.toLowerCase()) {
      return isDesc ? -1 : 1;
    }
    return 0;
  };

  return newData.sort(compare);
};

export const sortByDate = (data, name, sort = "Asce") => {
  const sortedAscending = data?.slice()?.sort((a, b) => new Date(a?.[name]) - new Date(b?.[name]));

  const sortedDescending = data
    .slice()
    .sort((a, b) => new Date(b?.[name]) - new Date(a?.[name]));
  return sort === "Asce" ? sortedAscending : sortedDescending;
};

export const prepareCapitalCase = (text) => {
  const newArray = text?.split(" ");
  return newArray?.reduce((total, item, index, array) => {
    return (total +=
      item?.charAt(0)?.toUpperCase() +
      item?.slice(1) +
      (array?.length - 1 !== index ? " " : ""));
  }, "");
};

export const prepareDeepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj) || "{}");
};

export const prepareIsConflict = (...values) => {
  let isValid = true;
  let shouldReturn = false;

  values?.forEach((item, index, array) => {
    if (shouldReturn) {
      return;
    }

    if (!Array.isArray(item) || item?.length !== 2) {
      isValid = false;
      shouldReturn = true;
    }

    array?.forEach((subItem, subIndex) => {
      if (index < subIndex) {
        if (
          (+item?.[0] <= +subItem?.[0] && +item?.[1] >= +subItem?.[0]) ||
          (+item?.[0] <= +subItem?.[1] && +item?.[1] >= +subItem?.[1]) ||
          (+item?.[0] > +subItem?.[0] && +item?.[1] < +subItem?.[1])
        ) {
          isValid = false;
          shouldReturn = true;
        }
      }
    });
  });

  return !isValid;
};

export const prepareGapConflicts2 = (
  values,
  [fromKey, toKey, seperatorKey],
  seperatorKeys
) => {
  const conflicts = [];

  values?.forEach((item, index, array) => {
    let isValid = false;

    array?.forEach((subItem, subIndex) => {
      if (index !== subIndex) {
        if (
          +item?.[toKey] + 1 === +subItem?.[fromKey] ||
          prepareIsConflict(
            [item?.[fromKey], item?.[toKey]],
            [subItem?.[fromKey], subItem?.[toKey]]
          )
        ) {
          isValid = true;
        }
      }
    });

    if (!isValid) {
      conflicts.push({
        index: index,
        [fromKey]: item?.[fromKey],
        [toKey]: item?.[toKey],
        [seperatorKey]: item?.[seperatorKey],
      });
    }
  });

  const maxToValueArray = seperatorKeys?.map((s) => ({
    index: -1,
    value: 0,
    id: s,
  }));

  conflicts?.forEach((item, index) => {
    const i = maxToValueArray?.findIndex(
      (m) => m?.id?.toString() === item?.[seperatorKey]?.toString()
    );
    if (i > -1) {
      const v = maxToValueArray[i];

      if (+item?.[toKey] > +v?.value) {
        maxToValueArray[i] = {
          index: index,
          value: v?.value,
          id: v?.id,
        };
      }
    }
  });

  return conflicts?.filter((_, index) => {
    return !maxToValueArray?.find((subItem) => subItem?.index === index);
  });
};

export const prepareIsBetweenInRange = (value, from, to) => {
  return +value >= +from && +value <= +to;
};

export const prepareGapConflicts = (values, [fromKey, toKey]) => {
  values?.sort((a, b) => +a?.[fromKey] - +b?.[fromKey]);

  const newArray = [values?.[0]];
  const gaps = [];

  values?.forEach((item) => {
    const last = newArray?.at(-1);
    if (
      prepareIsBetweenInRange(
        item?.[fromKey],
        last?.[fromKey],
        last?.[toKey] + 1
      )
    ) {
      newArray[newArray?.length - 1] = {
        ...item,
        [fromKey]: Math.min(+item?.[fromKey], +last?.[fromKey]),
        [toKey]: Math.max(+item?.[toKey], +last?.[toKey]),
      };
    } else {
      newArray.push({
        ...item,
      });

      if (newArray?.length > 1) {
        gaps.push({
          gap: +newArray?.at(-1)?.[fromKey] - +newArray?.at(-2)?.[toKey],
          [fromKey]: +newArray?.at(-1)?.[fromKey],
          [toKey]: +newArray?.at(-2)?.[toKey],
        });
      }
    }
  });
  return gaps;
};

export const objectsAreEqual = (obj1, obj2, ignoreKeys = []) => {
  if (
    obj1 === null ||
    obj2 === null ||
    typeof obj1 !== "object" ||
    typeof obj2 !== "object"
  ) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (ignoreKeys.includes(key)) {
      continue;
    }
    if (
      !ignoreKeys.includes(key) &&
      (!obj2.hasOwnProperty(key) ||
        !deepEqual(obj1[key], obj2[key], ignoreKeys))
    ) {
      return false;
    }
  }

  return true;
};

export const deepEqual = (a, b, ignoreKeys) => {
  if (typeof a !== typeof b) {
    return false;
  }

  if (typeof a === "object" && typeof b === "object") {
    return objectsAreEqual(a, b, ignoreKeys);
  }

  return a === b;
};

export const prepareImageUrl = (url) => {
  return {
    url: `${process.env.REACT_APP_BASE_IMAGE_URL}${url}`,
  };
};

export const prepareVendorOptions = (vendorList) => {
  const vendorOptions = (vendorList?.data || vendorList)
    ?.filter?.((vendor) => vendor?.name !== null)
    ?.slice?.()
    ?.sort((a, b) => a?.name?.localeCompare?.(b?.name))
    ?.map((val) => ({
      value: val?.id,
      label: val?.name,
    }));
  return vendorOptions || [];
};
export const prepareUsersOptions = (UserList) => {
  const userOptions = UserList?.data
    ?.filter?.((user) => user?.firstName !== null)
    ?.slice?.()
    ?.sort((a, b) => a?.firstName?.localeCompare?.(b?.firstName))
    ?.map((val) => ({
      value: val?.id,
      label: val?.firstName,
    }));
  return userOptions || [];
};

export const prepareMainCategoryOptions = (
  vendorList,
  getVendorId,
  categories
) => {
  const shopID = (vendorList?.data || vendorList)?.find?.(
    // eslint-disable-next-line
    (item) => item?.id == getVendorId
  )?.shop_id;
  const categoriesOptions = (categories?.data || categories)
    // eslint-disable-next-line
    ?.find?.((c) => c?.id == shopID)
    ?.children?.filter?.((item) => item?.status === "active")
    ?.slice?.()
    ?.sort?.((a, b) => a?.title?.localeCompare?.(b?.title))
    ?.map?.((val) => ({
      value: val?.id,
      label: val?.title,
    }));
  return categoriesOptions || [];
};

export const prepareCopy = (obj, defaultValue = {}) => {
  return JSON.parse(JSON.stringify(obj || defaultValue));
};


export const renderStarss = (value) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= value) {
      stars.push(<FaStar key={i} size={18} color="var(--chatlook-primary--)" />);
    } else if (i - value < 0.5) {
      stars.push(
        <FaStarHalfStroke key={i} size={18} color="var(--chatlook-primary--)" />
      );
    } else {
      stars.push(
        <FaStar key={i} size={18} color="var(--chatlook-gray--)" />
      );
    }
  }
  return stars;
};